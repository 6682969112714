import update from 'immutability-helper'
import {
  SET_USERS,
  CREATE_USERS,
  UPDATE_USERS,
  DELETE_USERS
} from 'constants/ActionTypes'

const initialState = []

export default function (state = initialState, action) {
  let index = -1
  switch (action.type) {
    case SET_USERS:
      return action.payload
    case CREATE_USERS:
      return [...state, action.payload]
    case UPDATE_USERS:
      index = state.findIndex(i => action.payload._id === i._id)
      if (index > -1) {
        return update(state, {
          [index]: { $set: { ...state[index], ...action.payload } }
        })
      }
      break
    case DELETE_USERS:
      index = state.findIndex(i => action.payload._id === i._id)
      if (index > -1) {
        return update(state, { $splice: [[index, 1]] })
      }
      break
    default:
      return state
  }
  return state
}
