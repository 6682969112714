import get from 'lodash/get'
import _ from 'lodash'
// import validate from './validate'

const _colonSplitterFunc = (txt = '') => {
  const _tmpColonReplacer = 'SYS_BACKSLASH_COLON_REPLACER'
  return txt.replaceAll('\\\\:', _tmpColonReplacer).split(':').map(item => item.replaceAll(_tmpColonReplacer, '\\\\:'))
}

// const _colonSplitter = new RegExp('(?<!\\\\):', 'g')

const specialTextEncode = (input = []) => {
  return input.map((item) => item.replaceAll(':', '\\\\:'))
}

const specialTextDecode = (text = '') => {
  return text.replaceAll('\\\\:', ':')
}

export const getExcludeValue = (exclude = []) => {
  return exclude.join('*')
}

/**
 * @desc input with string; expected to return array of string
 */
export const setExcludeValue = (text) => {
  return text.split('*')
}

export const getPatternLabel = pattern => {
  const { includes, excludes } = pattern
  if (excludes.length !== 0 && excludes.join('') !== '') {

    return `${specialTextEncode(includes).join('*')}|${excludes
      .map(e => getExcludeValue(specialTextEncode(e)))
      .join(':')}`
  }
  return `${specialTextEncode(includes).join('*')}`
}

export const setPatternLabel = pattern => {
  const [includes = '', excludes = ''] = pattern.split('|')
  return {
    includes: includes.split('*').map(e => specialTextDecode(e)),
    // excludes: excludes.split(_colonSplitter).map(e => setExcludeValue(specialTextDecode(e)))
    excludes: _colonSplitterFunc(excludes).map(e => setExcludeValue(specialTextDecode(e)))
  }
}

export const getPatterns = (keyword, index) => {
  if (index < 0) {
    return {
      includes: [],
      excludes: []
    }
  }
  return get(keyword, `patterns[${index}]`, null)
}

export const getAllExcludeValue = exclude => {
  return exclude.map(e => getExcludeValue(specialTextEncode(e))).join(':')
}

export const setAllExcludeValue = exclude => {
  // return exclude.split(_colonSplitter).map(e => setExcludeValue(e))
  return _colonSplitterFunc(exclude).map(e => setExcludeValue(e))
}

export const getPatternsValue = patterns => {
  return patterns.map(e => getPatternLabel(e)).join(',')
}

export const setPatternsValue = patterns => {
  return patterns.split(',').map(e => setPatternLabel(e))
}

export const validateAdvanceMode = (text, all = true) => {
  const validateFn = set => {
    const EachSet = _.split(set, '|')
    /** @desc Check if have | more than one */
    if (EachSet.length > 2) {
      return false
    }

    const Include = EachSet[0]
    const Exclude = EachSet[1]

    /** @desc Check Include keyword : if contain : */
    // if (_.indexOf(Include, ':') !== -1) {
    // if (Include.split(_colonSplitter).length > 1) {
    if (_colonSplitterFunc(Include).length > 1) {
      console.log("CASE1")
      return false
    }

    /** @desc Check Include keyword : if contain * more without keyword */
    if (!!Include && Include !== '') {
      console.log("CASE2")
      const IncludeKeyword = _.split(Include.replace(' ', ''), '*')
      if (_.indexOf(IncludeKeyword, '') !== -1) {
        return false
      }
    }

    if (Exclude) {
      /** @desc Check Exclude keyword : if contain * */
      if (_.indexOf(Exclude, '*') !== -1) {
        console.log("CASE3")
        return false
      }

      /** @desc Check Exclude keyword : if contain : more without keyword */
      if (!!Exclude && Exclude !== '') {
        const ExcludeKeyword = _.split(Exclude.replace(' ', ''), ':')
        if (_.indexOf(ExcludeKeyword, '') !== -1) {
          console.log("CASE4")
          return false
        }
      }
    }

    return true
  }

  if (all) {
    const allSetArr = text.split(',')
    for (let set of allSetArr) {
      if (!validateFn(set)) {
        return false
      }
    }
    return true
  }

  if (text.search(',') !== -1) return false
  if (!validateFn(text)) return false
  return true
}

export const checkDuplicate = pattern => {
  let labelPattern = getPatternLabel(pattern)
  // labelPattern = labelPattern.split(_colonSplitter).join('|')
  labelPattern = _colonSplitterFunc(labelPattern).join('|')
  labelPattern = labelPattern.split('*').join('|')
  const arr = _.split(_.toLower(labelPattern), '|')
  const uniqArr = _.uniq(arr)

  if (arr.length !== uniqArr.length) return false

  return true
}

export const checkPatternDuplicateInclude = (patterns = []) => {
  const _includesArray = _.map(patterns, pattern => {
    const { includes = [] } = pattern
    return includes.sort().join('*')
  })
  const dupResult = _.filter(_includesArray, (val, i, iteratee) => _.includes(iteratee, val, i + 1))
  return dupResult.join(', ')
}
