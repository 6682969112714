import { injectGlobal } from 'styled-components'

/* eslint no-unused-expressions: 0 */
injectGlobal`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  /* for fade css transition */
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease;
  }

  .error {
    margin: 0;
    text-align: left;
  }

  .max-height {
    max-height: 100vh;
  }
  .with-scrollbar {
    overflow: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.1);
      box-shadow: 0 0 1px rgba(255,255,255,.1);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.1);
    }
  }
`
