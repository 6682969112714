import {
  API_CENTRAL_ENGAGEMENT,
  API_AGENDA_STATUS,
  API_LINE,
  API_PANTIP_AUTH,
  API_EMAIL_AUTH,
  API_WHATSAPP_AUTH,
  API_WECHAT_AUTH,
  API_GOOGLE_PLAY_APP,
  API_ENGAGEMENT_CHATBOT,
  API_ENGAGEMENT_WEBCHAT
} from 'constants/ApiUrls'
import {
  METHOD_GET,
  METHOD_POST,
  METHOD_DELETE,
  METHOD_PUT
} from 'constants/Methods'
import { createApi } from 'utils/index'

export const apiListEngagement = () =>
  createApi(API_CENTRAL_ENGAGEMENT, METHOD_GET)

export const apiAddEngagement = (data) =>
  createApi(API_CENTRAL_ENGAGEMENT, METHOD_POST, data)

export const apiDeleteEngagement = (id, product) =>
  createApi(`${API_CENTRAL_ENGAGEMENT}/${id}`,
    METHOD_DELETE,
    { product: product }
  )

export const apiUpdateEngagement = (id, data) =>
  createApi(`${API_CENTRAL_ENGAGEMENT}/${id}`, METHOD_PUT, data)

export const apiValidateLineChannelToken = data =>
  createApi(`${API_LINE}/token`, METHOD_POST, data)

export const apiLoginPantip = data =>
  createApi(API_PANTIP_AUTH, METHOD_POST, data)

export const apiLoginEmail = data =>
  createApi(API_EMAIL_AUTH, METHOD_POST, data)

export const apiLoginWhatsapp = data =>
  createApi(API_WHATSAPP_AUTH, METHOD_POST, data)

export const apiLoginWechat = data =>
  createApi(API_WECHAT_AUTH, METHOD_POST, data)

export const apiGetAgendaResponse = data =>
  createApi(API_AGENDA_STATUS, METHOD_POST, data)

export const apiGetGooglePlayInfo = data =>
  createApi(API_GOOGLE_PLAY_APP, METHOD_POST, data)

export const apiGetChatbotList = () =>
  createApi(`${API_ENGAGEMENT_CHATBOT}/chatbot`, METHOD_GET)

export const apiInsertChatbot = data =>
  createApi(`${API_ENGAGEMENT_CHATBOT}/chatbot`, METHOD_POST, data)

export const apiUpdateChatbot = data =>
  createApi(`${API_ENGAGEMENT_CHATBOT}/chatbot/${data._id}`, METHOD_PUT, data)

export const apiDeleteChatbot = id =>
  createApi(`${API_ENGAGEMENT_CHATBOT}/chatbot/${id}`, METHOD_DELETE)

export const apiGetWebchatLanguageList = () =>
  createApi(`${API_ENGAGEMENT_WEBCHAT}/webchat`, METHOD_GET)