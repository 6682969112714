import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormGroup, ControlLabel, Tooltip, OverlayTrigger } from 'react-bootstrap'
import Info from 'assets/img/icon/question.svg'
import 'styles/customize/call-centre/callcentre.custom.css'

/* eslint-disable no-undef */
export default class PantipLogin extends Component {
  state = {
    loginWtihToken: true
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    onChange: () => { }
  };

  handleChange = evt => {
    this.props.onChange({
      // email: this.email.value,
      // password: this.password.value,
      loginWtihToken: true,
      userId: this.userId.value,
      token: this.token.value
    })
  };

  render() {
    return (
      <div style={{ padding: '15px' }}>
        <p>
          Please make sure that you have at least one message in the incoming message box before connect.
        </p>
        <div className='email-field'>
          <FormGroup controlId='pantipUserIdInput'>
            <ControlLabel>User ID</ControlLabel>
            <FormControl
              name='pantip_user_id'
              onChange={this.handleChange}
              inputRef={el => {
                this.userId = el
              }}
              type='text'
              placeholder='Enter User ID'
            />
          </FormGroup>
          <FormGroup controlId='pantipTokenInput'>
            <ControlLabel>
              Access token
              <OverlayTrigger
                placement='top'
                id={'overlay-access-token'}
                overlay={
                  <Tooltip id={`tooltip-access-token`} placement='top'>
                    Log in pantip with access token
                  </Tooltip>
                }
              >
                <span>
                  <img
                    src={Info}
                  />
                </span>
              </OverlayTrigger>

            </ControlLabel>
            <FormControl
              name='pantip_token'
              onChange={this.handleChange}
              inputRef={el => {
                this.token = el
              }}
              type='text'
              placeholder='Enter your Access token'
            />
          </FormGroup>
        </div>
      </div>
    )
  }
}
