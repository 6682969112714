import { METHOD_GET } from 'constants/Methods'
import { fetchApi } from './index'

/**
 * Helper for basic api creater
 * @param {String} uri
 * @param {instance of src/constants/Methods} method
 * @param {Object} body
 * @return {Promise} fetchApi().json()
 */
export default async (
  uri,
  method = METHOD_GET,
  body = null,
  formData = null,
  options = {}
) => {
  const {
    throwIfStatusCodeGreaterThan400 = true
  } = options
  // create fetch config
  const config = {
    method
  }
  // When @param body not null then assign body to config
  if (body !== null) {
    config.body = JSON.stringify(body)
  }
  try {
    const response = await fetchApi(uri, config, formData)
    const body = await response.json()
    // if (
    //   +response.status === 401 &&
    //   throwIfStatusCodeGreaterThan400 &&
    //   window.location.pathname !== '/login' &&
    //   window.location.pathname !== '/logout'
    // ) {
    //   await window.location.assign('/logout')
    //   return null
    //   // return Promise.reject(body)
    // }
    if (+response.status >= 400 && throwIfStatusCodeGreaterThan400) return Promise.reject(body)
    return body
  } catch (error) {
    return Promise.reject(error)
  }
}
