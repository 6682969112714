import {
  API_CRM_AUTO_ASSIGN
} from 'constants/ApiUrls'
import { METHOD_POST, METHOD_PUT, METHOD_DELETE } from 'constants/Methods'
import { createApi } from 'utils/index'

export const getAll = () => createApi(`${API_CRM_AUTO_ASSIGN}/all`)
export const getSettings = () => createApi(`${API_CRM_AUTO_ASSIGN}`)
export const createSettings = (data) => createApi(`${API_CRM_AUTO_ASSIGN}`, METHOD_POST, data)
export const updateSettings = (data) => createApi(`${API_CRM_AUTO_ASSIGN}`, METHOD_PUT, data)

export const getAgents = () => createApi(`${API_CRM_AUTO_ASSIGN}/agents`)
export const createAgents = (data) => createApi(`${API_CRM_AUTO_ASSIGN}/agents`, METHOD_POST, data)
export const bulkUpdateAgents = (data) => createApi(`${API_CRM_AUTO_ASSIGN}/agents`, METHOD_PUT, data)
export const updateAgents = async (data, id) => {
  const result = await createApi(`${API_CRM_AUTO_ASSIGN}/agents/${id}`, METHOD_PUT, data)
  result.body.updatedPayload = data
  return result
}

export const getConnections = () => createApi(`${API_CRM_AUTO_ASSIGN}/conditions`)
export const createConnections = (data) => createApi(`${API_CRM_AUTO_ASSIGN}/conditions`, METHOD_POST, data)
export const updateConnections = (data) => createApi(`${API_CRM_AUTO_ASSIGN}/conditions`, METHOD_PUT, data)

export const getRules = () => createApi(`${API_CRM_AUTO_ASSIGN}/rules`)
export const createRules = (data) => createApi(`${API_CRM_AUTO_ASSIGN}/rules`, METHOD_POST, data)
export const updateRules = (data, id) => createApi(`${API_CRM_AUTO_ASSIGN}/rules/${id}`, METHOD_PUT, data)
export const deleteRules = async (data) => {
  await createApi(`${API_CRM_AUTO_ASSIGN}/rules/${data._id}`, METHOD_DELETE)
  return { body: data }
}
