import { SET_CUSTOM_THEME, UPDATE_CUSTOM_THEME } from 'constants/ActionTypes'

const initialState = {
  is_set: false,
  details: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOM_THEME:
      return action.payload
    case UPDATE_CUSTOM_THEME:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
