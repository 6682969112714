import {
  API_VERIFICATION
} from 'constants/ApiUrls'
import { METHOD_POST, METHOD_PUT, METHOD_DELETE } from 'constants/Methods'
import { createApi } from 'utils/index'

export const listSettings = () => createApi(`${API_VERIFICATION}/settings`)
export const createSetting = (data) => createApi(`${API_VERIFICATION}/settings`, METHOD_POST, data)
export const updateSetting = (data) => createApi(`${API_VERIFICATION}/settings/${data._id}`, METHOD_PUT, data)
export const deleteSetting = async (data) => {
  await createApi(`${API_VERIFICATION}/settings/${data._id}`, METHOD_DELETE)
  return data._id
}

export const listLabels = () => createApi(`${API_VERIFICATION}/labels`)
export const createLabel = (data) => createApi(`${API_VERIFICATION}/labels`, METHOD_POST, data)
export const updateLabel = (data) => createApi(`${API_VERIFICATION}/labels/${data._id}`, METHOD_PUT, data)
export const deleteLabel = async (data) => {
  await createApi(`${API_VERIFICATION}/labels/${data._id}`, METHOD_DELETE)
  return data._id
}
