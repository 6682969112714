import React, { Component } from 'react'
import Raven from 'raven-js'

Raven.config(process.env.REACT_APP_SENTRY_KEY).install()

export class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch (error, errorInfo) {
    this.setState({ error })
    Raven.captureException(error, { extra: errorInfo })
  }

  render () {
    if (this.state.error) {
      return (
        <div
          className='container'
          onClick={() => Raven.lastEventId() && Raven.showReportDialog()}
        >
          <h1>We're sorry — something's gone wrong.</h1>
          <p>Our team has been notified, but click here fill out a report.</p>
        </div>
      )
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}

export default ErrorBoundary
