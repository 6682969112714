/**
 * @author Tlecreate(thanes@zanroo.com)
 */
import React from 'react'
import PropTypes from 'prop-types'
import { compose, defaultProps, withHandlers, setPropTypes, withState } from 'recompose'
import _ from 'lodash'
import Inline, {
  INLINE_SUBMIT,
  INLINE_CANCEL,
  INLINE_CLEAR
} from '@zanroo/react-inline'
import { FormControl, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

/**
 * @desc Create component div with styled.
 */
const Label = styled.div`
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background: none;
  border: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
`

/**
 * @desc for render label before editing
 * @param {Object} props
 * @property {Function} props.onEditing - for change label to editing mode
 * @property {String} props.value - for render current value in label
 */
export const InlineLabel = props => {
  const { onEditing, value } = props
  return <Label onClick={onEditing}>{value}</Label>
}

/**
 * @desc for render input on editing
 */
export const InputText = {
  setPropTypes: {
    refs: PropTypes.object,
    onChange: PropTypes.func,
    onEdited: PropTypes.func,
    submitEventKeys: PropTypes.array,
    cancelEventKeys: PropTypes.array,
    clearEventKeys: PropTypes.array,
    submitEventTypes: PropTypes.array,
    cancelEventTypes: PropTypes.array,
    clearEventTypes: PropTypes.array
  },
  defaultProps: {
    refs: Object.create(null),
    onChange () { },
    onEdited () { },
    submitEventKeys: ['Enter'],
    cancelEventKeys: ['Escape'],
    clearEventKeys: [],
    submitEventTypes: [],
    cancelEventTypes: ['blur'],
    clearEventTypes: []
  },
  /**
   * @param {{ onChange: Function, onEdited: Function }} props
   * @desc onChange for update current in <Inline /> component
   * @desc onEdited for change state to show label and should called submit or cancel
   */
  withHandlers: ({ onChange, onEdited, setText }) => ({
    /**
     * @param {Event} event
     * @desc use event.key for map action type to call onEdited
     */
    onKeyDown: props => e => {
      if (props.submitEventKeys.indexOf(e.key) > -1) {
        // onEdited(INLINE_SUBMIT)
        const { onSaveEdit, text, setText, currentText, setCurrentText } = props

        const saveResult = onSaveEdit(text)
        if (saveResult === false) {
          setText(currentText)
        } else {
          setCurrentText(text)
        }
        onEdited()
      }
      if (props.cancelEventKeys.indexOf(e.key) > -1) {
        onEdited(INLINE_CANCEL)
      }
      if (props.clearEventKeys.indexOf(e.key) > -1) {
        onEdited(INLINE_CLEAR)
      }
    },
    onChange: () => e => {
      // onChange(e.target.value)
      setText(e.target.value)
    },
    /**
     * @desc use event.type for map action type to call onEdited
     */
    onBlur: props => e => {
      const { onSaveEdit, text, currentText, setCurrentText } = props
      const saveResult = onSaveEdit(text)
      if (saveResult === false) {
        setText(currentText)
      } else {
        setCurrentText(text)
      }
      onEdited()
      if (props.submitEventTypes.indexOf(e.type) > -1) {
        onEdited(INLINE_SUBMIT)
      }
      if (props.cancelEventTypes.indexOf(e.type) > -1) {
        onEdited(INLINE_CANCEL)
      }
      if (props.clearEventTypes.indexOf(e.type) > -1) {
        onEdited(INLINE_CLEAR)
      }
    }
  }),
  /**
   *
   * @param {Object} props
   * @property {String} props.value
   * @property {Function} props.onKeyDown
   * @property {Function} props.onChange
   * @property {Function} props.onBlur
   * @desc receive and parse handles function above to <FormControl />
   */
  render (props) {
    const {
      text,
      onKeyDown,
      onChange,
      onBlur,
      refs,
      onSaveEnable = false,
      onSaveEdit,
      onEdited,
      setText,
      currentText,
      setCurrentText
    } = props
    return (
      <Row style={{ paddingRight: '35px' }}>
        <Col md={11}>
          <FormControl
            bsClass='input-search input-key form-control'
            value={text}
            inputRef={ref => {
              refs.input = ref
            }}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
          />
        </Col>
        <Col md={1}>
          {
            onSaveEnable &&
            (
              <i
                className='glyphicon glyphicon-floppy-save'
                onClick={() => {
                  const saveResult = onSaveEdit(text)
                  if (saveResult === false) {
                    setText(currentText)
                  } else {
                    setCurrentText(text)
                  }
                  onEdited()
                  // const a = onEdited(onSaveEdit(text))
                }}
                style={{
                  fontSize: '18px',
                  marginTop: '8px',
                  cursor: 'pointer'
                }}
              />
            )
          }
        </Col>
      </Row>
    )
  }
}

/**
 * @desc wrap InputText.render with with handlers
 */
export const InputTextEnhancer = compose(
  setPropTypes(InputText.setPropTypes),
  defaultProps(InputText.defaultProps),
  withState('text', 'setText', props => _.get(props, 'value', '')),
  withState('currentText', 'setCurrentText', props => _.get(props, 'value', '')),
  withHandlers(InputText.withHandlers)
)(InputText.render)

/**
 * @desc Use inline with InputTextEnhancer
 */
export const InlineInputText = {
  setPropTypes: {
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.string
  },
  defaultProps: {
    refs: Object.create(null),
    onFocus () { },
    onChange () { },
    onCancel () { },
    onSubmit () { },
    value: ''
  },
  /**
   * @desc when <Inline /> callback onFocus it will focus Input from InputText.ref
   */
  withHandlers: ({ onFocus }) => ({
    onFocus: ({ refs }) => () => {
      refs.input.focus()
      onFocus()
    }
  }),
  /**
   * @desc render <Inline /> with customize ComponentLabel and ComponentInline
   * @param {Object} props
   */
  render (props) {
    /**
     * @type {{ onFocus: Function, onChange: Function, onCancel: Function, onSubmit: Function, value: String, refs: Object }}
     * @example
     * <Inline {...handlers}>
     *  editing ? <InlineLabel {...handlers} />
     *  !editing ? <InputTextEnhancer {...handlers} />
     * </Inline>
     */
    const { onFocus, onChange, onCancel, onSubmit, value, refs } = props
    return (
      <Inline
        value={value}
        onChange={onChange}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onFocus={onFocus}
        ComponentLabel={InlineLabel}
        ComponentInline={InputTextEnhancer}
        refs={refs}
      />
    )
  }
}

/**
 * @desc export default InlineInputText with compose all HOCs.
 */
export default compose(
  setPropTypes(InlineInputText.setPropTypes),
  defaultProps(InlineInputText.defaultProps),
  withHandlers(InlineInputText.withHandlers)
)(InlineInputText.render)
