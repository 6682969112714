import { createAction } from 'redux-actions'
import get from 'lodash/get'
import each from 'lodash/each'
import pull from 'lodash/pull'
import { bindMiddlewaresCreator } from 'utils/actions'
import * as apis from 'apis/crm/autoAssign'

const defaultAsyncFn = async () => { }

const createApiActions = (prefix = '') => ({
  pending: createAction(`${prefix}.pending`),
  success: createAction(`${prefix}.success`),
  error: createAction(`${prefix}.error`)
})

const withThunkMiddleware = (defaultSeletor = s => s) =>
  (handlers = () => { }) =>
    (params = {}) => {
      const {
        payload,
        selector = defaultSeletor,
        id
      } = params
      return (dispatch, getState) =>
        handlers(dispatch, selector(getState()), payload, id)
    }

const createMiddlewares = ({
  caller = defaultAsyncFn,
  actionApi = createApiActions(),
  dispatcher = () => {}
}) => async (dispatch, state, payload, id) => {
  try {
    dispatch(actionApi.pending())
    const result = id ? await caller(payload, id) : await caller(payload)
    dispatch(dispatcher(result.body))
    dispatch(actionApi.success())
    return result
  } catch (error) {
    dispatch(actionApi.error(error))
    return Promise.reject(error)
  }
}

const autoAssignActions = ({
  prefix = '',
  apis = {},
  customMiddlewares = (/* actions */) => {}
}) => {
  const actions = {
    api: createApiActions(`actions.crm.auto_assign.${prefix}.api`),
    data: {
      set: createAction(`actions.crm.auto_assign.${prefix}.data.set`),
      reset: createAction(`actions.crm.auto_assign.${prefix}.data.reset`)
    },
    default: { set: createAction(`actions.crm.auto_assign.${prefix}.default.set`) }
  }

  const {
    get = defaultAsyncFn,
    create = defaultAsyncFn,
    update = defaultAsyncFn,
    del = defaultAsyncFn
  } = apis
  const middlewares = {
    get: createMiddlewares({ caller: get, actionApi: actions.api, dispatcher: actions.default.set }),
    create: createMiddlewares({ caller: create, actionApi: actions.api, dispatcher: actions.default.set }),
    update: createMiddlewares({ caller: update, actionApi: actions.api, dispatcher: actions.default.set }),
    del: createMiddlewares({ caller: del, actionApi: actions.api, dispatcher: actions.default.set }),
    ...customMiddlewares(actions)
  }
  actions.middlewares = bindMiddlewaresCreator(middlewares, withThunkMiddleware())
  return actions
}

const Actions = {
  agents: autoAssignActions({
    prefix: 'agents',
    apis: {
      get: apis.getAgents,
      create: apis.createAgents,
      update: apis.updateAgents
    },
    customMiddlewares: (actions) => ({
      update: createMiddlewares({
        caller: apis.updateAgents,
        actionApi: actions.api,
        dispatcher: (data) => (dispatch, getState) => {
          const state = getState()
          const agents = get(state, 'crm.autoAssign.agents.default', {})
          const updatedAgentId = data._id
          agents[updatedAgentId] = data

          dispatch(Actions.agents.default.set({...agents}))

          const { modify_field: field, value = [] } = data.updatedPayload

          if (field !== 'rules') {
            return
          }

          const rules = get(state, 'crm.autoAssign.rules.default', {})

          each(rules, rule => {
            const { users = [], _id } = rule

            if (value.includes(_id) && !users.includes(updatedAgentId)) {
              users.push(updatedAgentId)
              rules[_id] = {...rule}
            } else if (!value.includes(_id) && users.includes(updatedAgentId)) {
              pull(users, updatedAgentId)
              rules[_id] = {...rule}
            }
          })

          dispatch(Actions.rules.default.set({...rules}))
        }
      }),
      bulkUpdate: createMiddlewares({
        caller: apis.bulkUpdateAgents,
        actionApi: actions.api,
        dispatcher: (data) => (dispatch, getState) => {
          const state = getState()
          const agents = get(state, 'crm.autoAssign.agents.default', {})
          each(data, agent => {
            agents[agent._id] = agent
          })

          dispatch(Actions.agents.default.set({...agents}))
        }
      })
    })
  }),
  conditions: autoAssignActions({
    prefix: 'conditions',
    apis: {
      get: apis.getConnections,
      create: apis.createConnections,
      update: apis.updateConnections
    }
  }),
  rules: autoAssignActions({
    prefix: 'rules',
    apis: {
      get: apis.getRules,
      create: apis.createRules,
      update: apis.updateRules,
      del: apis.deleteRules
    },
    customMiddlewares: (actions) => ({
      create: createMiddlewares({
        caller: apis.createRules,
        actionApi: actions.api,
        dispatcher: (data) => (dispatch, getState) => {
          const state = getState()
          const rules = get(state, 'crm.autoAssign.rules.default', {})
          rules[data._id] = data

          dispatch(Actions.rules.default.set({...rules}))
        }
      }),
      update: createMiddlewares({
        caller: apis.updateRules,
        actionApi: actions.api,
        dispatcher: (data) => (dispatch, getState) => {
          const state = getState()
          const rules = get(state, 'crm.autoAssign.rules.default', {})
          rules[data._id] = data

          dispatch(Actions.rules.default.set({...rules}))
        }
      }),
      del: createMiddlewares({
        caller: apis.deleteRules,
        actionApi: actions.api,
        dispatcher: (data) => (dispatch, getState) => {
          const state = getState()
          const rules = get(state, 'crm.autoAssign.rules.default', {})
          delete rules[data._id]
          dispatch(Actions.rules.default.set({...rules}))

          const agents = get(state, 'crm.autoAssign.agents.default', {})
          each(data.users, agentId => {
            const updatedAgent = agents[agentId]

            if (!updatedAgent) {
              return
            }

            pull(updatedAgent.rules, data._id)
          })

          dispatch(Actions.agents.default.set({...agents}))
        }
      })
    })
  }),
  settings: autoAssignActions({
    prefix: 'settings',
    apis: {
      get: apis.getSettings,
      create: apis.createSettings,
      update: apis.updateSettings
    }
  })
}

Actions.all = autoAssignActions({
  prefix: 'all',
  customMiddlewares: (actions) => ({
    get: createMiddlewares({
      caller: apis.getAll,
      actionApi: actions.api,
      dispatcher: (data) => (dispatch) => {
        const agentMap = {}
        const ruleMap = {}

        for (let i = 0; i < data.agents.length; i++) {
          const agent = data.agents[i]
          agentMap[agent._id] = agent
        }

        for (let i = 0; i < data.rules.length; i++) {
          const rule = data.rules[i]
          ruleMap[rule._id] = rule
        }

        dispatch(Actions.settings.default.set(data.settings))
        dispatch(Actions.agents.default.set(agentMap))
        dispatch(Actions.conditions.default.set(data.conditions))
        dispatch(Actions.rules.default.set(ruleMap))
      }
    })
  })
})

export default Actions
