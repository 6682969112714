import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Row, Tooltip, OverlayTrigger, Col } from 'react-bootstrap'
import validate from '../validate'
const tooltip = title => <Tooltip id='tooltip'>{title}</Tooltip>

/* eslint-disable no-undef */
export default class LineLogin extends Component {
  state = {
    title: 'Copy to clipboard',
    channelId: ''
  };
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    isReady: PropTypes.func.isRequired,
    webhookUrl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onChange: () => { },
    isReady: () => { },
    webhookUrl: 'https://engagement-api-staging.zrinf.io/api/webhook/line/',
  };

  handleChange = evt => {
    this.props.onChange({
      auth: {
        client_id: this.channelId.value,
        access_token: this.channelAccessToken.value,
      }
    })
    this.setState({
      channelId: this.channelId.value,
      title: 'Copy to clipboard'
    })
  };

  onCopy = () => {
    this.setState({ title: 'Copied' })
  };

  onBlur = evt => {
    evt.preventDefault()
    this.props.isReady(this.validate.form())
  };

  componentDidMount() {
    this.validate = validate(this.form, {
      rules: {
        channel_id: 'required',
        channel_access_token: 'required',
      }
    })
  }

  render() {
    return (
      <>
        <style jsx>{`
        .modal-dialog {
          width:fit-content !important;
        }
        .line-connect-dialog .modal-dialog .line-connect-wrapper {
          padding: unset !important; 
        }
      `}</style>
        <form
          onBlur={this.onBlur}
          ref={el => {
            this.form = el
          }}
          className='line-connect-wrapper'
        >
          <Row>
            <Col xs={9} className='m-l-30'>
              <div className='form-group-line m-t-15'>
                <label style={{ marginRight: '10px' }}>Channel ID</label>
                <input
                  onChange={this.handleChange}
                  ref={el => {
                    this.channelId = el
                  }}
                  type='text'
                  placeholder='Channel Id'
                  name='channel_id'
                  required
                />
              </div>
              <div className='form-group-line m-t-15'>
                <label style={{ marginRight: '10px' }}>Channel Access Token</label>
                <input
                  onChange={this.handleChange}
                  ref={el => {
                    this.channelAccessToken = el
                  }}
                  type='text'
                  placeholder='Channel Access Token'
                  name='channel_access_token'
                  required
                />
              </div>
              <div className='form-group-line m-t-15'>
                <label style={{ marginRight: '10px' }}>Webhook URL</label>
                <OverlayTrigger
                  placement='top'
                  overlay={tooltip(this.state.title)}
                >
                  <CopyToClipboard
                    text={`${this.props.webhookUrl}${this.state.channelId}`}
                    onCopy={this.onCopy}
                  >
                    <span className='copy-url'>COPY URL</span>
                  </CopyToClipboard>
                </OverlayTrigger>
                <p
                  style={{
                    width: '590px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    border: '0',
                    borderBottom: 'solid 1px #dcdbdb',
                    background: 'transparent',
                    fontSize: '16px',
                    color: '#000'
                  }}
                >
                  {`${this.props.webhookUrl}${this.state.channelId}`}
                </p>
              </div>
            </Col>
          </Row>
        </form>
      </>
    )
  }
}
