import {
  SET_TICKET_TAGS,
  UPSERT_TICKET_TAG,
  DELETE_TICKET_TAG,
  UPDATE_TICKET_TAG_SELECTED,
  UPDATE_GROUP_SELECTED
} from 'constants/ActionTypes'
import { createAction } from 'redux-actions'

export const actionSetTicketTags = createAction(
  SET_TICKET_TAGS,
  payload => payload
)

export const actionUpsertTicketTags = createAction(
  UPSERT_TICKET_TAG,
  payload => payload
)

export const actionDeleteTicketTags = createAction(
  DELETE_TICKET_TAG,
  payload => payload
)

export const actionUpdateTicketTagSelected = createAction(
  UPDATE_TICKET_TAG_SELECTED,
  payload => payload
)

export const actionUpdateGroupSelected = createAction(
  UPDATE_GROUP_SELECTED,
  payload => payload
)
