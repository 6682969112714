import React from 'react'
import PropTypes from 'prop-types'
import {
  compose,
  mapProps,
  withState,
  withHandlers,
  lifecycle,
  defaultProps
} from 'recompose'
import get from 'lodash/get'
import { FormGroup, FormControl } from 'react-bootstrap'

const InputControl = {
  setPropTypes: {
    value: PropTypes.string,
    onSubmit: PropTypes.func,
    submitEventKeys: PropTypes.array,
    cancelEventKeys: PropTypes.array,
    clearEventKeys: PropTypes.array,
    submitEventTypes: PropTypes.array,
    cancelEventTypes: PropTypes.array,
    clearEventTypes: PropTypes.array
  },
  defaultProps: {
    value: '',
    onSubmit () {},
    submitEventKeys: ['Enter'],
    cancelEventKeys: ['Escape'],
    clearEventKeys: ['Enter', 'Escape'],
    submitEventTypes: [],
    cancelEventTypes: [],
    clearEventTypes: []
  },
  /**
   * @desc change props.value to props.ownValue
   * @param {{ value: String, ...rest: Object }}
   */
  mapProps: ({ value, ...rest }) => ({
    ownValue: value,
    ...rest
  }),
  /**
   * @desc after change props.value to props.ownValue, initial state name props.value for self
   */
  withState: ['value', 'setValue', ({ ownValue }) => ownValue],
  lifecycle: {
    /**
     * @desc when different nextProps.ownValue and current props.ownValue will update props.value self
     * @param {Object} nextProps
     * @property {String} nextProps.ownValue
     * @property {Function} nextProps.setValue - fn for update props.value self
     */
    componentWillReceiveProps (nextProps) {
      if (this.props.ownValue !== nextProps.ownValue) {
        this.props.setValue(nextProps.ownValue)
      }
    }
  },
  /**
   * @param {{ setValue: Function, onSubmit: Function }} - prototype fn for handlers
   */
  withHandlers: ({ setValue, onSubmit }) => ({
    onChange: ({ value }) => e => {
      if (get(e, 'target.value')) {
        setValue(e.target.value)
      } else {
        setValue('')
      }
    },
    /**
     * @desc handlers with condition from
     * @desc submitEventKeys, cancelEventKeys,
     * @desc clearEventKeys, submitEventTypes,
     * @desc cancelEventTypes, clearEventTypes
     * @desc property type look at InlineControl.setPropTypes
     */
    onSubmit: ({
      value,
      ownValue,
      submitEventKeys,
      cancelEventKeys,
      clearEventKeys,
      submitEventTypes,
      cancelEventTypes,
      clearEventTypes
    }) => e => {
      if (submitEventKeys.indexOf(e.key) > -1) {
        onSubmit(value)
      }
      if (cancelEventKeys.indexOf(e.key) > -1) {
        setValue(ownValue)
      }
      if (clearEventKeys.indexOf(e.key) > -1) {
        setValue('')
      }
      if (submitEventTypes.indexOf(e.type) > -1) {
        onSubmit(value)
      }
      if (cancelEventTypes.indexOf(e.type) > -1) {
        setValue(ownValue)
      }
      if (clearEventTypes.indexOf(e.type) > -1) {
        setValue('')
      }
    }
  }),
  render (props) {
    /**
     * @typedef {Function} onChange - from above withHandlers
     * @typedef {Function} onSubmit - from above withHandlers
     */
    const {
      value,
      onChange,
      onSubmit,
      /* not send to FormControl */
      setValue,
      ownValue,
      submitEventKeys,
      cancelEventKeys,
      clearEventKeys,
      submitEventTypes,
      cancelEventTypes,
      clearEventTypes,
      ...rest
    } = props
    return (
      <FormGroup>
        <FormControl
          {...rest}
          value={value}
          onChange={onChange}
          onKeyDown={onSubmit}
          onBlur={onSubmit}
        />
      </FormGroup>
    )
  }
}

export default compose(
  defaultProps(InputControl.defaultProps),
  mapProps(InputControl.mapProps),
  withState(...InputControl.withState),
  lifecycle(InputControl.lifecycle),
  withHandlers(InputControl.withHandlers)
)(InputControl.render)
