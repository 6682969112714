export const sites = [
  // {
  //   origin: 'http://test.zanroo.com:3000',
  //   profile: {
  //     // welcome_logo: require('./macquarie-bank.png'),
  //     // logo: require('./logo-black.png'),
  //     // welcome_bg: require('./macquarie_bg.jpg'),
  //     welcome_logo: 'https://b-cdn.zanroo.com/oem-profile/macquarie-profile/macquarie-bank.png',
  //     logo: 'https://b-cdn.zanroo.com/oem-profile/macquarie-profile/logo-black.png',
  //     welcome_bg: 'https://b-cdn.zanroo.com/oem-profile/macquarie-profile/macquarie_bg.jpg',
  //     acoount_id: null,
  //     site_name: '',
  //     site_title: '',
  //     theme: ''
  //   },
  //   after_login_page: {
  //     url: 'https://listening-dev.weet.tk/heatmap',
  //     // url: '/settings/account/main',
  //     title: 'Heat Map'
  //   }
  // },
  {
    origin: 'http://center.macquarie.zanroo.com',
    profile: {
      welcome_logo: 'https://b-cdn.zanroo.com/oem-profile/macquarie-profile/macquarie-bank.png',
      logo: 'https://b-cdn.zanroo.com/oem-profile/macquarie-profile/logo-black.png',
      sidebar_logo: 'https://b-cdn.zanroo.com/oem-profile/macquarie-profile/logo-black.png',
      welcome_bg: 'https://b-cdn.zanroo.com/oem-profile/macquarie-profile/macquarie_bg.jpg',
      acoount_id: null,
      site_name: '',
      site_title: '',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.macquarie.zanroo.com/heatmap',
      title: 'Heat Map'
    }
  },
  {
    origin: 'http://center.wolfeyesplus.zanroo.com',
    profile: {
      welcome_logo: 'https://b-cdn.zanroo.com/oem-profile/wolfeyesplus-profile/logo_small.png',
      logo: 'https://b-cdn.zanroo.com/oem-profile/wolfeyesplus-profile/logo.png',
      sidebar_logo: 'https://b-cdn.zanroo.com/oem-profile/wolfeyesplus-profile/logo.png',
      welcome_bg: 'https://b-cdn.zanroo.com/oem-profile/wolfeyesplus-profile/welcome_bg.png',
      acoount_id: null,
      site_name: '',
      site_title: 'WOLF EYES',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.wolfeyesplus.zanroo.com/dashboard',
      title: 'Dashboard'
    }
  },
  {
    origin: 'http://center.docatrack.zanroo.com',
    profile: {
      welcome_logo: 'https://b-cdn.zanroo.com/oem-profile/socialstratigic-profile/welcome_logo.png',
      logo: 'https://b-cdn.zanroo.com/oem-profile/socialstratigic-profile/logo.png',
      welcome_bg: 'https://b-cdn.zanroo.com/oem-profile/socialstratigic-profile/welcome_bg.jpg',
      sidebar_logo: "https://b-cdn.zanroo.com/oem-profile/socialstratigic-profile/logo.png",
      acoount_id: '529',
      site_name: '',
      site_title: '',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.docatrack.zanroo.com/dashboard',
      title: 'Dashboard'
    }
  },
  {
    // origin: 'http://dist.zanroo.com:3000',
    origin: 'http://center.dist.zanroo.com',    
    profile: {
      welcome_logo: require('./MOD/dist.png'),
      logo: require('./MOD/dist.png'),
      welcome_bg: require('./MOD/bg_thai01.png'), 
      sidebar_logo: require('./MOD/sidebar_icons/dist.png'),
      sidebar_logo_style: {
        width: '200px',
        height: 'auto',
        marginTop: '0px'
      },
      theme_color: '#0e5383',
      acoount_id: '335',
      site_name: '',
      site_title: '',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.dist.zanroo.com/dashboard',
      title: 'Dashboard'
    }
  },
  {
    // origin: 'http://opsd.zanroo.com:3000',
    origin: 'http://center.opsd.zanroo.com',    
    profile: {
      welcome_logo: require('./MOD/opsd.png'),
      logo: require('./MOD/opsd.png'),
      welcome_bg: require('./MOD/bg_thai01.png'),
      sidebar_logo: require('./MOD/sidebar_icons/opsd.png'),
      sidebar_logo_style: {
        width: '200px',
        height: 'auto',
        marginTop: '0px'
      },
      theme_color: '#0e5383',
      acoount_id: '556',
      site_name: '',
      site_title: '',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.opsd.zanroo.com/dashboard',
      title: 'Dashboard'
    }
  },
  {
    origin: 'http://center.armedforces.zanroo.com',
    profile: {
      welcome_logo: require('./MOD/armed.png'),
      logo: require('./MOD/armed.png'),
      welcome_bg: require('./MOD/bg_thai01.png'),
      sidebar_logo: require('./MOD/sidebar_icons/armedforces.png'),
      sidebar_logo_style: {
        width: '200px',
        height: 'auto',
        marginTop: '0px'
      },
      theme_color: '#0e5383',
      acoount_id: '555',
      site_name: '',
      site_title: '',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.armedforces.zanroo.com/dashboard',
      title: 'Dashboard'
    }
  },
  {
    // origin: 'http://army.zanroo.com:3000',
    origin: 'http://center.army.zanroo.com',
    profile: {
      welcome_logo: require('./MOD/army.png'),
      logo: require('./MOD/army.png'),
      welcome_bg: require('./MOD/bg_thai01.png'),
      sidebar_logo: require('./MOD/sidebar_icons/army.png'),
      sidebar_logo_style: {
        width: '200px',
        height: 'auto',
        marginTop: '0px'
      },
      theme_color: '#0e5383',
      acoount_id: '557',
      site_name: '',
      site_title: '',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.army.zanroo.com/dashboard',
      title: 'Dashboard'
    }
  },
  {
    origin: 'http://center.navy.zanroo.com',
    profile: {
      welcome_logo: require('./MOD/navy.png'),
      logo: require('./MOD/navy.png'),
      welcome_bg: require('./MOD/bg_thai01.png'),
      sidebar_logo: require('./MOD/sidebar_icons/navy.png'),
      sidebar_logo_style: {
        width: '200px',
        height: 'auto',
        marginTop: '0px'
      },
      theme_color: '#0e5383',
      acoount_id: '558',
      site_name: '',
      site_title: '',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.navy.zanroo.com/dashboard',
      title: 'Dashboard'
    }
  },
  {
    origin: 'http://center.airforce.zanroo.com',
    profile: {
      welcome_logo: require('./MOD/airforce.png'),
      logo: require('./MOD/airforce.png'),
      welcome_bg: require('./MOD/bg_thai01.png'),
      sidebar_logo: require('./MOD/sidebar_icons/airforce.png'),
      sidebar_logo_style: {
        width: '200px',
        height: 'auto',
        marginTop: '0px'
      },
      theme_color: '#0e5383',
      acoount_id: '559',
      site_name: '',
      site_title: '',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.airforce.zanroo.com/dashboard',
      title: 'Dashboard'
    }
  },
  {
    origin: 'http://center.intel.zanroo.com',
    profile: {
      welcome_logo: require('./MOD/intel.png'),
      logo: require('./MOD/intel.png'),
      welcome_bg: require('./MOD/bg_thai01.png'),
      sidebar_logo: require('./MOD/sidebar_icons/intel.png'),
      sidebar_logo_style: {
        width: '200px',
        height: 'auto',
        marginTop: '0px'
      },
      theme_color: '#0e5383',
      acoount_id: '560',
      site_name: '',
      site_title: '',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.intel.zanroo.com/dashboard',
      title: 'Dashboard'
    }
  },
  {
    origin: 'http://center.intel1.zanroo.com',
    profile: {
      welcome_logo: require('assets/img/logo/logo-white.svg'),
      logo: require('assets/img/logo/logo-white.svg'),
      welcome_bg: require('./MOD/bg_thai01.png'),
      theme_color: '#0e5383',
      acoount_id: '560',
      site_name: '',
      site_title: '',
      theme: ''
    },
    after_login_page: {
      url: 'http://listening.intel1.zanroo.com/dashboard',
      title: 'Dashboard'
    }
  }
]
