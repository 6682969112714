import update from 'immutability-helper'
import set from 'lodash/set'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
// import pullAllBy from 'lodash/pullAllBy'
import {
  SET_TEMPLATE_RESOURCE,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE
} from 'constants/ActionTypes'

const initialState = {}

const template = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPLATE_RESOURCE: {
      if (!state[action.payload.product]) {
        set(state, action.payload.product, {})
      }
      state = update(state, {
        [action.payload.product]: {
          [action.payload.resource]: { $set: action.payload.data }
        }
      })

      const loaded = [
        get(state, [action.payload.product, 'keywords'], false),
        get(state, [action.payload.product, 'monitor'], false),
        get(state, [action.payload.product, 'engagement'], false),
        get(state, [action.payload.product, 'catTag'], false),
        get(state, [action.payload.product, 'templates'], false)
      ]
      if (indexOf(loaded, false) === -1) {
        state = update(state, {
          [action.payload.product]: {
            loaded: { $set: true }
          }
        })
      }
      return state
    }
    case CREATE_TEMPLATE: {
      return (state = update(state, {
        [action.payload.product]: {
          templates: { $push: [action.payload.data] }
        }
      }))
    }
    case UPDATE_TEMPLATE: {
      const templates = state[action.payload.product].templates
      const i = templates.findIndex(i => action.payload.data._id === i._id)
      return update(state, {
        [action.payload.product]: {
          templates: {
            [i]: { $set: action.payload.data }
          }
        }
      })
    }
    case DELETE_TEMPLATE: {
      const templates = state[action.payload.product].templates
      const newTemplates = []
      templates.forEach(template => {
        if (template._id === action.payload.templateId) return
        newTemplates.push(template)
      })
      return update(state, {
        [action.payload.product]: {
          templates: { $set: newTemplates }
        }
      })
    }

    default:
      return state
  }
}

export default template
