import { createAction } from 'redux-actions'
import { bindMiddlewaresCreator, withThunkMiddleware } from 'utils/actions'
import apis from 'apis/crm/keywords'

const actions = {
  set: createAction('CRM/KEYWORDS/SET')
}

const middlewares = {
  async getKeywords (dispatch, state, payload) {
    const {
      body: {
        items = {}
      } = {}
    } = await apis.apiGetKeyword(null, 'crm')
    dispatch(actions.set(items))
  }
}

actions.middlewares = bindMiddlewaresCreator(middlewares, withThunkMiddleware())

export default actions
