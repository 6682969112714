import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import {
  compose,
  defaultProps,
  withHandlers,
  withState,
  withProps
} from 'recompose'
import update from 'immutability-helper'
import _ from 'lodash'
import { FormControl, FormGroup } from 'react-bootstrap'
import {
  getExcludeValue,
  setExcludeValue,
  // getPatternLabel,
  setPatternLabel
} from '../patternUtils'
import InlineControlList from './InlineControlList'
// import InlineControl from './InlineControl'
// import InputControl from './InputControl'

import toastr from '@zanroo/react-redux-toastr-arun'

const Includes = InlineControlList
const Excludes = InlineControlList
// const NewInclude = InlineControl
// const NewExclude = InlineControl
// const QuickMode = InputControl

export const Patterns = {
  setPropTypes: {
    pattern: PropTypes.object,
    onSubmit: PropTypes.func
  },
  defaultProps: {
    pattern: {
      includes: [],
      excludes: []
    },
    onSubmit() { }
  },
  withHandlers: ({ onSubmit }) => ({
    /**
     * @desc update or add more props.pattern.includes
     * @desc callback props.onSubmit with new props.pattern to above called for handles new data
     * @param {Array} value
     * @param {Number} i
     */
    onSubmitInclude: ({ pattern, refs }) => (value, i) => {
      if (pattern === null) {
        pattern = { includes: [], excludes: [] }
      }

      if (value.replace(/\s/g, '') === '') {
        toastr.error('ERROR', 'Can not add empty string')
        return null
      }

      if (_.indexOf(pattern.includes, value) !== -1) {
        return null
      }

      let updated = pattern
      if (i === undefined) {
        updated = update(pattern, {
          includes: {
            $push: [value]
          }
        })
      } else {
        updated = update(pattern, {
          includes: {
            [i]: { $set: value }
          }
        })
      }
      onSubmit(updated)
      refs.includeTextBox.value = ''
    },
    /**
     * @desc update or add more props.pattern.excludes
     * @desc callback props.onSubmit with new props.pattern to above called for handles new data
     * @param {Array} value
     * @param {Number} i
     */
    onSubmitExclude: ({ pattern, refs }) => (value, i) => {
      if (pattern === null) {
        pattern = { includes: [], excludes: [] }
      }

      if (value.replace(/\s/g, '') === '') {
        toastr.error('ERROR', 'Can not add empty string')
        return null
      }

      // @FIXME : this function could move to util
      let excludePatternToString = []
      _.forEach(pattern.excludes, datum => {
        excludePatternToString.push(_.toString(datum))
      })
      if (_.indexOf(excludePatternToString, value) !== -1) return null

      let updated = pattern
      if (i === undefined) {
        updated = update(pattern, {
          excludes: {
            $push: [setExcludeValue(value)]
          }
        })
      } else {
        updated = update(pattern, {
          excludes: {
            [i]: { $set: setExcludeValue(value) }
          }
        })
      }
      onSubmit(updated)
      refs.excludeTextBox.value = ''
    },
    /**
     * @desc remove props.pattern.includes
     * @desc callback props.onSubmit with new props.pattern to above called for handles new data
     * @param {Number} i
     */
    onDeleteInclude: ({ pattern }) => i => {
      const updated = update(pattern, {
        includes: {
          $splice: [[i, 1]]
        }
      })
      onSubmit(updated)
    },
    /**
     * @desc remove props.pattern.excludes
     * @desc callback props.onSubmit with new props.pattern to above called for handles new data
     * @param {Number} i
     */
    onDeleteExclude: ({ pattern }) => i => {
      const updated = update(pattern, {
        excludes: {
          $splice: [[i, 1]]
        }
      })
      onSubmit(updated)
    },
    /**
     * @desc callback submit new pattern with setPatternLabel({String})
     * @param {String} pattern
     */
    onSubmitRaw: () => pattern => {
      onSubmit(setPatternLabel(pattern))
    },
    /**
     * @desc handle press 'Enter' key on text-input then blur that component and call onSubmitFunction
     * @param {Function} onSubmitFunction
     */
    onEnterInput: props => onSubmitFunction => e => {
      if (e.key === 'Enter') {
        e.target.blur()
        if (e.target.value !== '') onSubmitFunction(e.target.value)
      }
    }
  }),
  /**
   * @desc initial props.raw for handlers advance raw mode or ui mode
   * @typedef {Boolean} props.raw
   */
  withState: ['raw', 'setRaw', false],
  /**
   * @example
   * <Layout {...handlers}>
   *  <Row>
   *    <Button {...handlers}>Normal Mode</Button> <Button {...handlers}>Advance Mode</Button>
   *    raw && <QuickMode:InputControl />
   *    !raw && (
   *      <Includes:InlineControlList {...handlers}>
   *        <InlineControl key={0} {...handlers} />
   *        <InlineControl key={1} {...handlers} />
   *        <InlineControl key={2} {...handlers} />
   *      </Includes>
   *      <Excludes:InlineControlList {...handlers}>
   *        <InlineControl key={0} {...handlers} />
   *        <InlineControl key={1} {...handlers} />
   *        <InlineControl key={2} {...handlers} />
   *      </Excludes>
   *    )
   *  </Row>
   * </Layout>
   */
  render(props) {
    /**
     * @typedef {{ includes: Array, excludes: [ Array ] }} pattern
     * @typedef {Function} onSubmitInclude - from above withHandlers
     * @typedef {Function} onSubmitExclude - from above withHandlers
     * @typedef {Function} onDeleteInclude - from above withHandlers
     * @typedef {Function} onDeleteExclude - from above withHandlers
     * @typedef {Function} onSubmitRaw - from above withHandlers
     * @typedef {Boolean} raw - from above withState
     * @typedef {Function} setRaw - from above withState
     */
    const {
      pattern,
      onSubmitInclude,
      onSubmitExclude,
      onDeleteInclude,
      onDeleteExclude,
      // onSubmitRaw,
      // raw,
      // setRaw,
      selected,
      onEnterInput,
      refs,
      hideEditPatternPanel
    } = props

    let newPattern = {}
    // let isNotSetPattern = false

    /* if (!!selected && !selected.hasOwnProperty('_id')) {
      console.log('ADD NEW CATEGORY OR TAGS')
    } */

    if (pattern === null) {
      // return null
      // isNotSetPattern = true
      newPattern = { excludes: [], includes: [] }
    } else {
      newPattern = pattern
    }

    const blockedStyle = condition => {
      if (!condition) {
        return {
          height: '100%',
          flex: '1'
        }
      }
      return {
        pointerEvents: 'none',
        flex: '1',
        opacity: '0.4',
        height: '100%'
      }
    }

    const { includes, excludes } = newPattern
    if (!_.has(selected, 'parent_id')) return null

    return (
      <div style={blockedStyle(!selected._id || hideEditPatternPanel)}>
        <div className='add-key-right'>
          <div className='add-key-head'>
            <div className='header-text'>
              <p>Add New Keywords</p>
            </div>
          </div>
          <div className='result-include'>
            <div
              className='keyword-label-input'
              style={{ marginBottom: '0px' }}
            >
              <p style={{
                fontWeight: 'bold',
                fontSize: '16px',
                marginBottom: '20px'
              }}>Included Keywords</p>
              <div className='add-keyword'>

                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder="Add Included Keyword"
                    inputRef={r => {
                      refs.includeTextBox = r
                    }}
                    onKeyPress={onEnterInput(onSubmitInclude)}
                  // onChange={e => setIncludeText(e.target.value)}
                  />
                  <span onClick={() => onSubmitInclude(refs.includeTextBox.value)}>Enter</span>
                </FormGroup>

              </div>
            </div>
            {includes.length > 0 ? (
              <div className='keyword-label-input result-list'>
                <p>Result Included</p>
              </div>
            ) : null}
          </div>
          <div className='edit-key'>
            <Includes
              list={includes}
              onDelete={onDeleteInclude}
              onSubmit={onSubmitInclude}
            />
          </div>

          <div className='result-exclude'>
            <div
              className='keyword-label-input'
              style={{ marginBottom: '0px' }}
            >
              <p style={{
                fontWeight: 'bold',
                fontSize: '16px',
                marginBottom: '20px',
                marginTop: '30px'
              }}>Excluded Keywords</p>
              <div className='add-keyword'>

                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder="Add Included Keyword"
                    disabled={_.isEmpty(_.get(pattern, 'includes', []))}
                    inputRef={r => {
                      refs.excludeTextBox = r
                    }}
                    onSubmit={onSubmitExclude}
                    onKeyPress={onEnterInput(onSubmitExclude)}
                  />
                  <span
                    onClick={_.isEmpty(_.get(pattern, 'includes', []))
                      ? () => { }
                      : () => onSubmitExclude(refs.excludeTextBox.value)}
                  >
                    Enter
                  </span>
                </FormGroup>

                {_.isEmpty(_.get(pattern, 'includes', [])) &&
                  <p
                    style={{
                      color: '#939495',
                      fontSize: '10px'
                    }}
                  >
                    Every keyword set must contain atleast 1 included keyword,
                    please enter include keyword first.
                  </p>
                }

              </div>
            </div>
            {excludes.length > 0 ? (
              <div className='keyword-label-input result-list'>
                <p>Result Excluded</p>
              </div>
            ) : null}

            <div className='edit-key'>
              <Excludes
                list={excludes}
                onDelete={onDeleteExclude}
                onSubmit={onSubmitExclude}
                getValue={getExcludeValue}
              />
            </div>
          </div>
          {/*        <QuickMode
            componentClass='textarea'
            value={getPatternLabel(newPattern)}
            submitEventKeys={[]}
            clearEventKeys={[]}
            cancelEventKeys={[]}
            submitEventTypes={['blur']}
            onSubmit={onSubmitRaw}
          /> */}
        </div>
      </div>
    )
    // return (
    //   <Row>
    //     <Col xs={12}>
    //       <Button bsSize="xs" onClick={() => setRaw(false)}>
    //         Normal Mode
    //       </Button>
    //       <Button bsSize="xs" onClick={() => setRaw(true)}>
    //         Advance Mode
    //       </Button>
    //     </Col>
    //     {raw && (
    //       <QuickMode
    //         componentClass="textarea"
    //         value={getPatternLabel(pattern)}
    //         submitEventKeys={[]}
    //         clearEventKeys={[]}
    //         cancelEventKeys={[]}
    //         submitEventTypes={['blur']}
    //         onSubmit={onSubmitRaw}
    //       />
    //     )}
    //     {!raw && (
    //       <Col sm={6}>
    //         <h3>Required keywords</h3>
    //         <Includes
    //           list={includes}
    //           onDelete={onDeleteInclude}
    //           onSubmit={onSubmitInclude}
    //         />
    //         <NewInclude onSubmit={onSubmitInclude} />
    //       </Col>
    //     )}
    //     {!raw && (
    //       <Col sm={6}>
    //         <h3>Excluded keywords</h3>
    //         <Excludes
    //           list={excludes}
    //           onDelete={onDeleteExclude}
    //           onSubmit={onSubmitExclude}
    //           getValue={getExcludeValue}
    //         />
    //         <NewExclude onSubmit={onSubmitExclude} />
    //       </Col>
    //     )}
    //   </Row>
    // )
  }
}

export default compose(
  defaultProps(Patterns.defaultProps),
  withHandlers(Patterns.withHandlers),
  withState(...Patterns.withState)
)(Patterns.render)
