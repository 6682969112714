import { API_CENTRAL_PRODUCTS } from 'constants/ApiUrls'
import {
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  METHOD_DELETE
} from 'constants/Methods'
import { createApi } from 'utils/index'

/** API FOR CRM PRODUCT */

export const apiGetTicketTags = (product = 'crm') =>
  createApi(`${API_CENTRAL_PRODUCTS}/${product}/ticket-tags`, METHOD_GET)

export const apiCreateTicketTags = (data = {}, product = 'crm') => {
  let type = 'group'
  if (data.ticket_group_id) {
    type = 'category'
  }
  if (data.ticket_cat_id) {
    type = 'tag'
  }
  return createApi(
    `${API_CENTRAL_PRODUCTS}/${product}/ticket-tags/types/${type}`,
    METHOD_POST,
    data
  )
}

export const apiUpdateTicketTags = (data = {}, product = 'crm') => {
  let type = 'group'
  if (data.ticket_group_id) {
    type = 'category'
  }
  if (data.ticket_cat_id) {
    type = 'tag'
  }
  if (!data._id) {
    // return Promise.reject({
    //   error: true,
    //   message: `Can't update ticket tags not found property "_id"`
    // })
    return Promise.reject(new Error(`Can't delete ticket tags not found property "_id"`))
  }
  return createApi(
    `${API_CENTRAL_PRODUCTS}/${product}/ticket-tags/types/${type}/${data._id}`,
    METHOD_PUT,
    data
  )
}

export const apiDeleteTicketTags = (data = {}, product = 'crm') => {
  let type = 'group'
  if (data.ticket_group_id) {
    type = 'category'
  }
  if (data.ticket_cat_id) {
    type = 'tag'
  }
  if (!data._id) {
    // return Promise.reject({
    //   error: true,
    //   message: `Can't delete ticket tags not found property "_id"`
    // })
    return Promise.reject(new Error(`Can't delete ticket tags not found property "_id"`))
  }
  return createApi(
    `${API_CENTRAL_PRODUCTS}/${product}/ticket-tags/types/${type}/${data._id}`,
    METHOD_DELETE
  )
}

// TODO: Tan, please remove this and fix anything is used this function
// export const apiCrmGetTag = (product = 'crm') =>
//   createApi(`${API_CENTRAL_PRODUCTS}/${product}/ticket-tags`, METHOD_GET)
// export const apiCrmCreateTag = (data, product = 'crm', types = 'group') =>
//   createApi(
//     `${API_CENTRAL_PRODUCTS}/${product}/ticket-tags/types/${types}`,
//     METHOD_POST,
//     data
//   )
