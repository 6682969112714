import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { Route, Redirect } from 'react-router-dom'
import { RedirectExternal } from './Redirect'
import { compose } from 'recompose'
import { Authenticated, Paymented, Intercom } from 'enhancer/index'
import { NORMAL, MEDIUM, STRONG, VERY_STRONG, MEDIUM_STRONG } from 'constants/PasswordStrength'
import moment from 'moment'
import {
  // AUTHENTICATED,
  // PAYMENTED,
  // NONPAYMENT,
  // ADDEDCARD,
  GUEST,
  NOT_OEM
} from 'constants/Middlewares'

// @FIXME mock data
import { sites } from 'modules/loginWithSignup/customLayout/temporaryResource/constants'

// Default redirect to path='/'
const DefaultShouldFailed = () => <Redirect to='/' />

const middlewares = {
  AUTHENTICATED(props) {
    return !!props.authenticated
  },
  PAYMENTED(props) {
    return !!props.paymented
  },
  NONPAYMENT(props) {
    return !props.paymented
  },
  GUEST(props) {
    return !props.authenticated
  },
  ADDEDCARD(props) {
    return !!props.addedCard
  },
  ADMIN(props) {
    return props.user.role.name === 'Zanroo_Admin'
  },
  NOT_OEM(props) {
    console.log("================= ~ file: RouteMiddleware.js ~ line 50 ~ NOT_OEM ~ window.location.origin", window.location.origin)
    console.log("================= ~ file: RouteMiddleware.js ~ line 51 ~ NOT_OEM ~ process.env.REACT_APP_URL", process.env.REACT_APP_URL)

    return window.location.origin === process.env.REACT_APP_URL
  },
  PRODUCT_ACTIVATE(props) {
    const activeListening = get(props, 'account.product.listening.active', false)
    const activeCRM = get(props, 'account.product.crm.active', false)
    const activeQuickAnalytic = get(props, 'account.product.quick_analytic.active', false)
    const activeInfluencer = get(props, 'account.product.influencer.active', false)
    const activeAdsScanner = get(props, 'account.product.ads_scanner.active', false)
    const activeCustomViz = get(props, 'account.product.custom_viz.active', false)
    const activePublishing = get(props, 'account.product.publishing.active', false)

    // campaign
    // cms
    // visual

    return activeListening || activeCRM || activeQuickAnalytic || activeInfluencer || activeAdsScanner || activeCustomViz || activePublishing
  },
  /**
   * @desc PRODUCT_SETTING_PAGE is middleware for every page that contain product in url
   * @param {*} product for get current product from url parameter
   * @param {*} activateProduct for get all activate product
   */
  PRODUCT_SETTING_PAGE(props) {
    const product = get(props, 'computedMatch.params.product', '')
    const activateProduct = get(props, 'account.product', {})
    if (product === 'main') return true
    if (get(activateProduct, `${product}.active`, false)) return true
    return false
  },
  /**
   * @desc PASSWORD_NOT_EXPIRED is middleware for check that password is expired or not
   * @param {*} props
   */
  PASSWORD_NOT_EXPIRED(props) {
    const { account, user, location } = props
    const FIX_PATHNAME = '/settings/account/main'
    const FIX_SEARCH = '?page=change-password'

    if (location.pathname === FIX_PATHNAME && location.search === FIX_SEARCH) {
      return true
    }

    if (get(user, 'must_change_password', false)) {
      return false
    }

    let lifeTimeDate = 0

    const passwordStrength = get(account, 'password_strength', MEDIUM)
    switch (passwordStrength) {
      case MEDIUM_STRONG:
        lifeTimeDate = 90
        break
      case STRONG:
        lifeTimeDate = 60
        break
      case VERY_STRONG:
        lifeTimeDate = 30
        break
      // case TEST_STRONG:
      //   lifeTimeDate = 1
      //   break
      case NORMAL:
      case MEDIUM:
      default:
        return true
    }

    const _changeDate = get(user, 'password_change_date', 'not_found')
    const changeDate = moment(_changeDate)
    if (changeDate._isValid) {
      var expireDate = moment(_changeDate).startOf('day').add(lifeTimeDate, 'days')
      const today = moment()
      const remainDate = expireDate.diff(today, 'days')
      if (remainDate >= 0) {
        return true
      }
    }

    return false
  },
  PERMISSIONS_REQUIRED(props) {
    const permissionsRequired = get(props, 'permissionsRequired')
    const product = get(props, 'computedMatch.params.product', '')
    const permissions = get(props, 'user.role.permissions', [])

    const isValid = (req) => {
      return req.every((permission) => {
        if (Array.isArray(permission)) {
          return permission.some((i) => permissions.includes(i))
        } else if (typeof permission === 'string') {
          return permissions.includes(permission)
        }
        return false
      })
    }

    if (permissionsRequired.all && permissionsRequired.all.length) {
      if (isValid(permissionsRequired.all)) {
        return true
      }
      return false
    } else {
      if (!permissionsRequired[product]) return false

      if (!permissionsRequired[product].length) {
        return true
      } else {
        if (isValid(permissionsRequired[product])) {
          return true
        }
        return false
      }
    }
  }
}

class RouteMiddleware extends React.Component {
  constructor() {
    super()
    this.wrongIndexAt = null
  }
  middlewareIsValidAllTypes(types = []) {
    for (let i = 0; i < types.length; i++) {
      // Set wrong index at verify {types} for choose render failed components.
      this.wrongIndexAt = i
      const type = types[i]
      // Call verify any middleware logic from {this.middlewares}
      if (!middlewares[type](this.props)) {
        return false
      }
    }
    // Reset wrong index to null
    this.wrongIndexAt = null
    return true
  }
  shouldComponentFailed() {
    const { componentFailed = null, types, payment } = this.props

    const planSelected = get(payment, 'planSelected')

    if (!isEmpty(planSelected) && types[this.wrongIndexAt] === GUEST) {
      return () => <Redirect to='/settings/account/payment' />
    }

    /**
     * @FIXME mock data from const
     */
    if (types[this.wrongIndexAt] === NOT_OEM) {
      try {
        const oemObject = find(sites, { origin: window.location.origin })
        const redirectPage = get(oemObject, 'after_login_page.url', '')
        if (redirectPage !== '') {
          return () => <RedirectExternal loc={redirectPage} />
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (
      Array.isArray(componentFailed) &&
      componentFailed[this.wrongIndexAt] !== undefined
    ) {
      return componentFailed[this.wrongIndexAt]
    }
    return DefaultShouldFailed
  }
  render() {
    const { types, component = () => null, ...rest } = this.props
    const isValid = this.middlewareIsValidAllTypes(types, this.props)
    let ShouldFailed = this.shouldComponentFailed()
    return (
      <Route
        {...rest}
        render={childProps =>
          isValid
            ? React.createElement(component, { ...childProps, ...rest })
            : React.createElement(ShouldFailed, { ...childProps, ...rest })
        }
      />
    )
  }
}

RouteMiddleware.propTypes = {
  component: PropTypes.func.isRequired,
  types: PropTypes.array,
  componentFailed: PropTypes.array,
  authenticated: PropTypes.bool.isRequired,
  paymented: PropTypes.bool.isRequired,
  permissionsRequired: PropTypes.object
}

RouteMiddleware.defaultProps = {
  types: [],
  componentFailed: [],
  permissionsRequired: {
    all: [],
    main: [],
    listening: [],
    crm: []
  }
}

export default compose(Authenticated, Paymented, Intercom)(RouteMiddleware)
