// import React from 'react'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import { compose, lifecycle } from 'recompose'
import { Container as AuthContainer } from 'enhancer/Authenticated'
import { PaymentContainer } from 'enhancer/Paymented'
import { apiLogout } from 'apis/auth'

const Lifecycle = lifecycle({
  async componentDidMount () {
    await apiLogout()
    const {
      setUser,
      setAccount,
      setPaymentCustomer,
      setPaymentPlanSelected,
      setPaymentInvoices
    } = this.props
    // call action to set payment.planSelected in store to empty
    setPaymentPlanSelected({})
    // call action to set payment.customer in store to empty
    setPaymentCustomer({})
    // call action to set account in store to empty
    setAccount({})
    // call action to set user in store to empty
    setUser({})
    // call action to set invoices in store to empty
    setPaymentInvoices([])

    // set cookie to empty
    Cookies.set(process.env.REACT_APP_ACCESS_TOKEN_NAME, '', {
      domain: process.env.REACT_APP_SSO_COOKIE_DOMAIN
    })
  }
})

/**
 * For logout
 * When visit route /login will be update user, account in store to empty object
 */
export function Logout (props) {
  return null
}

Logout.propTypes = {
  setUser: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired
}

Logout.defaultProps = {
  setUser: () => {},
  setAccount: () => {}
}

// Wraped AuthContainer
export default compose(AuthContainer, PaymentContainer, Lifecycle)(Logout)
