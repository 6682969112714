let REACT_APP_CENTRAL_ROOT_URL = process.env.REACT_APP_CENTRAL_ROOT_URL
let REACT_APP_API_URL = process.env.REACT_APP_API_URL
let REACT_APP_LINE_WEBHOOK_URL = process.env.REACT_APP_LINE_WEBHOOK_URL
let REACT_APP_WHATSAPP_WEBHOOK_URL = process.env.REACT_APP_WHATSAPP_WEBHOOK_URL
let REACT_APP_WHATSAPP_VERIFY_TOKEN = process.env.REACT_APP_WHATSAPP_VERIFY_TOKEN

const currentDomain = window.location.origin
if (currentDomain !== process.env.REACT_APP_API_URL) {
  REACT_APP_API_URL = currentDomain
}

export const API_CENTRAL_ROOT_URL = `${REACT_APP_CENTRAL_ROOT_URL}`

export const API_CENTRAL_ACCOUNTS = `${REACT_APP_API_URL
  }/api/v1.0/accounts`

export const API_CENTRAL_EXCLUDES_ACCOUNTS = `${REACT_APP_API_URL
  }/api/v1.0/accounts/keywords/excludes`

export const API_CENTRAL_ROLES = `${REACT_APP_API_URL
  }/api/v1.0/accounts/roles`

export const API_CENTRAL_GROUPS = `${REACT_APP_API_URL
  }/api/v1.0/accounts/groups`

export const API_CENTRAL_GROUP_OPTION = `${REACT_APP_API_URL
  }/api/v1.0/accounts/products`

export const API_CENTRAL_USERS = `${REACT_APP_API_URL
  }/api/v1.0/accounts/users`

export const API_CENTRAL_EMAIL_CONTACT = `${REACT_APP_API_URL
  }/api/v1.0/email-contact`

export const API_CENTRAL_AUTH_LOGIN = `${REACT_APP_API_URL
  }/auth/login`

export const API_CENTRAL_AUTH_LOGOUT = `${REACT_APP_API_URL
  }/auth/logout`

export const API_CENTRAL_AUTH_LOGIN_REMOTE = `${REACT_APP_API_URL
  }/auth/login/remote`

export const API_CENTRAL_GET_USER_INFO = `${REACT_APP_API_URL
  }/api/v1.0/users/me`

export const API_CENTRAL_PAYMENT = `${REACT_APP_API_URL
  }/api/v1.0/accounts/stripe`

export const API_CENTRAL_PLAN = `${REACT_APP_API_URL
  }/api/v1.0/plans`

export const API_CENTRAL_PRODUCTS = `${REACT_APP_API_URL
  }/api/v1.0/accounts/products`

export const API_CENTRAL_AUTH_FACEBOOK = `${REACT_APP_API_URL
  }/api/v1.0/search/auth/facebook`

export const API_CENTRAL_ADMIN_ACCOUNTS = `${REACT_APP_API_URL
  }/api/admin/accounts`

export const API_CENTRAL_ADMIN_GROUP = `${REACT_APP_API_URL
  }/api/admin/accounts/group`

export const API_CENTRAL_ADMIN_USER = `${REACT_APP_API_URL
  }/api/admin/user`

export const API_CENTRAL_ADMIN_MIGRATE_KEYWORD = `${REACT_APP_API_URL
  }/api/admin/migrate-keyword`

export const API_CENTRAL_ADMIN_MIGRATE_TAG = `${REACT_APP_API_URL
  }/api/admin/migrate-tag`

export const API_CENTRAL_ADMIN_MIGRATE_MONITOR = `${REACT_APP_API_URL
  }/api/admin/migrate-monitor`

export const API_CENTRAL_ADMIN_CUSTOMERS = `${REACT_APP_API_URL
  }/api/admin/customers`

export const API_CENTRAL_ACCOUNTS_PLAN = `${REACT_APP_API_URL
  }/api/v1.0/accounts/plan`

export const API_CENTRAL_PRODUCT_PERMISSION = `${REACT_APP_API_URL
  }/api/admin/permissions`

export const API_CENTRAL_MAP_PERMISSIONS = `${REACT_APP_API_URL
  }/api/v1.0/accounts/map-permissions`

export const API_CENTRAL_DEFAULT_ROLE = `${REACT_APP_API_URL
  }/api/admin/default_role`

export const API_PANTIP_AUTH = `${REACT_APP_API_URL
  }/auth/login/pantip`

export const API_EMAIL_AUTH = `${REACT_APP_API_URL
  }/auth/login/email`

export const API_WHATSAPP_AUTH = `${REACT_APP_API_URL
  }/auth/login/whatsapp`

export const API_WECHAT_AUTH = `${REACT_APP_API_URL
  }/auth/login/wechat`

export const API_GOOGLE_PLAY_AUTH = `${REACT_APP_API_URL
  }/auth/login/google_play`

export const API_GOOGLE_PLAY_APP = `${REACT_APP_API_URL
  }/auth/login/google_play_app`

export const API_FB_AUTH = `${REACT_APP_API_URL
  }/auth/login/facebook`

export const API_OUTLOOK_AUTH = `${REACT_APP_API_URL
  }/auth/login/outlook`

export const API_GOOGLE_BUSINESS_AUTH = `${REACT_APP_API_URL
  }/auth/login/google`

export const API_YOUTUBE_AUTH = `${REACT_APP_API_URL
  }/auth/login/youtube`

export const API_TIKTOK_AUTH = `${REACT_APP_API_URL
  }/auth/login/tiktok`

export const API_GMAIL_AUTH = `${REACT_APP_API_URL
  }/auth/login/gmail`

export const API_TWITTER_AUTH = `${REACT_APP_API_URL
  }/auth/login/twitter`

export const API_SHOPEE_AUTH = `${REACT_APP_API_URL
  }/auth/login/shopee`

export const API_PANTIP = `${REACT_APP_API_URL}/api/v1.0/pantip`

export const API_FACEBOOK = `${REACT_APP_API_URL
  }/api/v1.0/facebook`

export const API_ENGAGEMENT_APP = `${REACT_APP_API_URL
  }/api/v1.0/engagement-app`

export const API_ENGAGEMENT_CHATBOT = `${REACT_APP_API_URL
  }/api/v1.0/engagement-chatbot`

export const API_ENGAGEMENT_WEBCHAT = `${REACT_APP_API_URL
  }/api/v1.0/engagement-webchat`

export const API_TWITTER = `${REACT_APP_API_URL}/api/v1.0/twitter`

export const API_APP_STORE = `${REACT_APP_API_URL}/api/v1.0/app_store`

export const API_SLA = `${REACT_APP_API_URL}/api/v1.0/sla`

export const API_CANNED_RESPONSE = `${REACT_APP_API_URL}/api/v1.0/canned_response`

export const API_LINE = `${REACT_APP_API_URL}/api/v1.0/line`

export const API_CENTRAL_UPLOAD = `${REACT_APP_API_URL
  }/api/v1.0/upload`

export const API_CENTRAL_TEMPLATE = `${REACT_APP_API_URL
  }/api/v1.0/accounts/products/:product/templates`

export const API_CENTRAL_BLACKLIST = `${REACT_APP_API_URL
  }/api/v1.0/accounts/blacklist`

export const API_LINE_WEBHOOK_URL = REACT_APP_LINE_WEBHOOK_URL

export const API_WHATSAPP_WEBHOOK_URL = REACT_APP_WHATSAPP_WEBHOOK_URL

export const WHATSAPP_VERIFY_TOKEN = REACT_APP_WHATSAPP_VERIFY_TOKEN

// export const API_KONG_SERVER = `${REACT_APP_API_URL}/kong`

export const API_CENTRAL_INTEGRATION_API = `${REACT_APP_API_URL
  }/api/v1.0/accounts/integration-api`

export const API_SEARCH_SOCIAL = `${REACT_APP_API_URL
  }/api/v1.0/search`

export const API_CENTRAL_MONITORING = `${REACT_APP_API_URL
  }/api/v1.0/accounts/monitors`

export const API_CENTRAL_ENGAGEMENT = `${REACT_APP_API_URL
  }/api/v1.0/accounts/engagements`

export const API_AGENDA_STATUS = `${REACT_APP_API_URL
  }/api/v1.0/accounts/agenda_status`

export const API_ACTIVITY_LOG = `${REACT_APP_API_URL
  }/api/v1.0/accounts/activity-log`

export const API_ACTIVITY_LOG_SEARCH = `${REACT_APP_API_URL
  }/api/v1.0/accounts/activity-log/_search`

export const API_CRM_CONNECTIONS = `${REACT_APP_API_URL
  }/api/v1.0/crm/connections`

export const API_CRM_AUTO_ASSIGN = `${REACT_APP_API_URL
  }/api/v1.0/crm/auto_assign`

export const API_CRM_AUTO_CANNED_RESPONSE = `${REACT_APP_API_URL
  }/api/v1.0/crm/auto_canned_response`

export const API_VERIFICATION = `${REACT_APP_API_URL
  }/api/v1.0/crm/verification`

export const API_WORKING_STATUS = `${REACT_APP_API_URL
  }/api/v1.0/crm/working-status`

export const API_CENTRAL_CHECK_EMAIL = `${REACT_APP_API_URL
  }/api/v1.0/check-email`

// export const API_CENTRAL_PUNCH_ACTION = `${
//   REACT_APP_API_URL
// }/api/v1.0/users/punch-action`

export const API_CENTRAL_PUNCH_LOG = `${REACT_APP_API_URL
  }/api/v1.0/accounts/punch-log`

export const API_CENTRAL_REGISTER_WITH_PAYMENT = `${REACT_APP_API_URL
  }/api/v1.0/register-firstpayment`

export const API_CRM_CUSTOM_ACTION = `${REACT_APP_API_URL
  }/api/v1.0/crm/custom-action`

export const API_CRM_AUTO_SENTIMENT = `${REACT_APP_API_URL
  }/api/v1.0/crm/auto_sentiment`

export const API_UI_SETTINGS = `${REACT_APP_API_URL
  }/api/v1.0/crm/ui_settings`

export const API_CRM_GROUP_MANAGE = `${REACT_APP_API_URL
  }/api/v1.0/crm/group_manage`


export const IMAGE_SERVER_BASE_URL = process.env.REACT_APP_IMAGE_SERVER

export const ROCKET_API = process.env.REACT_APP_ROCKET_API

export const API_CENTRAL_RESET_PASSWORD = `${REACT_APP_API_URL
  }/api/reset-password`

export const API_CENTRAL_GET_LOGIN_ATTEMPT = `${REACT_APP_API_URL}/api/v1.0/get-login-attempt`

export const API_CENTRAL_ACCOUNTS_V1_1 = `${REACT_APP_API_URL}/api/v1.1/accounts`

export const API_CMS_INTEREST_TAGS = `${REACT_APP_API_URL
  }/api/v1.0/cms/interest-tag`

