import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import update from 'immutability-helper'

import actions from 'actions/crm/autoAssign'
import crmState from './initial.state'

const handler = (actions, initialState) => handleActions({
  [actions.data.set]: (state, action) => {
    return update(state, {
      data: { $set: action.payload }
    })
  },
  [actions.data.reset]: (state) => {
    return update(state, {
      data: { $set: state.default }
    })
  },
  [actions.default.set]: (state, action) => {
    return update(state, {
      data: { $set: JSON.parse(JSON.stringify(action.payload))},
      default: { $set: action.payload }
    })
  },
  [actions.api.pending]: (state, action) => {
    return update(state, {
      api: {
        pending: { $set: true },
        success: { $set: false }
      }
    })
  },
  [actions.api.success]: (state, action) => {
    return update(state, {
      api: {
        pending: { $set: false },
        success: { $set: true }
      }
    })
  },
  [actions.api.error]: (state, action) => {
    return update(state, {
      api: {
        pending: { $set: false },
        success: { $set: false },
        error: { $set: action.payload }
      }
    })
  }
}, initialState)

export default combineReducers({
  settings: handler(actions.settings, crmState.autoAssign.settings),
  agents: handler(actions.agents, crmState.autoAssign.agents),
  conditions: handler(actions.conditions, crmState.autoAssign.conditions),
  rules: handler(actions.rules, crmState.autoAssign.rules)
})
