/**
 * @param {String} URI = 'http://example.com/:foo/:bar'
 * @param {Object} data = { foo: 'oof', bar: '1' }
 * @return {String} 'http://example.com/oof/1'
 */
export default function parseApi (URI, data) {
  let url = URI
  Object.keys(data).forEach(key => {
    const val = data[key]
    url = url.replace(`:${key}`, val)
  })
  return url
}
