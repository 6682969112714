import { compose, lifecycle, renderNothing, withState } from 'recompose'
import has from 'lodash/has'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withSocket } from 'enhancer/socket'

export default compose(
  connect(
    createStructuredSelector({
      authenticated: state => has(state, 'user.name') && has(state, 'user._id') && has(state, 'user.account_id'),
      user: state => state.user
    })
  ),
  withSocket,
  withState('connected', 'setConnected', false),
  withState('updated', 'setUpdated', false),
  lifecycle({
    componentDidMount () {
      const {
        connection,
        setConnected
      } = this.props
      /**
       * SOCKET IO
       */
      this.onConnect = () => {
        console.log('on connect')
        setConnected(true)
      }

      this.onReconnect = () => {
        console.log('on connect')
        connection.setStatus('online')
      }

      this.onDisconnect = () => {
        console.log('on disconnect')
        connection.setStatus('offline')
      }

      if (connection) {
        console.log('listener events connect, disconnect')
        connection.socket.on('connect', this.onConnect)
        connection.socket.on('disconnect', this.onDisconnect)
        connection.socket.on('reconnect', this.onReconnect)
        connection.socket.on('reconnecting', this.onReconnect)
        connection.socket.on('reconnect_error', this.onDisconnect)
        connection.socket.on('reconnect_failed', this.onDisconnect)
      }
    },
    componentDidUpdate () {
      const {
        setUpdated,
        authenticated,
        connection,
        connected,
        updated,
        user
      } = this.props

      if (authenticated && connected && !updated) {
        const obj = {
          'name': user.name,
          'user_id': user._id,
          'acc_id': user.account_id,
          'punch_in': get(user, 'punch_data.in_status', false)
        }
        connection.register(obj, (err, res) => {
          if (err) {
            console.error(err.message)
          }
        })
        console.log('setStatus: online')
        connection.setStatus('online')
        setUpdated(true)
      }
      if (!authenticated && connected && updated) {
        console.log('setStatus: offline')
        connection.setStatus('offline')
        setUpdated(false)
      }
    },
    componentWillUnmount () {
      const { connection } = this.props
      if (connection) {
        connection.setStatus('offline')
        connection.socket.off('connect', this.onConnect)
        connection.socket.off('disconnect', this.onDisconnect)
        connection.socket.off('reconnect', this.onReconnect)
        connection.socket.off('reconnecting', this.onReconnect)
        connection.socket.off('reconnect_error', this.onDisconnect)
        connection.socket.off('reconnect_failed', this.onDisconnect)
      }
    }
  })
)(renderNothing)
