import autoAssign from './autoAssign'
import keywords from './keywords'
import tags from './tags'
import engagements from './engagements'

export default {
  autoAssign,
  keywords,
  tags,
  engagements
}
