import { handleActions } from 'redux-actions'
import update from 'immutability-helper'
import crmActions from 'actions/crm'

const initialState = {}

export default handleActions({
  [crmActions.engagements.set]: (state, action) => {
    return update(state, {
      items: {$set: action.payload}
    })
  }
}, initialState)
