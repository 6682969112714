import React from 'react'
import PropTypes from 'prop-types'


export const propTypes = {
  showModal: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
}

/**
 * For manage state show hide modal just wrap this on your component
 * @param {:ReactComponent} Component
 */
export default function (Component) {
  class ModalEnhancer extends React.Component {
    state = {
      showModal: false
    };
    handleModal (open = false) {
      if (this.state.showModal !== open) {
        this.setState({
          showModal: open
        })
      }
    }
    render () {
      /*
        <Component /> will receive 3 props and other parents props
        showModal:Boolean for parse to <Modal show={showModal} /> component
        openModal,closeModal :Function for handle state of showModal
       */
      return (
        <div>
      
        <Component
          {...this.props}
          showModal={this.state.showModal}
          openModal={() => this.handleModal(true)}
          closeModal={() => this.handleModal(false)}
        />
        </div>
      )
    }
  }
  return ModalEnhancer
}
