// import Cookies from 'js-cookie'

export default (url = 'http://0.0.0.0', config = {}, formData) =>
  fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      /* Authorization: `Bearer ${Cookies.get(
        process.env.REACT_APP_ACCESS_TOKEN_NAME
      ) || ''}`, */
      formData: formData
    },
    ...config
  })
