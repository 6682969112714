import update from 'immutability-helper'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import keyBy from 'lodash/keyBy'

import {
  SET_TICKET_TAGS,
  UPSERT_TICKET_TAG,
  UPDATE_TICKET_TAG_SELECTED,
  DELETE_TICKET_TAG,
  UPDATE_GROUP_SELECTED
} from 'constants/ActionTypes'

import initialState from './initial.state'

export default function (state = initialState.tags, action) {
  switch (action.type) {
    case SET_TICKET_TAGS: {
      if (!isArray(action.payload)) {
        return state
      }
      const mapItems = keyBy(action.payload, '_id')
      return update(state, {
        items: { $set: mapItems }
      })
    }
    case UPSERT_TICKET_TAG: {
      if (!isObject(action.payload)) {
        return state
      }
      return update(state, {
        items: {
          $set: { ...state.items, [action.payload._id]: action.payload }
        }
      })
    }
    case UPDATE_TICKET_TAG_SELECTED: {
      if (!isObject(action.payload)) {
        return state
      }
      return update(state, {
        selected: { $set: action.payload }
      })
    }
    case DELETE_TICKET_TAG: {
      if (!isObject(action.payload)) {
        return state
      }
      if (!action.payload._id) {
        return state
      }

      const itemKeys = Object.keys(state.items)
      const shouldDeleteKeys = {
        [action.payload._id]: 1
      }

      /**
       * @FIXME need flow fix, double check for find
       */
      let findRound = 0
      const obj = state.items[action.payload._id]
      if (obj.ticket_cat_id) {
        findRound = 0
      } else if (obj.ticket_group_id) {
        findRound = 1
      } else {
        findRound = 2
      }

      for (let i = 0; i < findRound; i++) {
        itemKeys.forEach(key => {
          const item = state.items[key]
          if (shouldDeleteKeys[item.ticket_group_id] || shouldDeleteKeys[item.parent_id]) {
            shouldDeleteKeys[item._id] = 1
          }
          if (shouldDeleteKeys[item.ticket_cat_id]) {
            shouldDeleteKeys[item._id] = 1
          }
        })
      }

      const newItems = { ...state.items }
      Object.keys(shouldDeleteKeys).forEach(key => {
        delete newItems[key]
      })
      return update(state, {
        items: { $set: newItems }
      })
    }
    case UPDATE_GROUP_SELECTED: {
      if (!isObject(action.payload)) {
        return state
      }
      return update(state, {
        groupSelected: { $set: action.payload }
      })
    }
    default:
      return state
  }
}
