import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import fbIcon from 'assets/img/channel/facebook.png'
import twitterIcon from 'assets/customize/twitter.svg'
import pantipIcon from 'assets/img/channel/pantip.png'
import lineIcon from 'assets/img/channel/line-logo.png'

const Wrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 100%;
  height: 100px;
  &:hover {
    cursor: pointer;
    color: #337ab7;
  }
`

const ButtonImage = styled.img`
  max-width: 256px;
  max-height: 256px;
  background-repeat: no-repeat;
  background-size: contain;
`

const FacebookImage = styled(ButtonImage)`
  background-image: url(${fbIcon});
`

const TwitterImage = styled(ButtonImage)`
  background-image: url(${twitterIcon});
`

const PantipImage = styled(ButtonImage)`
  background-image: url(${pantipIcon});
`

const LineImage = styled(ButtonImage)`
  background-image: url(${lineIcon});
`

const ButtonLabel = styled.p`
  font-size: 18px;
`
/* eslint-disable no-undef */
export class SocialButton extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    onClick: PropTypes.func
  };

  static defaultProps = {
    type: 'Facebook',
    width: 64,
    height: 64,
    onClick: () => {}
  };

  renderButton = ({ type, width, height }) => {
    switch (type) {
      case 'Facebook':
        return <FacebookImage width={width} height={height} />
      case 'Twitter':
        return <TwitterImage width={width} height={height} />
      case 'Pantip':
        return <PantipImage width={width} height={height} />
      case 'Line':
        return <LineImage width={width} height={height} />

      default:
        break
    }
  };

  render () {
    const { type, onClick } = this.props
    return (
      <Wrapper onClick={onClick}>
        {this.renderButton(this.props)}
        <ButtonLabel>{type}</ButtonLabel>
      </Wrapper>
    )
  }
}

export default SocialButton
