import $ from 'jquery'
import 'jquery-validation'

export default (form, options) => {
  /**
   * Custom validator for contains at least one lower-case letter
   */
  $.validator.addMethod('atLeastOneLowercaseLetter', function (value, element) {
    return this.optional(element) || /[a-z]+/.test(value)
  }, 'Must have at least one lowercase letter')

  /**
  * Custom validator for contains at least one upper-case letter.
  */
  $.validator.addMethod('atLeastOneUppercaseLetter', function (value, element) {
    return this.optional(element) || /[A-Z]+/.test(value)
  }, 'Must have at least one uppercase letter')

  /**
  * Custom validator for contains at least one number.
  */
  $.validator.addMethod('atLeastOneNumber', function (value, element) {
    return this.optional(element) || /[0-9]+/.test(value)
  }, 'Must have at least one number')

  /**
  * Custom validator for contains at least one symbol.
  */
  $.validator.addMethod('atLeastOneSymbol', function (value, element) {
    return this.optional(element) || /[!@#$%^&*()]+/.test(value)
  }, 'Must have at least one symbol')

  const valid = $(form).validate(options)
  return valid
}
