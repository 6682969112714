import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import { install } from 'redux-loop'
import Raven from 'raven-js'

import createRavenMiddleware from 'raven-for-redux'
import rootReducer from '../reducers'

const middlewares = [ReduxThunk, createRavenMiddleware(Raven)]
const enhancer = [applyMiddleware(...middlewares), install()]

export default function configureStore (initialState = {}) {
  return createStore(rootReducer, initialState, compose(...enhancer))
}
