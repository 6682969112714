import {
  API_CRM_AUTO_CANNED_RESPONSE
} from 'constants/ApiUrls'
import { METHOD_POST, METHOD_PUT, METHOD_DELETE } from 'constants/Methods'
import { createApi } from 'utils/index'

export const getConditions = () => createApi(`${API_CRM_AUTO_CANNED_RESPONSE}/conditions`)
export const createCondition = (data) => createApi(`${API_CRM_AUTO_CANNED_RESPONSE}/conditions`, METHOD_POST, data)
export const updateCondition = (data) => createApi(`${API_CRM_AUTO_CANNED_RESPONSE}/conditions/${data._id}`, METHOD_PUT, data)
export const deleteCondition = async (data) => {
  await createApi(`${API_CRM_AUTO_CANNED_RESPONSE}/conditions/${data._id}`, METHOD_DELETE)
  return data._id
}

export const getKeyword = (id = 'none') => createApi(`${API_CRM_AUTO_CANNED_RESPONSE}/keywords/${id}`)
export const listKeyword = () => createApi(`${API_CRM_AUTO_CANNED_RESPONSE}/keywords`)
export const createKeyword = (data) => createApi(`${API_CRM_AUTO_CANNED_RESPONSE}/keywords`, METHOD_POST, data)
export const updateKeyword = (data) => createApi(`${API_CRM_AUTO_CANNED_RESPONSE}/keywords/${data._id}`, METHOD_PUT, data)
export const deleteKeyword = (id = 'none') => createApi(`${API_CRM_AUTO_CANNED_RESPONSE}/keywords/${id}`, METHOD_DELETE)
