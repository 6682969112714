import { createAction } from 'redux-actions'
import {
  RELOAD_PANTIP_CAPTCHA,
  LOGIN_WITH_PANTIP,
  CONNECT_PANTIP,
  GET_PANTIP_ACCOUNTS,
  REMOVE_PANTIP_ACCOUNT,
  LOGIN_WITH_FACEBOOK,
  GET_FACEBOOK_PAGES,
  CONNECT_FACEBOOK_PAGE,
  REMOVE_FACEBOOK_PAGE,
  LOGIN_WITH_TWITTER,
  CONNECT_TWITTER_ACCOUNT,
  GET_TWITTER_ACCOUNTS,
  REMOVE_TWITTER_ACCOUNT,
  VALIDATE_LINE_TOKEN,
  CONNECT_LINE,
  GET_LINE_CHAT_BOTS,
  REMOVE_LINE_CHAT_BOT
} from './constants'

export const reloadPantipCatcha = createAction(
  RELOAD_PANTIP_CAPTCHA,
  payload => payload
)
export const loginWithPantip = createAction(
  LOGIN_WITH_PANTIP,
  payload => payload
)
export const loginWithPantipDone = createAction(
  `${LOGIN_WITH_PANTIP}_SUCCESS`,
  payload => payload
)
export const loginWithPantipFailed = createAction(
  `${LOGIN_WITH_PANTIP}_ERROR`,
  payload => payload
)
export const connectPantipDone = createAction(
  `${CONNECT_PANTIP}_SUCCESS`,
  payload => payload
)
export const connectPantipFailed = createAction(
  `${CONNECT_PANTIP}_ERROR`,
  payload => payload
)
export const getPantipAccountsDone = createAction(
  `${GET_PANTIP_ACCOUNTS}_SUCCESS`,
  payload => payload
)
export const getPantipAccountsFailed = createAction(
  `${GET_PANTIP_ACCOUNTS}_ERROR`,
  payload => payload
)
export const removePantipAccount = createAction(
  REMOVE_PANTIP_ACCOUNT,
  payload => payload
)
export const removePantipAccountDone = createAction(
  `${REMOVE_PANTIP_ACCOUNT}_SUCCESS`,
  payload => payload
)

export const loginWithFacebook = createAction(LOGIN_WITH_FACEBOOK)
export const loginWithFacebookDone = createAction(
  `${LOGIN_WITH_FACEBOOK}_SUCCESS`,
  payload => payload
)
export const loginWithFacebookError = createAction(
  `${LOGIN_WITH_FACEBOOK}_ERROR`,
  payload => payload
)
export const getFacebookPages = createAction(
  GET_FACEBOOK_PAGES,
  payload => payload
)
export const getFacebookPagesDone = createAction(
  `${GET_FACEBOOK_PAGES}_SUCCESS`,
  payload => payload
)
export const getFacebookPagesError = createAction(
  `${GET_FACEBOOK_PAGES}_ERROR`,
  payload => payload
)
export const connectFacebookPage = createAction(
  CONNECT_FACEBOOK_PAGE,
  payload => payload
)
export const connectFacebookPageDone = createAction(
  `${CONNECT_FACEBOOK_PAGE}_SUCCESS`,
  payload => payload
)
export const connectFacebookPageError = createAction(
  `${CONNECT_FACEBOOK_PAGE}_ERROR`,
  payload => payload
)
export const removeFacebookPage = createAction(
  REMOVE_FACEBOOK_PAGE,
  payload => payload
)
export const removeFacebookPageDone = createAction(
  `${REMOVE_FACEBOOK_PAGE}_SUCCESS`,
  payload => payload
)

export const loginWithTwitter = createAction(LOGIN_WITH_TWITTER)
export const loginWithTwitterDone = createAction(
  `${LOGIN_WITH_TWITTER}_SUCCESS`,
  payload => payload
)
export const loginWithTwitterError = createAction(
  `${LOGIN_WITH_TWITTER}_ERROR`,
  payload => payload
)
export const connectTwitterAccount = createAction(
  CONNECT_TWITTER_ACCOUNT,
  payload => payload
)
export const connectTwitterAccountDone = createAction(
  `${CONNECT_TWITTER_ACCOUNT}_SUCCESS`,
  payload => payload
)
export const connectTwitterAccountError = createAction(
  `${CONNECT_TWITTER_ACCOUNT}_ERROR`,
  payload => payload
)
export const getTwitterAccounts = createAction(GET_TWITTER_ACCOUNTS)
export const getTwitterAccountsDone = createAction(
  `${GET_TWITTER_ACCOUNTS}_SUCCESS`,
  payload => payload
)
export const getTwitterAccountsError = createAction(
  `${GET_TWITTER_ACCOUNTS}_ERROR`,
  payload => payload
)
export const removeTwitterAccount = createAction(
  REMOVE_TWITTER_ACCOUNT,
  payload => payload
)
export const removeTwitterAccountDone = createAction(
  `${REMOVE_TWITTER_ACCOUNT}_SUCCESS`,
  payload => payload
)

export const validateLineToken = createAction(
  VALIDATE_LINE_TOKEN,
  payload => payload
)
export const validateLineTokenDone = createAction(
  `${VALIDATE_LINE_TOKEN}_SUCCESS`,
  payload => payload
)
export const validateLineTokenError = createAction(
  `${VALIDATE_LINE_TOKEN}_ERROR`,
  payload => payload
)
export const connectLineChatbot = createAction(
  CONNECT_LINE,
  payload => payload
)
export const connectLineChatbotDone = createAction(
  `${CONNECT_LINE}_SUCCESS`,
  payload => payload
)
export const connectLineChatbotError = createAction(
  `${CONNECT_LINE}_ERROR`,
  payload => payload
)
export const getLineChatbots = createAction(GET_LINE_CHAT_BOTS)
export const getLineChatbotsDone = createAction(
  `${GET_LINE_CHAT_BOTS}_SUCCESS`,
  payload => payload
)
export const getLineChatbotsError = createAction(
  `${GET_LINE_CHAT_BOTS}_ERROR`,
  payload => payload
)
export const removeLineChatbot = createAction(
  REMOVE_LINE_CHAT_BOT,
  payload => payload
)
export const removeLineChatbotDone = createAction(
  `${REMOVE_LINE_CHAT_BOT}_SUCCESS`,
  payload => payload
)
