import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import isObject from 'lodash/isObject'
import update from 'immutability-helper'
import { createFullCycleHandlers } from 'utils/reducers'

import actions from 'actions/crm/groupManage'
import crmState from './initial.state'

const ui = handleActions({
  [actions.ui.groupSelected.set]: (state, actions) => {
    return update(state, {
      groupSelected: { $set: actions.payload }
    })
  }
}, crmState.groupManage.ui)

export default combineReducers({
  settings: createFullCycleHandlers(actions.settings, crmState.groupManage.settings, {
    [actions.settings.default.set]: (state, action) => {
      const settings = action.payload
      if (settings.ticket_tags) {
        settings.ticket_tags = settings.ticket_tags.map(item => isObject(item) ? item._id : item)
      }
      if (settings.keywords) {
        settings.keywords = settings.keywords.map(item => isObject(item) ? item._id : item)
      }
      return update(state, {
        data: { $set: settings },
        default: { $set: settings }
      })
    }
  }),
  ui
})
