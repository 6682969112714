import React from 'react'
import PropTypes from 'prop-types'
import {
  compose,
  defaultProps,
  withHandlers,
  withState
} from 'recompose'
import update from 'immutability-helper'
import { FormGroup, FormControl } from 'react-bootstrap'
import _ from 'lodash'
import {
  getExcludeValue,
  setExcludeValue,
  // getPatternLabel,
  setPatternLabel
} from '../patternUtils'
import InlineControlList from './InlineControlList'

import toastr from '@zanroo/react-redux-toastr-arun'

const Includes = InlineControlList
const Excludes = InlineControlList

export const Patterns = {
  setPropTypes: {
    pattern: PropTypes.object,
    onSubmit: PropTypes.func
  },
  defaultProps: {
    pattern: {
      includes: [],
      excludes: []
    },
    onSubmit() { }
  },
  withHandlers: ({ onSubmit }) => ({
    /**
     * @desc update or add more props.pattern.includes
     * @desc callback props.onSubmit with new props.pattern to above called for handles new data
     * @param {Array} value
     * @param {Number} i
     */
    onSubmitInclude: ({ pattern, selected, refs }) => (value, i, ref = null) => {
      let updated = pattern

      const process = (_value) => {
        _value = _.trim(_value)

        if (_value.replace(/\s/g, '') === '') {
          toastr.error('ERROR', 'Cannot add empty keyword.')
          return false
        }

        /** @desc Add Include duplicate in same pattern */
        const findID = _.findIndex(pattern.includes, include => {
          return _.toLower(include) === _.toLower(_value)
        })
        if (findID !== -1 && findID !== i) {
          toastr.error('ERROR', 'Cannot add exists include keyword.')
          return false
        }

        if (i === undefined) {
          updated = update(updated, {
            includes: {
              $push: [_value]
            }
          })
        } else {
          if (_value !== '') {
            updated = update(updated, {
              includes: {
                [i]: { $set: _value }
              }
            })
          }
        }
      }

      if (pattern === null) pattern = { includes: [], excludes: [] }

      const _arrVal = _.split(value, '*')
      _arrVal.map((item) => process(item))

      const result = onSubmit(updated, 'include-form')
      refs.includeTextBox.value = ''
      return result
    },
    /**
     * @desc update or add more props.pattern.excludes
     * @desc callback props.onSubmit with new props.pattern to above called for handles new data
     * @param {Array} value
     * @param {Number} i
     */
    onSubmitExclude: ({ pattern, refs }) => (value, i) => {
      if (pattern === null) {
        pattern = { includes: [], excludes: [] }
      }

      value = _.trim(value)
      /* if (_.replace(value, ' ', '') === '') {
        return null
      } */

      if (value.replace(/\s/g, '') === '') {
        toastr.error('ERROR', 'Cannot add empty keyword.')
        return false
      }

      // @FIXME : this function could move to util
      let excludePatternToString = []
      _.forEach(pattern.excludes, datum => {
        excludePatternToString.push(_.toString(datum))
      })
      // const findID = _.indexOf(excludePatternToString, value)
      const findID = _.findIndex(excludePatternToString, string => {
        return _.toLower(string) === _.toLower(value)
      })
      if (findID !== -1 && findID !== i) {
        toastr.error('ERROR', 'Cannot add exists exclude keyword.')
        return false
      }

      let updated = pattern
      if (i === undefined) {
        updated = update(pattern, {
          excludes: {
            $push: [setExcludeValue(value)]
          }
        })
      } else {
        updated = update(pattern, {
          excludes: {
            [i]: { $set: setExcludeValue(value) }
          }
        })
      }
      const result = onSubmit(updated, 'exclude-form')
      refs.excludeTextBox.value = ''
      return result
    },
    /**
     * @desc remove props.pattern.includes
     * @desc callback props.onSubmit with new props.pattern to above called for handles new data
     * @param {Number} i
     */
    onDeleteInclude: ({ pattern }) => i => {
      const updated = update(pattern, {
        includes: {
          $splice: [[i, 1]]
        }
      })
      onSubmit(updated)
    },
    /**
     * @desc remove props.pattern.excludes
     * @desc callback props.onSubmit with new props.pattern to above called for handles new data
     * @param {Number} i
     */
    onDeleteExclude: ({ pattern }) => i => {
      const updated = update(pattern, {
        excludes: {
          $splice: [[i, 1]]
        }
      })
      onSubmit(updated)
    },
    /**
     * @desc callback submit new pattern with setPatternLabel({String})
     * @param {String} pattern
     */
    onSubmitRaw: () => pattern => {
      onSubmit(setPatternLabel(pattern))
    },
    /**
     * @desc handle press 'Enter' key on text-input then blur that component and call onSubmitFunction
     * @param {Function} onSubmitFunction
     */
    onEnterInput: props => onSubmitFunction => e => {
      if (e.key === 'Enter') {
        e.target.blur()
        if (e.target.value !== '') {
          onSubmitFunction(e.target.value)
        }
      }
      return null
    },
    toggleToolTip: props => status => e => {
      const { setShowTooltip } = props
      setShowTooltip(status)
    },
  }),
  /**
   * @desc initial props.raw for handlers advance raw mode or ui mode
   * @typedef {Boolean} props.raw
   */
  withState: ['raw', 'setRaw', false],
  /**
   * @example
   * <Layout {...handlers}>
   *  <Row>
   *    <Button {...handlers}>Normal Mode</Button> <Button {...handlers}>Advance Mode</Button>
   *    raw && <QuickMode:InputControl />
   *    !raw && (
   *      <Includes:InlineControlList {...handlers}>
   *        <InlineControl key={0} {...handlers} />
   *        <InlineControl key={1} {...handlers} />
   *        <InlineControl key={2} {...handlers} />
   *      </Includes>
   *      <Excludes:InlineControlList {...handlers}>
   *        <InlineControl key={0} {...handlers} />
   *        <InlineControl key={1} {...handlers} />
   *        <InlineControl key={2} {...handlers} />
   *      </Excludes>
   *    )
   *  </Row>
   * </Layout>
   */
  render(props) {
    /**
     * @typedef {{ includes: Array, excludes: [ Array ] }} pattern
     * @typedef {Function} onSubmitInclude - from above withHandlers
     * @typedef {Function} onSubmitExclude - from above withHandlers
     * @typedef {Function} onDeleteInclude - from above withHandlers
     * @typedef {Function} onDeleteExclude - from above withHandlers
     * @typedef {Function} onSubmitRaw - from above withHandlers
     * @typedef {Boolean} raw - from above withState
     * @typedef {Function} setRaw - from above withState
     */
    const {
      pattern,
      onSubmitInclude,
      onSubmitExclude,
      onDeleteInclude,
      onDeleteExclude,
      selected,
      onEnterInput,
      // showTooltip,
      refs,
      // toggleToolTip,
      patternAt,
      setIncludeText,
      includeText,
      setExcludeText,
      excludeText,
      onSelectedKeyword,
      hideEditPatternPanel
      // Excluded,
      // onDeleteExcluded,
      // onSubmitExcluded,
      // showModal,
      // setShowModal,
    } = props

    let newPattern = {}
    let isAddNewKeyword = false

    if (pattern === null) {
      // return null
      newPattern = { excludes: [], includes: [] }
    } else {
      newPattern = pattern
    }

    if (!!selected && !selected.hasOwnProperty('_id')) {
      isAddNewKeyword = true
    }

    const { includes, excludes } = newPattern

    // const target = ReactDOM.findDOMNode(refs.excludeQM);
    // const allExcludeTooltip = (
    //   <Overlay placement="top" show={showTooltip} target={target}>
    //     <Tooltip
    //       id={`overLayTop`}
    //       style={{
    //         fontSize: '11px'
    //       }}
    //     >
    //       <div>
    //         <Row>
    //           <Col
    //             xs={6}
    //             style={{
    //               textDecoration: 'underline',
    //               textAlign: 'left',
    //               fontWeight: 'bold'
    //             }}
    //           >
    //             <p>Description</p>
    //           </Col>
    //           <Col
    //             xs={6}
    //             style={{
    //               textAlign: 'right'
    //             }}
    //           >
    //             <p onClick={toggleToolTip(false)}>X</p>
    //           </Col>
    //         </Row>
    //         <strong>Meaning:</strong> You can enter excluded keywords that you
    //         need to add all the included keyword set in only one time.
    //       </div>
    //     </Tooltip>
    //   </Overlay>
    // );

    const blockedStyle = condition => {
      if (!condition) {
        return {
          flex: '1'
        }
      }
      return {
        pointerEvents: 'none',
        opacity: '0.25',
        flex: '1'
      }
    }

    return (
      <div style={blockedStyle(isAddNewKeyword || hideEditPatternPanel)}>
        <div className='add-key-right'>
          <div className='add-key-head'>
            <div className='header-text'>
              <p>Add New Keywords</p>
            </div>
          </div>
          <div style={{ overflow: 'scroll', maxHeight: '400px' }}>
            <div
              className='result-include'
              style={blockedStyle(patternAt === null)}
            >
              <div
                className='keyword-label-input'
                style={{ marginBottom: '0px' }}
              >
                <p style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '20px'
                }}>Included Keyword</p>
                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder="Add Included Keyword"
                    inputRef={r => {
                      refs.includeTextBox = r
                    }}
                    onKeyPress={onEnterInput(onSubmitInclude)}
                    onChange={e => setIncludeText(e.target.value)}
                  />
                  <span onClick={() => onSubmitInclude(includeText)}>Enter</span>
                </FormGroup>
              </div>

              {includes.length > 0 && (
                <div className='keyword-label-input result-list'>
                  <p>Result Included</p>
                </div>
              )}
            </div>
            <div className='edit-key'>
              <Includes
                list={includes}
                onDelete={onDeleteInclude}
                onSubmit={onSubmitInclude}
                onSelectedKeyword={onSelectedKeyword}
              />
            </div>

            <div
              className='result-exclude'
              style={blockedStyle(patternAt === null)}
            >
              <div
                className='keyword-label-input'
                style={{ marginBottom: '0px' }}
              >
                <p style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '20px',
                  marginTop: '30px'
                }}>Excluded Keyword</p>
                <FormGroup>
                  <FormControl
                    type="text"
                    placeholder="Add Included Keyword"
                    disabled={_.isEmpty(_.get(pattern, 'includes', []))}
                    inputRef={r => {
                      refs.excludeTextBox = r
                    }}
                    onSubmit={onSubmitExclude}
                    onKeyPress={onEnterInput(onSubmitExclude)}
                    onChange={e => setExcludeText(e.target.value)}
                  />
                  <span
                    onClick={_.isEmpty(_.get(pattern, 'includes', []))
                      ? () => { }
                      : () => onSubmitExclude(excludeText)}
                  >
                    Enter
                  </span>
                </FormGroup>

                {_.isEmpty(_.get(pattern, 'includes', [])) &&
                  <p
                    style={{
                      color: '#939495',
                      fontSize: '10px'
                    }}
                  >
                    Every keyword set must contain atleast 1 included keyword,
                    please enter include keyword first.
                  </p>
                }
              </div>

              {excludes.length > 0 ? (
                <div className='keyword-label-input result-list'>
                  <p>Result Excluded</p>
                </div>
              ) : null}
            </div>
            <div className='edit-key'>
              <Excludes
                list={excludes}
                onDelete={onDeleteExclude}
                onSubmit={onSubmitExclude}
                getValue={getExcludeValue}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  defaultProps(Patterns.defaultProps),
  // withState('showTooltip', 'setShowTooltip', false),
  withState('showModal', 'setShowModal', false),
  withState('includeText', 'setIncludeText', ''),
  withState('excludeText', 'setExcludeText', ''),
  withHandlers(Patterns.withHandlers),
  withState(...Patterns.withState)
)(Patterns.render)
