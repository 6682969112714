// import React from 'react'
import { compose, lifecycle } from 'recompose'
// import { get } from 'lodash'

const Compose = {
  lifecycle: {
    async componentDidMount () {
      const { authenticated } = this.props
      // const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

      if (authenticated) {

        /*
            @desc Disable intercom temporary
        const userEmail = get(this.props, 'user.email', '')
        const userName = get(this.props, 'user.name', '')
        const userID = get(this.props, 'user._id', '')
        const accountID = get(this.props, 'account._id', '')
        const accountName = get(this.props, 'account.name', '')

        window.intercomSettings = {
          alignment: 'right',
          horizontal_padding: 20,
          vertical_padding: 100
        }

        window.Intercom('boot', {
          app_id: APP_ID,
          email: userEmail,
          name: userName,
          user_id: userID,
          account_id: accountID,
          account_name: accountName
        }) */
      } else {
        window.Intercom('shutdown')
      }
    }
  }
}

export default Component => compose(
  lifecycle(Compose.lifecycle)
)(Component)
