import { API_CENTRAL_USERS } from 'constants/ApiUrls'
import {
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  METHOD_DELETE
} from 'constants/Methods'
import { createApi, parseApi } from 'utils/index'

export const apiListUser = () =>
  //  createApi(parseApi(API_CENTRAL_USERS, { account_id }), METHOD_GET)
  createApi(`${API_CENTRAL_USERS}`, METHOD_GET)

export const apiCreateUser = (accountID, data) =>
  createApi(parseApi(API_CENTRAL_USERS, { accountID }), METHOD_POST, data)

export const apiUpdateUser = (accountID, id, data) =>
  createApi(
    parseApi(`${API_CENTRAL_USERS}/${id}`, { accountID }),
    METHOD_PUT,
    data
  )

export const apiDeleteUser = (accountID, id) =>
  createApi(
    parseApi(`${API_CENTRAL_USERS}/${id}`, { accountID }),
    METHOD_DELETE
  )
