import {
  SET_PAYMENT_PLAN,
  SET_PAYMENT_PLAN_SELECTED,
  SET_PAYMENT_CARD_INFO,
  SET_PAYMENT_DISABLED,
  SET_PAYMENT_ENABLED,
  SET_PAYMENT_STATUS_MESSAGE,
  SET_PAYMENT_CUSTOMER,
  SET_PAYMENT_INVOICE
} from 'constants/ActionTypes'
import update from 'immutability-helper'

const initialState = {
  plans: [],
  planSelected: {},
  cardInfo: {},
  statusMessage: '',
  disabled: false,
  customer: {},
  invoices: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT_PLAN:
      return update(state, {
        plans: { $set: action.payload }
      })
    case SET_PAYMENT_PLAN_SELECTED:
      return update(state, {
        planSelected: { $set: action.payload }
      })
    case SET_PAYMENT_INVOICE:
      return update(state, {
        invoices: { $set: action.payload }
      })
    case SET_PAYMENT_CARD_INFO:
      return update(state, {
        cardInfo: { $set: action.payload }
      })
    case SET_PAYMENT_DISABLED:
      return update(state, {
        disabled: { $set: true }
      })
    case SET_PAYMENT_ENABLED:
      return update(state, {
        disabled: { $set: false }
      })
    case SET_PAYMENT_STATUS_MESSAGE:
      return update(state, {
        statusMessage: { $set: action.payload }
      })
    case SET_PAYMENT_CUSTOMER:
      return update(state, {
        customer: { $set: action.payload }
      })
    default:
      return state
  }
}
