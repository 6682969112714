// Counter
export const INCREMENT_COUNTER = 'INCREMENT_COUNTER'
export const DECREMENT_COUNTER = 'DECREMENT_COUNTER'

// User
export const SET_USER = 'SET_USER'
export const UPDATE_USER = 'UPDATE_USER'

// Roles
export const SET_ROLES = 'SET_ROLES'

// Users
export const SET_USERS = 'SET_USERS'
export const CREATE_USERS = 'CREATE_USERS'
export const UPDATE_USERS = 'UPDATE_USERS'
export const DELETE_USERS = 'DELETE_USERS'

// Groups
export const SET_GROUPS = 'SET_GROUPS'
export const CREATE_GROUPS = 'CREATE_GROUPS'
export const UPDATE_GROUPS = 'UPDATE_GROUPS'
export const DELETE_GROUPS = 'DELETE_GROUPS'

// Account
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'

// Payment
export const SET_PAYMENT_PLAN = 'SET_PAYMENT_PLAN'
export const SET_PAYMENT_PLAN_SELECTED = 'SET_PAYMENT_PLAN_SELECTED'
export const SET_PAYMENT_INVOICE = 'SET_PAYMENT_INVOICE'
export const SET_PAYMENT_CARD_INFO = 'SET_PAYMENT_CARD_INFO'
export const SET_PAYMENT_STATUS_MESSAGE = 'SET_PAYMENT_STATUS_MESSAGE'
export const SET_PAYMENT_DISABLED = 'SET_PAYMENT_DISABLED'
export const SET_PAYMENT_ENABLED = 'SET_PAYMENT_ENABLED'
export const SET_PAYMENT_CUSTOMER = 'SET_PAYMENT_CUSTOMER'

// Ticket tags
export const SET_TICKET_TAGS = 'SET_TICKET_TAGS'
export const UPSERT_TICKET_TAG = 'UPSERT_TICKET_TAG'
export const UPDATE_TICKET_TAG_SELECTED = 'UPDATE_TICKET_TAG_SELECTED'
export const DELETE_TICKET_TAG = 'DELETE_TICKET_TAG'
export const UPDATE_GROUP_SELECTED = 'UPDATE_GROUP_SELECTED'

export const SET_TEMPLATE = 'SET_TEMPLATE'
export const SET_TEMPLATE_RESOURCE = 'SET_TEMPLATE_RESOURCE'
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE'
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE'
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'

// Group Option
export const SET_GROUP_OPTION = 'SET_GROUP_OPTION'
export const UPDATE_GROUP_OPTION = 'UPDATE_GROUP_OPTION'

// Custom Theme
export const SET_CUSTOM_THEME = 'SET_CUSTOM_THEME'
export const UPDATE_CUSTOM_THEME = 'UPDATE_CUSTOM_THEME'
