import {
  createFullCycleActions
} from 'utils/actions'
import * as apis from 'apis/crm/autoSentiment'

const Actions = {
  settings: createFullCycleActions({
    prefix: 'actions.crm.autoSentiment.settings',
    apis: {
      get: apis.getSettings,
      create: apis.createSettings,
      update: apis.updateSettings
    }
  })
}

export default Actions
