// @flow
import is from 'ramda/src/is'
import isEmpty from 'ramda/src/isEmpty'
import moment from 'moment'
import _ from 'lodash'

const isNumber = value => !isEmpty(value) && is(Number, parseInt(value, 0))

type SlaLevelProps = {
  level: number,
  from: number,
  to: number
}

export type ValidateLevelResult = {
  error: boolean,
  message?: string
}

const isAsc = (start: number, end: number, last: boolean = false): boolean => {
  if (last && +end === 0) {
    return true
  }
  return end > start
}

const LIMIT_TIME = 687599  //687599 sec => 7day 23hour 59min 59sec

const isInLimitTime = (start, end) => start <= LIMIT_TIME && end <= LIMIT_TIME

const validateLevels = (
  label: string,
  levels: Array<SlaLevelProps>
): ValidateLevelResult => {
  for (let counter = 0; counter < levels.length - 1; counter++) {
    const current = levels[counter]
    const next = levels[counter + 1]
    const isLast = counter >= levels.length - 2 // mean before last, not the last one

    if (
      !isNumber(current.to) ||
      !isNumber(current.from) ||
      !isAsc(current.from, current.to) ||
      !isInLimitTime(current.from, current.to)
    ) {
      return {
        error: true,
        message: `${label}: Level ${current.level} is invalid.`,
        data: {
          name: label,
          toIndex: counter,
          fromIndex: counter
        }
      }
    }
    if (
      !isNumber(next.to) ||
      !isNumber(next.from) ||
      !isAsc(next.from, next.to, isLast) ||
      !isInLimitTime(next.from, next.to)
    ) {
      return {
        error: true,
        message: `${label}: Level ${next.level} is invalid.`,
        data: {
          name: label,
          toIndex: counter + 1,
          fromIndex: counter + 1
        }
      }
    }
    if (!isAsc(current.to, next.from)) {
      return {
        error: true,
        message: `${label}: Level ${current.level} and Level ${next.level
          } are invalid.`,
        data: {
          name: label,
          toIndex: counter,
          fromIndex: counter + 1
        }
      }
    }
    if (current.to + 1 !== next.from) {
      return {
        error: true,
        message: `${label}: Level ${current.level} and Level ${next.level
          } are invalid.`,
        data: {
          name: label,
          toIndex: counter,
          fromIndex: counter + 1
        }
      }
    }
  }
  return { error: false }
}

export const isFormError = (name, index, type, slaError) => {
  if (_.isEmpty(slaError) || name !== slaError.name) {
    return false
  }

  if (type === 'from' && slaError.fromIndex === index) {
    return true
  }

  if (type === 'to' && slaError.toIndex === index) {
    return true
  }

  return false
}

export const defaultLevels = [
  { level: 1, from: 0, to: 359 },
  { level: 2, from: 360, to: 659 },
  { level: 3, from: 660, to: 1259 },
  { level: 4, from: 1260, to: 0 }
]

export const defaultSettings = {
  business_hour: {
    is_custom: false,
    days: [
      { label: 'Sunday', is_holiday: false, start: '08:00', end: '17:00' },
      { label: 'Monday', is_holiday: false, start: '08:00', end: '17:00' },
      { label: 'Tuesday', is_holiday: false, start: '08:00', end: '17:00' },
      { label: 'Wednesday', is_holiday: false, start: '08:00', end: '17:00' },
      { label: 'Thursday', is_holiday: false, start: '08:00', end: '17:00' },
      { label: 'Friday', is_holiday: false, start: '08:00', end: '17:00' },
      { label: 'Saturday', is_holiday: false, start: '08:00', end: '17:00' }
    ],
    start: '08:00',
    end: '17:00'
  },
  holidays: [],
  first_response: defaultLevels,
  handling_time: defaultLevels,
  avg_response_time: defaultLevels,
  resolution_time: defaultLevels,
  sla_time: defaultLevels,
  waiting_time: defaultLevels,
  turn_around_time: defaultLevels,
  updated_time: ''
}

const SECONDS_IN_DAY = 24 * 60 * 60
const SECONDS_IN_HOUR = 60 * 60
const SECONDS_IN_MINUTE = 60

export const convertSecondToOther = (second) => {
  if (second === null) {
    return
  }
  return {
    day: Math.floor(second / SECONDS_IN_DAY),
    hour: Math.floor((second % SECONDS_IN_DAY) / SECONDS_IN_HOUR),
    minute: Math.floor((second % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE),
    second: second % SECONDS_IN_MINUTE
  }
}

export const convertOtherToSecond = (day, hour, minute, second) => {
  if (day === null && hour === null && minute === null && second === null) {
    return
  }
  return Math.floor(
    (day * SECONDS_IN_DAY) +
    (hour * SECONDS_IN_HOUR) +
    (minute * SECONDS_IN_MINUTE) +
    second
  )
};

export const toolTipLabel = {
  day: 'You can fill 0-7 Days',
  hour: 'You can fill 0-23 Hours',
  minute: 'You can fill 0-59 Minutes',
  second: 'You can fill 0 - 59 Seconds'
}

export const DAYS = [
  { value: 'Sun', name: 'Sunday', index: 0 },
  { value: 'Mon', name: 'Monday', index: 1 },
  { value: 'Tue', name: 'Tuesday', index: 2 },
  { value: 'Wed', name: 'Wednesday', index: 3 },
  { value: 'Thu', name: 'Thursday', index: 4 },
  { value: 'Fri', name: 'Friday', index: 5 },
  { value: 'Sat', name: 'Saturday', index: 6 },
]

export const LIMIT_DAY = 365

export const filterDate = (days = [], date = new Date()) =>
  days.filter(day => moment(_.get(day, 'date')).isSameOrAfter(date, 'day'))

export const groupDates = (dates = []) => _.sortBy(dates, (date) => new Date(date)).reduce((grouped, date) => {
  const [month, year] = date.split(' ').slice(1)
  const key = `${month} ${year}`
  grouped[key] = [...(grouped[key] || []), date]
  return grouped
}, {})

export default validateLevels
