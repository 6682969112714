import { loop, Cmd } from 'redux-loop'
import update from 'immutability-helper'
import { get, intersection, omit, pick } from 'lodash'
import { normalize } from 'normalizr'
import toastr from '@zanroo/react-redux-toastr-arun'
import {
  apiGetCannedResponseFolders,
  apiPostNewCannedResponseFolder,
  apiPutChangeFolderName,
  apiDeleteFolder,
  apiPostNewCannedResponse,
  apiDeleteCannedResponse,
  apiPutChangeCannedResponse
} from './api'
import schema from './schema'

// Constants
const GET_FOLDERS = 'crm/canned-response/GET-FOLDERS'
const GET_FOLDERS_SUCCESS = 'crm/canned-response/GET-FOLDERS-SUCCESS'
const GET_FOLDERS_FAILED = 'crm/canned-response/GET-FOLDERS-FAILED'
const CREATE_NEW_FOLDER = 'crm/canned-response/CREATE-NEW-FOLDER'
const CREATE_NEW_FOLDER_SUCCESS =
  'crm/canned-response/CREATE-NEW-FOLDER-SUCCESS'
const SAVE_FOLDER_FAILED = 'crm/canned-response/CREATE-NEW-FOLDER-FAILED'
const CHANGE_FOLDER_NAME = 'crm/canned-response/CHANGE-FOLDER-NAME'
const CHANGE_FOLDER_NAME_SUCCESS =
  'crm/canned-response/CHANGE-FOLDER-NAME-SUCCESS'
const REMOVE_FOLDER = 'crm/canned-response/REMOVE-FOLDER'
const REMOVE_FOLDER_SUCCESS = 'crm/canned-response/REMOVE-FOLDER-SUCCESS'
const REMOVE_FOLDER_FAILED = 'crm/canned-response/REMOVE-FOLDER-FAILED'
const FILTER_BY_FOLDER = 'crm/canned-response/FILTER-BY-FOLDER'
const FILTER_BY_TEXT = 'crm/canned-response/FILTER-BY-TEXT'
const CREATE_NEW_CANNED_RESPONSE =
  'crm/canned-response/CREATE-NEW-CANNED-RESPONSE'
const CREATE_NEW_CANNED_RESPONSE_SUCCESS =
  'crm/canned-response/CREATE-NEW-CANNED-RESPONSE-SUCCESS'
const REMOVE_CANNED_RESPONSE = 'crm/canned-response/REMOVE-CANNED-RESPONSE'
const REMOVE_CANNED_RESPONSE_SUCCESS =
  'crm/canned-response/REMOVE-CANNED-RESPONSE-SUCCESS'
const REMOVE_CANNED_RESPONSE_FAILED =
  'crm/canned-response/REMOVE-CANNED-RESPONSE-FAILED'
const CHANGE_CANNED_RESPONSE = 'crm/canned-response/CHANGE-CANNED-RESPONSE'
const CHANGE_CANNED_RESPONSE_SUCCESS =
  'crm/canned-response/CHANGE-CANNED-RESPONSE-SUCCESS'

// Init State
export const initState = {
  folders: [],
  entities: {
    folder: {},
    response: {}
  },
  loading: false,
  error: null,
  filterBy: {
    folder: '',
    text: ''
  }
}

// Reducer
export default function reducer (
  state = initState,
  action = { type: '', payload: {} }
) {
  switch (action.type) {
    case GET_FOLDERS:
      return loop(
        { ...state, loading: true },
        Cmd.run(apiGetCannedResponseFolders, {
          successActionCreator: folderFetchSuccessful,
          failActionCreator: folderFetchFailed,
          args: [action.payload]
        })
      )
    case REMOVE_FOLDER:
      return loop(
        update(state, {
          loading: { $set: true },
          filterBy: {
            folder: { $set: '' }
          },
          folders: {
            $splice: [
              [state.folders.findIndex(item => item === action.payload), 1]
            ]
          },
          entities: {
            folder: {
              $unset: [action.payload]
            },
            response: {
              $apply: (responses) => {
                const folderResponses = (state.entities.folder[action.payload] &&
                  state.entities.folder[action.payload].responses) || []
                return omit(responses, intersection(folderResponses, Object.keys(responses)))
              }
            }
          }
        }),
        Cmd.run(apiDeleteFolder, {
          successActionCreator: removeFolderSuccessful,
          failActionCreator: removedFolderFailed,
          args: [action.payload]
        })
      )
    case REMOVE_CANNED_RESPONSE: {
      const selectedResponse = get(
        state,
        `entities.response.${get(action, 'payload')}`
      )
      return loop(
        selectedResponse
          ? update(state, {
            loading: { $set: true },
            entities: {
              folder: {
                [selectedResponse.folder_id]: {
                  responses: {
                    $splice: [
                      [
                        get(
                          state,
                          `entities.folder.${
                            selectedResponse.folder_id
                          }.responses`,
                          []
                        ).findIndex(item => item === action.payload),
                        1
                      ]
                    ]
                  }
                }
              },
              response: {
                [action.payload]: { $set: undefined }
              }
            }
          })
          : { ...state, loading: true },
        Cmd.run(apiDeleteCannedResponse, {
          successActionCreator: removeCannedResponseSuccessful,
          failActionCreator: removedCannedResponseFailed,
          args: [action.payload]
        })
      )
    }
    case CREATE_NEW_FOLDER:
      return loop(
        { ...state, loading: true },
        Cmd.run(apiPostNewCannedResponseFolder, {
          successActionCreator: createNewFolderSuccessful,
          failActionCreator: (payload) => { throw new Error(payload.message) } ,
          args: [action.payload]
        })
      )
    case CREATE_NEW_CANNED_RESPONSE:
      return loop(
        { ...state, loading: true },
        Cmd.run(apiPostNewCannedResponse, {
          successActionCreator: createNewCannedResponseSuccessful,
          failActionCreator: (payload) => { throw new Error(payload.message) },
          args: [action.payload]
        })
      )
    case CHANGE_FOLDER_NAME:
      return loop(
        { ...state, loading: true },
        Cmd.run(apiPutChangeFolderName, {
          successActionCreator: changeFolderNameSuccessful,
          failActionCreator: (payload) => { throw new Error(payload.message) },
          args: [action.payload]
        })
      )
    case CHANGE_CANNED_RESPONSE: {
      return loop(
        { ...state, loading: true },
        Cmd.run(apiPutChangeCannedResponse, {
          successActionCreator: changeCannedResponseSuccessful,
          failActionCreator: (payload) => { throw new Error(payload.message) },
          args: [
            action.payload.cannedResponseId,
            pick(action.payload, [
              'title',
              'folder_id',
              'type',
              'text',
              'media',
              'permission'
            ])
          ]
        })
      )
    }
    case GET_FOLDERS_SUCCESS: {
      const { entities, result } = normalize(
        { folders: [...action.payload] },
        schema
      )
      return update(state, {
        loading: { $set: false },
        folders: { $push: result.folders },
        entities: { $merge: entities }
      })
    }
    case CREATE_NEW_FOLDER_SUCCESS: {
      const { entities, result } = normalize(
        { folders: [action.payload] },
        schema
      )
      return update(state, {
        loading: { $set: false },
        folders: { $push: result.folders },
        entities: {
          folder: {
            $merge: { ...entities.folder }
          }
        }
      })
    }
    case CREATE_NEW_CANNED_RESPONSE_SUCCESS: {
      return update(state, {
        loading: { $set: false },
        entities: {
          response: {
            [action.payload._id]: { $set: action.payload }
          },
          folder: {
            [action.payload.folder_id]: {
              responses: {
                $push: [action.payload._id]
              }
            }
          }
        }
      })
    }
    case CHANGE_FOLDER_NAME_SUCCESS:
      return update(state, {
        loading: { $set: false },
        entities: {
          folder: {
            [get(action, 'payload.data._id', '')]: {
              name: {
                $set: get(action, 'payload.data.name')
              }
            }
          }
        }
      })
    case REMOVE_FOLDER_SUCCESS:
    case REMOVE_CANNED_RESPONSE_SUCCESS:
      return update(state, {
        loading: { $set: false }
      })
    case CHANGE_CANNED_RESPONSE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        entities: {
          folder: {
            // remove from old folder
            [action.payload.folder_id]: {
              responses: {
                $splice: [
                  [
                    get(
                      state,
                      `entities.folder.${
                        action.payload.folder_id
                      }.responses`,
                      []
                    ).findIndex(
                      item =>
                        item === get(action, 'payload._id')
                    ),
                    1
                  ]
                ]
              }
            },
            // add to new folder
            [get(action, 'payload.folder_id')]: {
              responses: {
                $push: [get(action, 'payload.cannedResponseId')]
              }
            }
          },
          response: {
            [get(action, 'payload._id')]: {
              $merge: {
                ...pick(action.payload, [
                  'title',
                  'folder_id',
                  'type',
                  'text',
                  'media',
                  'permission'
                ])
              }
            }
          }
        }
      })
    case SAVE_FOLDER_FAILED:
    case REMOVE_FOLDER_FAILED:
    case REMOVE_CANNED_RESPONSE_FAILED:
    case GET_FOLDERS_FAILED: {
      return { ...state, loading: false, error: { ...action.payload } }
    }
    case FILTER_BY_FOLDER: {
      return update(state, {
        filterBy: {
          folder: { $set: action.payload }
        }
      })
    }
    case FILTER_BY_TEXT: {
      return update(state, {
        filterBy: {
          text: { $set: action.payload }
        }
      })
    }
    default:
      return state
  }
}

// Action
export function getFolders (payload) {
  return { type: GET_FOLDERS, payload }
}

/**
 * Create `Default` folder if empty data from API
 * @param {Object} payload
 */
export function folderFetchSuccessful (payload) {
  if (Object.keys(payload).length) {
    return { type: GET_FOLDERS_SUCCESS, payload }
  }
  return {
    type: CREATE_NEW_FOLDER,
    payload: { name: 'Default', user_id: '0' }
  }
}

export function folderFetchFailed (payload) {
  toastr.error('API ERROR', payload.message)
  return { type: GET_FOLDERS_FAILED, payload }
}

export function createNewFolder (payload) {
  return { type: CREATE_NEW_FOLDER, payload }
}

export function createNewFolderSuccessful (data) {
  toastr.success('Folder', `You've just create a new folder`)
  return {
    type: CREATE_NEW_FOLDER_SUCCESS,
    payload: { ...data, responses: [] }
  }
}

export function saveCannedResponseFail (payload) {
  toastr.error('ERROR', payload.message)
  return { type: SAVE_FOLDER_FAILED, payload }
}

export function createCannedResponse (payload) {
  return { type: CREATE_NEW_CANNED_RESPONSE, payload }
}

export function createNewCannedResponseSuccessful (payload) {
  toastr.success('Canned response', `You've just create a new canned response`)
  return { type: CREATE_NEW_CANNED_RESPONSE_SUCCESS, payload }
}

export function changeFolderName (payload) {
  return { type: CHANGE_FOLDER_NAME, payload }
}

export function changeFolderNameSuccessful (payload) {
  toastr.success('Edit folder', `You've just changed folder name`)
  return { type: CHANGE_FOLDER_NAME_SUCCESS, payload }
}

export function changeCannedResponse (payload) {
  return { type: CHANGE_CANNED_RESPONSE, payload }
}

export function changeCannedResponseSuccessful (payload) {
  toastr.success('Edit canned response', `You've just changed canned response`)
  return { type: CHANGE_CANNED_RESPONSE_SUCCESS, payload }
}

export function removeFolderById (payload) {
  return { type: REMOVE_FOLDER, payload }
}

export function removeFolderSuccessful (payload) {
  toastr.success('Folder', `You've just deleted a new folder`)
  return { type: REMOVE_FOLDER_SUCCESS, payload }
}

export function removedFolderFailed (payload) {
  toastr.error('API ERROR', payload.message)
  return { type: REMOVE_FOLDER_FAILED, payload }
}

export function removeCannedResponseById (payload) {
  return { type: REMOVE_CANNED_RESPONSE, payload }
}

export function removeCannedResponseSuccessful (payload) {
  toastr.success('Canned response', `You've just deleted a canned response`)
  return { type: REMOVE_CANNED_RESPONSE_SUCCESS, payload }
}

export function removedCannedResponseFailed (payload) {
  toastr.error('API ERROR', payload.message)
  return { type: REMOVE_CANNED_RESPONSE_FAILED, payload }
}

export function filterFolderById (payload) {
  return { type: FILTER_BY_FOLDER, payload }
}

export function filterCannedResponseByText (payload) {
  return { type: FILTER_BY_TEXT, payload }
}
