import {
  API_CENTRAL_GET_USER_INFO,
  API_CENTRAL_ACCOUNTS,
  API_CENTRAL_AUTH_LOGIN,
  API_CENTRAL_AUTH_LOGIN_REMOTE,
  API_CENTRAL_AUTH_LOGOUT,
  API_CENTRAL_ROLES,
  API_CENTRAL_GET_LOGIN_ATTEMPT
} from 'constants/ApiUrls'
import { METHOD_GET, METHOD_POST } from 'constants/Methods'
import Cookies from 'js-cookie'
import { createApi, parseApi } from 'utils/index'
// import { get } from 'lodash'

/**
 * signup api
 * @return {Promise} response.json
 */
export const apiSignup = data =>
  createApi(API_CENTRAL_ACCOUNTS, METHOD_POST, data)

/**
 * Login request to api
 * @return {Promise} responses.json
 */
export const apiLogin = async data => {
  let response
  try {
    response = await createApi(API_CENTRAL_AUTH_LOGIN, METHOD_POST, data)
    // save access_token to cookie
    /* Cookies.set(process.env.REACT_APP_ACCESS_TOKEN_NAME, response.accessToken, {
      domain: process.env.REACT_APP_SSO_COOKIE_DOMAIN,
    }) */
  } catch (error) {
    return Promise.reject(error)
  }

  try {
    /*
    @desc Disable intercom temporary
    const user = await get(response, 'user', { _id: '', name: 'Guest', email: '' })
    const account = await get(response, 'account', { _id: 'unknown', name: 'unknown' })

    window.intercomSettings = {
      alignment: 'right',
      horizontal_padding: 20,
      vertical_padding: 100
    }

    await window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      email: user.email,
      name: user.name,
      user_id: user._id,
      account_id: account._id,
      account_name: account.name
    }) */
  } catch (error) {
    // console.log(error)
  }

  return response
}

export const apiLogout = async () => {
  let response
  try {
    response = await createApi(API_CENTRAL_AUTH_LOGOUT, METHOD_GET)


    /** @desc LOGOUT WITH GET METHOD FOR PRODUCT WITH NEXT_AUT */
    try {
      await createApi(process.env.REACT_APP_LOGOUT_CUSTOM_VIZ_URL, METHOD_GET)
      await createApi(process.env.REACT_APP_LOGOUT_INFLUENCER_URL, METHOD_GET)
    } catch (err) {
      //
    }
    try {
    } catch (err) {
      //
    }

    // window.Intercom('boot', {
    //   app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    //   name: 'Guest',
    //   email: '',
    //   user_id: ''
    // })
  } catch (error) {
    return Promise.reject(error)
  }

  try {
    window.Intercom('shutdown')
  } catch (error) {
    // console.log(error)
  }
  return response
}

export const apiRemoteLogin = async data => {
  try {
    const response = await createApi(
      API_CENTRAL_AUTH_LOGIN_REMOTE,
      METHOD_POST,
      data
    )
    // console.log('RES :', response)

    // save access_token to cookie
    /* Cookies.set(process.env.REACT_APP_ACCESS_TOKEN_NAME, response.token, {
      domain: process.env.REACT_APP_SSO_COOKIE_DOMAIN,
    }) */
    console.log('Cookies : ', Cookies.getJSON())
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Check from Api and update cookies
 * @return {Promise} createApi
 */
export const apiGetUserInfo = () =>
  createApi(API_CENTRAL_GET_USER_INFO, METHOD_GET)

/**
 * Get all roles
 * @return {Promise} createApi
 */
export const apiListRole = accountID =>
  createApi(parseApi(API_CENTRAL_ROLES, { accountID }), METHOD_GET)

export const apiGetLoginAttempt = () =>
  createApi(API_CENTRAL_GET_LOGIN_ATTEMPT, METHOD_GET)
