import React from 'react'
import PropTypes from 'prop-types'
import {
  compose,
  onlyUpdateForKeys,
  defaultProps,
  setPropTypes
  // withState,
  // withHandlers
} from 'recompose'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
// import _ from 'lodash'
import toastr from '@zanroo/react-redux-toastr-arun'
import styled from 'styled-components'

export const KeywordsLabel = styled.div`
  .key-setting-list {
    max-height: calc(100vh - 492px);
    overflow-y: scroll;
  }

  .key-setting-list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  .key-setting-list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.1);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.1);
  }
`

/**
 * @desc this render label list and control edit delete | save reset button
 * @example
 * <Layout>
 *  <Row key={0}>
 *    <label />
 *    editing ? <Button>edit</Button> Button>delete</Button>
 *    !editing ? <Button>save</Button> Button>cancel</Button>
 *  </Row>
 *  <Row key={1}>
 *    <label />
 *    editing ? <Button>edit</Button> Button>delete</Button>
 *    !editing ? <Button>save</Button> Button>cancel</Button>
 *  </Row>
 *  <Row key={2}>
 *    <label />
 *    editing ? <Button>edit</Button> Button>delete</Button>
 *    !editing ? <Button>save</Button> Button>cancel</Button>
 *  </Row>
 * </Layout>
 */
export const LabelControlList = {
  setPropTypes: {
    list: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    getLabel: PropTypes.func
  },
  defaultProps: {
    list: [],
    onEdit() { },
    onDelete() { },
    getLabel: e => e
  },
  render(props) {
    const {
      list,
      onEdit,
      onDelete,
      getLabel,
      onClickAdvance,
      patternAt,
      type = 'keyword',
      excludesAllLabel,
      hideAdvanceMode = false
    } = props
    const tooltip = (
      <Tooltip id='tooltip'>
        <p style={{ textAlign: 'left' }}>
          Click here for advance mode each {type} set
        </p>
      </Tooltip>
    )

    const activeStyle = condition => {
      if (!condition) return null
      return {
        backgroundColor: 'rgba(0, 167, 201, 0.2)',
        color: '#414042'
      }
    }

    return (
      <KeywordsLabel>
        <div className='key-setting-list'>
          {list.map((data, i) => {
            if (data === null) return null

            const label = getLabel(data)
            // const label = `${getLabel(data)}|${excludesAll}`
            return (

              <div
                className='adv'
                key={`list.${i}`}
                style={{
                  ...activeStyle(patternAt === i),
                  position: 'relative'
                }}
              // className='adv edit-text add-key'
              // title='edit'
              // onClick={() => onEdit(data, i)}
              >
                <div
                  title={
                    excludesAllLabel ? `${label}|${excludesAllLabel}` : label
                  }
                  style={{
                    width: '45%',
                    // whiteSpace: 'nowrap',
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis'
                  }}
                >
                  <div style={{ display: 'inline-flex' }}>
                    <span style={{
                      color: '#939495',
                      borderLeftWidth: '0px',
                      paddingLeft: '0px',
                      paddingRight: '5px',
                      fontSize: '12px'
                    }}>{`${i + 1} `}</span>
                  </div>
                  <div
                    style={{
                      width: '80%',
                      wordBreak: 'break-all',
                      display: 'inline-flex'
                    }}
                  >
                    {label}
                  </div>

                  {type === 'keyword' &&
                    !!excludesAllLabel && (
                      <span
                        style={{
                          color: 'rgb(255,158,158)',
                          paddingLeft: '0px',
                          fontSize: '14px',
                          fontWeight: 'normal',
                          textTransform: 'none',
                          borderLeft: 'none'
                        }}
                      >
                        {`|${excludesAllLabel}`}
                      </span>
                    )}
                </div>
                <div className='adv-edit' style={{ position: 'absolute', top: '6px', right: '15px' }}>
                  <img
                    title='edit'
                    alt='edit'
                    src={require('assets/img/icon/pencil.svg')}
                    onClick={() => onEdit(data, i)}
                  />
                  <img
                    title='delete'
                    alt='delete'
                    src={require('assets/img/icon/rubbish-bin.svg')}
                    onClick={() =>
                      toastr.confirm(
                        'Warning!',
                        `Do you want to delete this ${type} set?`,
                        () => onDelete(i)
                      )
                    }
                  />
                  {!hideAdvanceMode &&
                    <OverlayTrigger placement='right' overlay={tooltip}>
                      <span
                        onClick={async () => {
                          await onEdit(data, i)
                          await onClickAdvance()
                        }}
                      >
                        Advance Modes
                      </span>
                    </OverlayTrigger>
                  }
                </div>
              </div>
            )
          })}
        </div>
      </KeywordsLabel>
    )
  }
}

export default compose(
  setPropTypes(LabelControlList.setPropTypes),
  defaultProps(LabelControlList.defaultProps),
  onlyUpdateForKeys(['list', 'patternAt'])
)(LabelControlList.render)
