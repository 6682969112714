import React from 'react'
import PropTypes from 'prop-types'
import { Overlay, Tooltip, Row, Col } from 'react-bootstrap'

const getPlacement = tooltipOf => {
  switch (tooltipOf) {
    case 'Exclude_tags_all':
      return 'top'
    case 'Advance_Mode':
    case 'All_keyword_set':
    case 'All_tags_set':
    default:
      return 'right'
  }
}

const Detail = ({ tooltipOf, type }) => {
  switch (tooltipOf) {
    case 'Advance_Mode':
      return (
        <div className='text-left'>
          <strong>Meaning:</strong>
          Advance mode(quick mode), you can edit include or exclude keywords at
          the same time using.
        </div>
      )
    case 'All_keyword_set':
    case 'All_tags_set':
      return (
        <div classNmae='text-left'>
          <strong>Meaning:</strong> Every {type} set will be shown together
          separated by “,”
          <Row>
            <Col xs={6}>How to edit? Sign for using:</Col>
            <Col xs={6}>
              <p>1.For include keywords you have to use “ * “</p>
              <p>2.For exclude keywords you have to use “ : “</p>
              <p>3.And included/excluded are separated by “ | “</p>
            </Col>
          </Row>
        </div>
      )
    case 'Exclude_tags_all':
      return (
        <div className='text-left'>
          <strong>Meaning:</strong>
          You can enter excluded tags that you need to add all the included tag
          set in only one time.
        </div>
      )
    default:
      return <div />
  }
}
Detail.propTypes = {
  tooltipOf: PropTypes.string,
  type: PropTypes.string
}

const AdvanceTooltip = ({
  tooltipIndex,
  tooltipOf,
  target,
  toggleToolTip,
  show,
  type
}) => {
  const placement = getPlacement(tooltipOf)
  if (target === null) return null
  return (
    <Overlay placement={placement} show={show} target={target}>
      <Tooltip id={`overLayRight-advance-${tooltipIndex}`}>
        <div>
          <Row>
            <Col
              xs={6}
              style={{
                textDecoration: 'underline',
                textAlign: 'left',
                fontWeight: 'bold'
              }}
            >
              <p>Description</p>
            </Col>
            <Col
              xs={6}
              style={{
                textAlign: 'right'
              }}
            >
              <p onClick={toggleToolTip(false, tooltipIndex)}>X</p>
            </Col>
          </Row>
          <Detail tooltipOf={tooltipOf} type={type} />
        </div>
      </Tooltip>
    </Overlay>
  )
}
AdvanceTooltip.propTypes = {
  tooltipIndex: PropTypes.number,
  tooltipOf: PropTypes.string,
  toggleToolTip: PropTypes.func,
  show: PropTypes.bool,
  type: PropTypes.string
}
export default AdvanceTooltip
