import {
  createFullCycleActions,
  createMiddlewares
} from 'utils/actions'
import { get, findIndex } from 'lodash'
import * as apis from 'apis/crm/autoCanned'
import update from 'immutability-helper'

const Actions = {
  conditions: createFullCycleActions({
    prefix: 'actions.crm.autoCanned.conditions',
    apis: {
      list: apis.getConditions,
      create: apis.createCondition,
      update: apis.updateCondition,
      del: apis.deleteCondition
    },
    customMiddlewares: (actions) => ({
      create: createMiddlewares({
        caller: apis.createCondition,
        actionApi: actions.api,
        dispatcher: (data) => (dispatch, getState) => {
          const state = getState()
          const defaultConditions = get(state, 'crm.autoCanned.conditions.default', {})

          const updated = update(defaultConditions, {
            items: {
              $push: [data]
            }
          })
          dispatch(Actions.conditions.default.set(updated))
        }
      }),
      update: createMiddlewares({
        caller: apis.updateCondition,
        actionApi: actions.api,
        dispatcher: (data) => (dispatch, getState) => {
          const state = getState()
          const defaultConditions = get(state, 'crm.autoCanned.conditions.default', {})

          const index = findIndex(defaultConditions.items, {_id: data._id})

          if (index < 0) {
            return
          }

          const updated = update(defaultConditions, {
            items: {
              [index]: {$set: data}
            }
          })

          dispatch(Actions.conditions.default.set(updated))
        }
      }),
      del: createMiddlewares({
        caller: apis.deleteCondition,
        actionApi: actions.api,
        dispatcher: (id) => (dispatch, getState) => {
          const state = getState()
          const defaultConditions = get(state, 'crm.autoCanned.conditions.default', {})
          const index = findIndex(defaultConditions.items, {_id: id})

          if (index < 0) {
            return
          }

          const updated = update(defaultConditions, {
            items: { $splice: [[index, 1]] }
          })

          dispatch(Actions.conditions.default.set(updated))
        }
      })
    })
  }),
  keywords: createFullCycleActions({
    prefix: 'actions.crm.autoCanned.keywords',
    apis: {
      list: apis.listKeyword,
      create: apis.createKeyword,
      update: apis.updateKeyword,
      del: apis.deleteKeyword
    }
  })
}

export default Actions
