import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { bindActionCreators } from 'redux'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import { compose } from 'recompose'
import { setUser } from 'actions/user'
import { setAccount } from 'actions/account'
import { setCustomTheme } from 'actions/theme'
import { setGroupOption } from 'actions/group-option'
import { apiGetUserInfo } from 'apis/auth'
import { apiGetGroupOption, apiGetGroupOptionV2 } from 'apis/group-option'
import { setPaymentCustomer } from 'actions/payment'
import { get, find } from 'lodash'

/**
 * @FIXME mock data
 */
import { sites } from 'modules/loginWithSignup/customLayout/temporaryResource/constants'

/**
 * @return {user: Object, account: Object, authenticated: Boolean}
 */
export const mapStateToProps = createStructuredSelector({
  user: createSelector(state => state.user, user => user),
  account: createSelector(state => state.account, account => account),
  userGroupOption: createSelector(
    state => state.userGroupOption,
    userGroupOption => userGroupOption
  ),
  theme: createSelector(state => state.theme, theme => theme),
  authenticated: createSelector(
    state => state.user,
    state => state.account,
    (user, account) => {
      // If user not empty is mean Authenicated
      return !isEmpty(user) && !isEmpty(account)
    }
  )
})

/**
 * create action setUser when checked pass from fetch api.
 * @param {Function} dispatch
 */
export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUser,
      setAccount,
      setPaymentCustomer,
      setGroupOption,
      setCustomTheme
    },
    dispatch
  )
}

export const Container = Component =>
  connect(mapStateToProps, mapDispatchToProps)(Component)

/**
 * Enhancer for wrap any component easy to reuse for receive nessessary props.
 * @param {:ReactComponent} Component
 */
export function authenticated(Component) {
  class AuthenticatedEnhancer extends React.Component {
    // validate props
    static propTypes = {
      authenticated: PropTypes.bool.isRequired,
      setAccount: PropTypes.func.isRequired,
      setUser: PropTypes.func.isRequired,
      setGroupOption: PropTypes.func.isRequired
    };

    /*
    authenticated state for first time mounted component.
    It will not render since fetch api.
    It will render after receive data from api and dispatch to update store already.
    */
    state = {
      authenticated: null
    };

    componentWillReceiveProps(nextProp) {
      const { authenticated } = nextProp
      // Check for state or props not change it will not be rerender.
      if (
        nextProp.authenticated !== this.props.authenticated &&
        nextProp.authenticated !== this.state.authenticated
      ) {
        this.setState({
          authenticated
        })
      }
    }

    /**
     * First time mounted component call this.authCheck()
     */
    componentDidMount() {
      this.authCheck()
    }
    /**
     * Check authenticate and manage authenticated status.
     */
    authCheck = async () => {
      const { authenticated } = this.props
      try {
        if (!authenticated) {
          await this.requestApi()
        }
        this.setState({
          authenticated
        })
      } catch (error) {
        this.setState({
          authenticated
        })
      }
    };
    /**
    * Get Group Option data per page only nessessary fields before dispatch data to store.
    */
    getGroupOption = async (groupID, product) => {
      if (!includes(['listening', 'crm'], product)) return {}
      try {
        var path = window.location.pathname
        switch (path) {
          case '/settings/keywords/listening':
            return await apiGetGroupOptionV2(null, groupID, product, 'keywords', 'name,parent_id,patterns', 'parent_id,name,patterns')
          case '/settings/tags/listening':
            return await apiGetGroupOptionV2(null, groupID, product, 'tags', 'name,parent_id')
          case '/settings/templates/listening':
            const [
              templates_keyword,
              templates_tag,
              templates_monitoring,
              templates_engagement
            ] = await Promise.all([
              apiGetGroupOptionV2(null, groupID, product, 'keywords', 'name,parent_id', 'parent_id,name'),
              apiGetGroupOptionV2(null, groupID, product, 'tags', 'name,parent_id'),
              apiGetGroupOptionV2(null, groupID, product, 'custom_socials'),
              apiGetGroupOptionV2(null, groupID, product, 'engagements')
            ])
            return {
              ...templates_keyword,
              ...templates_tag,
              ...templates_monitoring,
              ...templates_engagement
            }
          case '/settings/social-custom/listening':
            const [
              social_custom_keyword,
              social_custom_monitoring
            ] = await Promise.all([
              apiGetGroupOptionV2(null, groupID, product, 'keywords', 'name,parent_id', 'parent_id,name'),
              apiGetGroupOptionV2(null, groupID, product, 'custom_socials')
            ])
            return { ...social_custom_keyword, ...social_custom_monitoring }
          case '/settings/engagement/listening':
            return await apiGetGroupOptionV2(null, groupID, product, 'engagements')
          case '/settings/manage/listening':
            return {}
          case '/settings/email-notification/listening':
            return {}
          case '/settings/blacklist/listening':
            return {}
          case '/settings/ui/crm':
            return {}
          case '/settings/sla/crm':
            return {}
          default:
            return await apiGetGroupOption(null, groupID, product)
        }
      } catch (error) {
      }
    };
    /**
     * Fetch check authenticated status from api and dispatch data to store.
     */
    requestApi = async () => {
      const { setUser, setAccount, setPaymentCustomer, setGroupOption, computedMatch, setCustomTheme } = this.props
      try {
        const product = get(computedMatch, 'params.product', '')
        const userInfo = await apiGetUserInfo()
        const groupID = get(userInfo, 'group._id', '')
        const groupOption = await this.getGroupOption(groupID, product)
        const origin = await window.location.origin
        const object = find(sites, { 'origin': origin })
        if (object) {
          let newTheme = {
            is_set: true,
            details: object
          }
          await setCustomTheme(newTheme)
        }
        await setUser({
          ...userInfo.user,
          role: userInfo.role,
          group: userInfo.group,
          plan: userInfo.plan
        })
        await setAccount(userInfo.account)
        await setPaymentCustomer(userInfo.customer || {})
        await setGroupOption(groupOption)
        return true
      } catch (error) {
        throw error
      }
    };

    /**
     * render argument wrapped Component with all props from
     * (connect Container, func authCheck, authenticated status).
     */
    render() {
      if (this.state.authenticated === null) return null
      return (
        <Component
          authenticated={this.state.authenticated}
          authCheck={() => this.requestApi()}
          paymentCheck={() => this.requestApi()}
          {...this.props}
        />
      )
    }
  }

  return AuthenticatedEnhancer
}

export const Authenticated = compose(Container)(props => {
  const { authenticated = false, children = () => null, not = false } = props
  const isNotRender = not ? authenticated : !authenticated
  if (isNotRender || Array.isArray(children)) {
    return null
  }
  return children
})

// Export most actually used.
export default Component => compose(Container, authenticated)(Component)
