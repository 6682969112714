import { loop, Cmd } from 'redux-loop'
import { createActions, handleActions } from 'redux-actions'
import get from 'lodash/get'
import toastr from '@zanroo/react-redux-toastr-arun'
import { apiGetSlaSetting, apiSaveSlaSetting, apiDeleteSlaSetting } from './apis'
import { defaultSettings } from './helpers'
import actionsUsers from 'actions/users'
import engagemensActions from 'actions/crm/engagements'
import actionsCrm from 'actions/crm'

const initState = {
  loading: false,
  error: null,
  list: [
    {...defaultSettings}
  ]

}

const {
  fetchSlaSettings,
  fetchSlaSettingsSuccess,
  fetchSlaSettingsError,
  saveSlaSettings,
  saveSlaSettingsSuccess,
  saveSlaSettingsError
} = createActions({
  FETCH_SLA_SETTINGS: () => { },
  FETCH_SLA_SETTINGS_SUCCESS: payload => payload,
  FETCH_SLA_SETTINGS_ERROR: error => ({ error }),
  SAVE_SLA_SETTINGS: data => data,
  SAVE_SLA_SETTINGS_SUCCESS: payload => payload,
  SAVE_SLA_SETTINGS_ERROR: error => ({ error })
})

export const actionCreators = {
  fetchSlaSettings,
  saveSlaSettings,
  getInitialData: () => async (dispatch) => {
    try {
      await Promise.all([
        dispatch(actionsUsers.middlewares.getUsers()),
        dispatch(engagemensActions.middlewares.getEngagements()),
        dispatch(actionsCrm.tags.middlewares.getTags()),
        dispatch(actionsCrm.keywords.middlewares.getKeywords()),
      ])
    } catch (error) {
      console.error(error)
    }
  },
  deleteSetting: (id) => async (dispatch) => {
    try {
      await apiDeleteSlaSetting(id)
      toastr.success('Success', 'Delete completed')
    } catch (error) {
      toastr.error('Error', error.message)
    }
    
  }
}

const reducer = handleActions(
  {
    [fetchSlaSettings]: (state, action) =>
      loop(
        { ...state, loading: true },
        Cmd.run(apiGetSlaSetting, {
          successActionCreator: fetchSlaSettingsSuccess,
          failActionCreator: fetchSlaSettingsError
        })
      ),
    [fetchSlaSettingsError]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: false
    }),
    [fetchSlaSettingsSuccess]: (state, action) => ({
      ...state,
      loading: false,
      list: get(action.payload, 'body.sla')
    }),
    [saveSlaSettings]: (state, action) =>
      loop(
        { ...state, loading: true },
        Cmd.run(apiSaveSlaSetting, {
          successActionCreator: saveSlaSettingsSuccess,
          failActionCreator: saveSlaSettingsError,
          args: [{ ...action.payload }]
        })
      ),
    [saveSlaSettingsSuccess]: (state, action) => loop({
      ...state,
      ...get(action.payload, 'body.sla'),
      loading: false
    }, Cmd.run(toastr.success, { args: ['SLA', `You've just updated setting.`] })),
    [saveSlaSettingsError]: (state, action) => loop({
      ...state,
      ...action.payload,
      loading: false
    }, Cmd.run(toastr.error, { args: ['Error', action.payload.error.message] }))
  },
  initState
)

export default reducer
