import { createAction } from 'redux-actions'
import {
  createFullCycleActions
} from 'utils/actions'
import * as apis from 'apis/crm/uiSettings'

const Actions = {
  settings: createFullCycleActions({
    prefix: 'actions.crm.ui.settings',
    apis: {
      update: apis.updateSettings
    }
  }),
  pending: {
    can_edit_after_done: {
      set: createAction(`actions.crm.ui.loading.canEditAfterDone.set`)
    },
    can_reply_after_done: {
      set: createAction(`actions.crm.ui.loading.canReplyAfterDone.set`)
    },
    no_dup_open_inbox: {
      set: createAction(`actions.crm.ui.loading.noDupOpenInbox.set`)
    },
    action_on_assigned: {
      set: createAction(`actions.crm.ui.loading.actionOnAssigned.set`)
    },
    timer_enabled: {
      set: createAction(`actions.crm.ui.loading.timerEnabled.set`)
    },
    done_with_assignee: {
      set: createAction(`actions.crm.ui.loading.doneWithAssignee.set`)
    },
    reply_with_assignee: {
      set: createAction(`actions.crm.ui.loading.replyWithAssignee.set`)
    },
    done_with_survey: {
      set: createAction(`actions.crm.ui.loading.doneWithSurvey.set`)
    },
    tag_search_option: {
      set: createAction(`actions.crm.ui.loading.tagSearchOption.set`)
    },
    pause_survey_enabled: {
      set: createAction(`actions.crm.ui.loading.pauseSurveyEnabled.set`)
    },
    pause_survey_duration: {
      set: createAction(`actions.crm.ui.loading.pauseSurveyDuraton.set`)
    },
    email_escalation: {
      set: createAction(`actions.crm.ui.loading.emailEscalation.set`)
    },
    ticket_states: {
      set: createAction(`actions.crm.ui.loading.ticketStates.set`)
    }
  }
}

export default Actions
