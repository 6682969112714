import {
  API_CMS_INTEREST_TAGS
} from 'constants/ApiUrls'
import { METHOD_POST, METHOD_PUT, METHOD_DELETE } from 'constants/Methods'
import { createApi } from 'utils/index'

export const LIST = () => createApi(`${API_CMS_INTEREST_TAGS}`)
export const CREATE = (data) => createApi(`${API_CMS_INTEREST_TAGS}`, METHOD_POST, data)
export const UPDATE = (data) => createApi(`${API_CMS_INTEREST_TAGS}/${data._id}`, METHOD_PUT, data)
export const DELETE = (id) => createApi(`${API_CMS_INTEREST_TAGS}/${id}`, METHOD_DELETE)
