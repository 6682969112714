import {
  API_WORKING_STATUS
} from 'constants/ApiUrls'
import { METHOD_POST, METHOD_PUT, METHOD_DELETE } from 'constants/Methods'
import { createApi } from 'utils/index'

export const list = () => createApi(`${API_WORKING_STATUS}`)
export const createStatus = (data) => createApi(`${API_WORKING_STATUS}`, METHOD_POST, data)
export const getStatus = (data) => createApi(`${API_WORKING_STATUS}/${data._id}`)
export const updateStatus = (data) => createApi(`${API_WORKING_STATUS}/${data._id}`, METHOD_PUT, data)
export const deleteStatus = (data) => createApi(`${API_WORKING_STATUS}/${data._id}`, METHOD_DELETE)

