import { schema } from 'normalizr'

const response = new schema.Entity('response', {}, { idAttribute: '_id' })

const folder = new schema.Entity(
  'folder',
  {
    responses: [response]
  },
  { idAttribute: '_id' }
)

const cannedResponse = { folders: new schema.Array(folder) }

export default cannedResponse
