import {
  API_CRM_CUSTOM_ACTION
} from 'constants/ApiUrls'
import { METHOD_POST, METHOD_DELETE, METHOD_PUT } from 'constants/Methods'
import { createApi } from 'utils/index'

export const ListPostBlocker = () => createApi(`${API_CRM_CUSTOM_ACTION}`)
export const CreatePostBlocker = (data) => createApi(`${API_CRM_CUSTOM_ACTION}`, METHOD_POST, data)
export const DeletePostBlocker = (id) => createApi(`${API_CRM_CUSTOM_ACTION}/${id}`, METHOD_DELETE)
export const UpdatePostBlocker = (data) => createApi(`${API_CRM_CUSTOM_ACTION}/${data._id}`, METHOD_PUT, data)
