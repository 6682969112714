import React from 'react'
import PropTypes from 'prop-types'
import {
  compose,
  setPropTypes,
  onlyUpdateForKeys,
  defaultProps
} from 'recompose'
// import { Row, Col } from 'react-bootstrap'
import InlineControl from './InlineControl'

/**
 * @desc map render InlineControl from list
 * @example render
 * <InlineControlList {...handlers}>
 *  <InlineControl key={0} {...handlers} />
 *  <InlineControl key={1} {...handlers} />
 *  <InlineControl key={2} {...handlers} />
 *  <InlineControl key={3} {...handlers} />
 * </InlineControlList>
 */
export const InlineControlList = {
  setPropTypes: {
    list: PropTypes.array,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    getValue: PropTypes.func
  },
  defaultProps: {
    list: [],
    onSubmit () {},
    onDelete () {},
    getValue: e => e
  },
  render (props) {
    const { list, onSubmit, onDelete, getValue, onSelectedKeyword, ...rest } = props
    return (
      <div className='include-list'>
        {list.map((item, i) => {
          const value = getValue(item)
          return (
            <InlineControl
              {...rest}
              key={`list.${i}`}
              edit
              del
              value={value}
              onDelete={() => onDelete(i)}
              onSubmit={value => {
                return onSubmit(value, i)
              }}
            />
          )
        })}
      </div>
    )
  }
}

export default compose(
  setPropTypes(InlineControlList.setPropTypes),
  defaultProps(InlineControlList.defaultProps),
  onlyUpdateForKeys(['list'])
)(InlineControlList.render)
