import { combineReducers } from 'redux'
import autoAssign from './autoAssign'
import autoSentiment from './autoSentiment'
import keywords from './keywords'
import tags from './tags'
import groupManage from './groupManage'
import ui from './uiSettings'
import autoCanned from './autoCanned'
import engagements from './engagements'
import verification from './verification'
import postBlocker from './postBlocker'
import workingStatus from './workingStatus'

export default combineReducers({
  autoAssign,
  autoSentiment,
  keywords,
  tags,
  groupManage,
  ui,
  autoCanned,
  engagements,
  verification,
  postBlocker,
  workingStatus
})
