import { handleActions } from 'redux-actions'
import update from 'immutability-helper'
import keyBy from 'lodash/keyBy'

export const setKeyBy = (by = '_id') => (state, action) => {
  return keyBy(action.payload, by)
}

export const createFullCycleHandlers = (actions, initialState, customHandlers = {}) => handleActions({
  [actions.data.set]: (state, action) => {
    return update(state, {
      data: { $set: action.payload }
    })
  },
  [actions.data.reset]: (state) => {
    return update(state, {
      data: { $set: state.default }
    })
  },
  [actions.default.set]: (state, action) => {
    return update(state, {
      data: { $set: action.payload },
      default: { $set: action.payload }
    })
  },
  [actions.api.pending]: (state, action) => {
    return update(state, {
      api: {
        pending: { $set: true },
        success: { $set: false }
      }
    })
  },
  [actions.api.success]: (state, action) => {
    return update(state, {
      api: {
        pending: { $set: false },
        success: { $set: true }
      }
    })
  },
  [actions.api.error]: (state, action) => {
    return update(state, {
      api: {
        pending: { $set: false },
        success: { $set: false },
        error: { $set: action.payload }
      }
    })
  },
  ...customHandlers
}, initialState)
