import React from 'react'
import { compose, defaultProps, withHandlers } from 'recompose'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import styled from 'styled-components'
import { SketchPicker } from 'react-color'

/**
 * @desc for select color and return color selected in onChange callback
 */
export const ColorPicker = {
  /**
   * @typedef {Object} defaultProps
   * @property {Fucntion} defaultProps.onChange
   */
  defaultProps: {
    onChange() { }
  },
  /**
   * @desc handle after selected colors
   * @param {{ onChange: Function }}
   * @return {{ onChangeComplete: Function }}
   */
  withHandlers: ({ onChange }) => ({
    /**
     * @desc call onChange with hex color after color selected
     * @param {{ hex: String }}
     */
    onChangeComplete: () => ({ hex }) => {
      onChange(hex)
    }
  }),
  /**
   * @desc render color box and handle show popover right color picker after click it
   * @param {{color: String, onChangeComplete: Function}} props
   * @return {ReactElement}
   */
  render(props) {
    const { color, onChangeComplete, customStyle } = props
    const hasColor = color !== ''
    const Color = styled.div`
      cursor: pointer;
      height: 15px;
      width: 15px;
      margin-left: 10px;
      ${hasColor
        ? `background-color: ${color}`
        : `background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);`};
      ${customStyle}
    `
    const Picker = (
      <Popover id='color-picker'>
        <SketchPicker
          color={color}
          disableAlpha
          onChangeComplete={onChangeComplete}
        />
      </Popover>
    )
    return (
      <OverlayTrigger
        trigger='click'
        rootClose
        placement='right'
        overlay={Picker}
      >
        <Color />
      </OverlayTrigger>
    )
  }
}

export default compose(
  defaultProps(ColorPicker.defaultProps),
  withHandlers(ColorPicker.withHandlers)
)(ColorPicker.render)
