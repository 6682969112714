const defaultOptions = {
  toolbar: 'no',
  location: 'no',
  directories: 'no',
  status: 'no',
  menubar: 'no',
  scrollbars: 'yes',
  resizable: 'yes',
  width: 600,
  height: 600,
  top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
  left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
}

const popupOption = options => {
  const mergedOptions = Object.assign({}, defaultOptions, options)

  return Object.keys(mergedOptions)
    .map(
      key =>
        key +
        '=' +
        (typeof mergedOptions[key] === 'function'
          ? mergedOptions[key].call(this, mergedOptions, window)
          : mergedOptions[key])
    )
    .join(',')
}

export default ({ url, title, options }) => {
  window.open(url, title, popupOption(options))
}
