import { createAction } from 'redux-actions'
import { bindMiddlewaresCreator, withThunkMiddleware } from 'utils/actions'
import { apiListEngagement, apiUpdateEngagement } from 'apis/engagement'
import { filter, get, findIndex } from 'lodash'
import update from 'immutability-helper'

const actions = {
  set: createAction(
    'CRM/ENGAGEMENTS/SET')
}

const middlewares = {
  async getEngagements(dispatch, state) {
    const { body } = await apiListEngagement()
    const crmEngagements = filter(body.items, (item) => { return get(item, 'product.crm', false) })
    dispatch(actions.set(crmEngagements))
  },
  async updateEngagement(dispatch, state, payload) {
    const { body } = await apiUpdateEngagement(payload._id, payload.data)
    const items = state.crm.engagements.items

    const index = findIndex(items, { _id: body._id })

    if (index < 0) {
      return
    }

    const updated = update(items, {
      [index]: { $set: body }
    })

    dispatch(actions.set(updated))
  }
}

actions.middlewares = bindMiddlewaresCreator(middlewares, withThunkMiddleware())

export default actions
