import { bindMiddlewaresCreator, withThunkMiddleware } from 'utils/actions'
import * as actionsTags from '../ticketTags'
import apis from 'apis/crm/tags'

const actions = {
  set: actionsTags.actionSetTicketTags
}

const middlewares = {
  async getTags (dispatch, state, payload) {
    const {
      body: {
        items = []
      } = {}
    } = await apis.apiGetTicketTags('crm')
    dispatch(actions.set(items))
  }
}

actions.middlewares = bindMiddlewaresCreator(middlewares, withThunkMiddleware())

export default actions
