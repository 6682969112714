import React from 'react'
import { Bundle } from 'utils/index'

export const PageAdmin = props => (
  <Bundle load={import('modules/adminPage/AccountList')}>
    {Component => <Component {...props} />}
  </Bundle>
)
// export const AdminAccountSettings = props => (
//   <Bundle load={import('modules/adminPage/AccountSettings')}>
//     {Component => <Component {...props} />}
//   </Bundle>
// )
export const AdminAccountAdd = props => (
  <Bundle load={import('modules/adminPage/Account/AccountAdd')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const AdminMigrateKeyword = props => (
  <Bundle load={import('modules/adminPage/MigrateKeyword/migrateKeyword')}>
    {Component => <Component {...props} />}
  </Bundle>
)

export const AdminRoleSettings = props => (
  <Bundle load={import('modules/adminPage/RoleList')}>
    {Component => <Component {...props} />}
  </Bundle>
)
export const AdminPermissionSettings = props => (
  <Bundle load={import('modules/adminPage/PermissionSettings/index')}>
    {Component => <Component {...props} />}
  </Bundle>
)
export const AdminPlanManage = props => (
  <Bundle load={import('modules/adminPage/PlanManagePage')}>
    {Component => <Component {...props} />}
  </Bundle>
)
export const AdminProductPermissionManage = props => (
  <Bundle
    load={import('modules/adminPage/ProductPermission/ProductPermissionPage')}
  >
    {Component => <Component {...props} />}
  </Bundle>
)
export const AdminDefaultRole = props => (
  <Bundle load={import('modules/adminPage/DefaultRole/')}>
    {Component => <Component {...props} />}
  </Bundle>
)
export const AdminPlanSettings = props => (
  <Bundle load={import('modules/adminPage/PlanSettings')}>
    {Component => <Component {...props} />}
  </Bundle>
)
export const RemoteUsersNew = props => (
  <Bundle load={import('modules/adminPage/RemoteUserListNew/index')}>
    {Component => <Component {...props} />}
  </Bundle>
)
export const RemoteUsers = props => (
  <Bundle load={import('modules/adminPage/RemoteUserList')}>
    {Component => <Component {...props} />}
  </Bundle>
)
