import React from 'react'
import PropTypes from 'prop-types'
import { compose, setPropTypes, defaultProps, withHandlers } from 'recompose'
// import { Row, Col, Button } from 'react-bootstrap'
import {
  // InlineLabel,
  InlineInputText,
  InputTextEnhancer
} from './InlineInputText'
import Inline, { INLINE_CANCEL, INLINE_SUBMIT } from '@zanroo/react-inline'

/**
 * @desc handle inline input with button edit, delete | reset, cancel
 */
export const ComponentControl = {
  setPropTypes: {
    edit: PropTypes.bool,
    del: PropTypes.bool,
    refs: PropTypes.object,
    editing: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onEditing: PropTypes.func,
    onEdited: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func
  },
  defaultProps: {
    edit: false,
    del: false,
    refs: Object.create(null),
    editing: false,
    value: '',
    placeholder: 'click...',
    onEditing() { },
    onEdited() { },
    onChange() { },
    onDelete() { }
  },
  /**
   * @param {{ onEdited: Function ,onEditing: Function , onDelete: Function }} props - prototype function callback
   * @return {{ onClickEdit: Function, onClickDelete: Function, onClickReset: Function }} - functions for handlers
   */
  withHandlers: ({ onEdited, onEditing, onDelete }) => ({
    /**
     * @desc This handler edit button before inline visible or submit after inline visibled.
     * @param {{ editing: Boolean, value: String }}
     */
    onClickEdit: ({ editing }) => () => {
      if (editing) {
        onEdited(INLINE_SUBMIT)
      } else {
        onEditing()
      }
    },
    /**
     * @desc on click delete button it will callback props.onDelete
     */
    onClickDelete: () => () => {
      onDelete()
    },
    /**
     * @desc this handler reset button after inline visibled
     */
    onClickReset: ({ editing }) => () => {
      onEdited(INLINE_CANCEL)
    }
  }),
  /**
   * @desc presentation inline control
   * @param {Object} props
   * @example
   * <Layout>
   *  editing && <InputTextEnhancer {...handlers} />
   *  !editing && <InlineLabel {...handlers} />
   *  <Button {...handlers}>Edit<Button/>
   *  <Button {...handlers}>Delete<Button/>
   * </Layout>
   *
   */
  render(props) {
    /**
     * @typedef {Object} props
     * @property {Boolean} props.edit - if need to use edit button send true data
     * @property {Boolean} props.del - if need to use delete button send true data
     * @property {Boolean} props.refs - for set assign dom ref
     * @property {Boolean} props.editing - editing status, true is edit, false will show label instead input box
     * @property {Boolean} props.onEditing - for set props.editing = true before editing
     * @property {Boolean} props.onEdited - for set props.editing = false when edited
     * @property {String} props.value - value will send to <Inline /> also
     * @property {Function} props.onChange - on value change callback this function
     * @property {Function} props.onClickEdit - on click edit, onFocus or onSubmit from <Inline /> callback this function
     * @property {Function} props.onClickDelete - on click delete callback this function
     * @property {Function} props.onClickReset - on click reset, onCancel from <Inline /> callback this function
     */
    const {
      edit,
      del,
      refs,
      editing,
      onEditing,
      onEdited,
      value,
      placeholder,
      onChange,
      onSubmit,
      // onClickEdit,
      onClickDelete,
      // onClickReset,
      ...rest
    } = props
    if (editing) {
      return (
        <InputTextEnhancer
          refs={refs}
          value={value}
          onChange={onChange}
          onEdited={onEdited}
          editing={editing}
          cancelEventTypes={['']}
          {...rest}
          className='input-search input-key'
          onSaveEnable
          onSaveEdit={onSubmit}
        />
      )
    }
    return (
      // <p className='edit-text add-key' title='' onClick={onEditing}>
      <p className='edit-text add-key'>
        <span
          style={{
            width: 'calc(100% - 70px)',
            wordBreak: 'break-word',
            display: '-webkit-inline-box'
          }}
        >
          {value || placeholder}
        </span>
        <span className='icon-detail' onClick={onClickDelete} style={{ visibility: 'unset' }}>
          {del && (
            <img
              title='delete'
              alt=''
              src={require('assets/img/icon/rubbish-bin.svg')}
            />
          )}
        </span>
        <span className='icon-detail' onClick={onEditing} style={{ visibility: 'unset' }}>
          {del && (
            <img
              style={{
                marginLeft: '0px',
                marginRight: '0px'
              }}
              title='edit'
              alt=''
              src={require('assets/img/icon/pencil.svg')}
            />
          )}
        </span>
      </p>
    )
    // return (
    //   <Row>
    //     <Col sm={8}>
    //       {editing ? (
    //         <InputTextEnhancer
    //           refs={refs}
    //           value={value}
    //           onChange={onChange}
    //           onEdited={onEdited}
    //           editing={editing}
    //           cancelEventTypes={['']}
    //         />
    //       ) : (
    //         <InlineLabel
    //           onEditing={onEditing}
    //           value={value}
    //           editing={editing}
    //         />
    //       )}
    //     </Col>
    //     {editing ? (
    //       <Col sm={4}>
    //         <Button onClick={onClickEdit} bsSize="xs">
    //           <i className="fa fa-check" />
    //         </Button>
    //         <Button onClick={onClickReset} bsSize="xs">
    //           <i className="fa fa-refresh" />
    //         </Button>
    //       </Col>
    //     ) : (
    //       <Col sm={4}>
    //         {edit && (
    //           <Button onClick={onClickEdit} bsSize="xs">
    //             <i className="fa fa-edit" />
    //           </Button>
    //         )}
    //         {del && (
    //           <Button onClick={onClickDelete} bsSize="xs">
    //             <i className="fa fa-times" />
    //           </Button>
    //         )}
    //       </Col>
    //     )}
    //   </Row>
    // )
  }
}

export const ComponentControlEnhancer = compose(
  setPropTypes(ComponentControl.setPropTypes),
  defaultProps(ComponentControl.defaultProps),
  withHandlers(ComponentControl.withHandlers)
)(ComponentControl.render)

export const InlineControl = {
  /**
   * @desc reuse defaultProps and withHandlers from InlineInputText
   */
  defaultProps: InlineInputText.defaultProps,
  withHandlers: InlineInputText.withHandlers,
  /**
   * @desc render <Inline /> with customize ComponentLabel and ComponentInline
   * @param {Object} props
   * @example
   * <InlineControl {...handleEvents} >
   *  editing && <ComponentControlEnhancer {...handleEvents} />
   *  !editing && <ComponentControlEnhancer {...handleEvents} />
   * </InlineControl>
   */
  render(props) {
    /**
     * @type {{ onFocus: Function, onChange: Function, onCancel: Function, onSubmit: Function, value: String }}
     */
    const {
      onFocus,
      onChange,
      onCancel,
      onSubmit,
      value,
      refs,
      ...rest
    } = props
    return (
      <Inline
        refs={refs}
        value={value}
        onChange={onChange}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onFocus={onFocus}
        ComponentLabel={ComponentControlEnhancer}
        ComponentInline={ComponentControlEnhancer}
        {...rest}
      />
    )
  }
}

export default compose(
  defaultProps(InlineControl.defaultProps),
  withHandlers(InlineControl.withHandlers)
)(InlineControl.render)
