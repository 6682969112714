import {
  createFullCycleActions, createMiddlewares
} from 'utils/actions'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'
import update from 'immutability-helper'
import toastr from '@zanroo/react-redux-toastr-arun'
import * as apis from 'apis/crm/workingStatus'

const Actions = {
  settings: createFullCycleActions({
    prefix: 'actions.crm.workingStatus.settings',
    customMiddlewares: (actions) => ({
      list: createMiddlewares({
        caller: apis.list,
        actionApi: actions.api,
        dispatcher: ({ data, statusCode, message }) => async (dispatch, getState) => {
          if (statusCode !== 20000) {
            toastr.error('ERROR', message)
            return
          }
          await dispatch(actions.default.set(data))
        }
      }),
      create: createMiddlewares({
        caller: apis.createStatus,
        actionApi: actions.api,
        dispatcher: ({ data, statusCode, message }) => async (dispatch, getState) => {
          if (statusCode !== 20000) {
            toastr.error('ERROR', message)
            return
          }
          const state = getState()
          const defaultData = get(state, 'crm.workingStatus.settings.default', {})
          const updated = update(defaultData, {
            items: {
              $push: [data]
            }
          })
          await dispatch(actions.default.set(updated))
          toastr.success('SUCCESS', 'Status has been created.')
        }
      }),
      update: createMiddlewares({
        caller: apis.updateStatus,
        actionApi: actions.api,
        dispatcher: ({ data, statusCode, message }) => async (dispatch, getState) => {
          if (statusCode !== 20000) {
            toastr.error('ERROR', message)
            return
          }
          const state = getState()
          const defaultData = get(state, 'crm.workingStatus.settings.default', {})

          const index = findIndex(defaultData.items, { _id: data._id })

          if (index < 0) {
            return
          }

          const updated = update(defaultData, {
            items: {
              [index]: { $set: data }
            }
          })

          await dispatch(actions.default.set(updated))
          toastr.success('SUCCESS', 'Status has been updated.')
        }
      }),
      del: createMiddlewares({
        caller: apis.deleteStatus,
        actionApi: actions.api,
        dispatcher: ({ data, statusCode, message }) => async (dispatch, getState) => {
          if (statusCode !== 20000) {
            toastr.error('ERROR', message)
            return
          }
          const state = getState()
          const defaultData = get(state, 'crm.workingStatus.settings.default', {})
          const index = findIndex(defaultData.items, { _id: data._id })

          if (index < 0) {
            return
          }

          const updated = update(defaultData, {
            items: { $splice: [[index, 1]] }
          })

          await dispatch(actions.default.set(updated))
          toastr.success('SUCCESS', 'Status has been deleted.')
        }
      })
    })
  })
}

export default Actions
