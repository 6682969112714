import { API_CENTRAL_PRODUCTS } from 'constants/ApiUrls'
import { API_CENTRAL_PRODUCTS_V2 } from 'constants/ApiUrlsV2'
import {
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  METHOD_DELETE
} from 'constants/Methods'
import { createApi } from 'utils/index'

export const apiGetKeywordById = (accountID, data, product = 'listening') =>
  createApi(`${API_CENTRAL_PRODUCTS}/${product}/keywords/${data._id}`, METHOD_GET)

export const apiGetKeyword = (accountID, product = 'listening') =>
  createApi(`${API_CENTRAL_PRODUCTS}/${product}/keywords`, METHOD_GET)

export const apiGetKeywordV2 = (accountID, product = 'listening', fields = 'none') =>
  createApi(`${API_CENTRAL_PRODUCTS}/${product}/keywords/?fields=${fields}`, METHOD_GET)

export const apiGetKeywordTree = (accountID, product = 'listening') =>
  createApi(`${API_CENTRAL_PRODUCTS}/${product}/keywords/?hierarchy=1`, METHOD_GET)

export const apiCreateKeyword = (data, product = 'listening') =>
  createApi(`${API_CENTRAL_PRODUCTS}/${product}/keywords`, METHOD_POST, data)

export const apiCountKeyword = (product = 'listening') =>
  createApi(`${API_CENTRAL_PRODUCTS_V2}/${product}/keywords/count`)

export const apiUpdateKeyword = (accountID, data, product = 'listening') =>
  createApi(
    `${API_CENTRAL_PRODUCTS}/${product}/keywords/${data._id}`,
    METHOD_PUT,
    data
  )

export const apiDeleteKeyword = (keywordId, product = 'listening') =>
  createApi(
    `${API_CENTRAL_PRODUCTS}/${product}/keywords/${keywordId}`,
    METHOD_DELETE
  )
/**
 *    /keywords/:product/options
 *    @FIXME - all these functions copy from old one need to change parameter
 *
 */
export const apiGetKeywordOption = (product = 'listening') =>
  createApi(`${API_CENTRAL_PRODUCTS}/${product}/keywords/options`, METHOD_GET)

export const apiCreateKeywordOption = (data, product = 'listening') =>
  createApi(
    `${API_CENTRAL_PRODUCTS}/${product}/keywords/options`,
    METHOD_POST,
    data
  )

export const apiUpdateKeywordOption = (data, product = 'listening') =>
  createApi(
    `${API_CENTRAL_PRODUCTS}/${product}/keywords/options`,
    METHOD_PUT,
    data
  )

export const apiDeleteKeywordOption = (keywordId, product = 'listening') =>
  createApi(
    `${API_CENTRAL_PRODUCTS}/${product}/keywords/options`,
    METHOD_DELETE
  )
