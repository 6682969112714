import { API_CENTRAL_GROUP_OPTION } from 'constants/ApiUrls'
import { METHOD_GET, METHOD_PUT } from 'constants/Methods'
import { createApi, parseApi } from 'utils/index'
import _ from 'lodash'

// export const apiUpdateGroupOption = (accountID, id, product, data) =>
//   createApi(
//     `${parseApi(API_CENTRAL_GROUP_OPTION, {
//       accountID
//     })}/${product}/group-option/${id}`,
//     METHOD_PUT,
//     data
//   )

// export const apiGetGroupOption = (accountID, id, product) =>
//   createApi(
//     `${parseApi(API_CENTRAL_GROUP_OPTION, {
//       accountID
//     })}/${product}/group-option/${id}`,
//     METHOD_GET
//   )

export const apiUpdateGroupOption = (accountID, id, product, data) =>
  createApi(
    `${parseApi(API_CENTRAL_GROUP_OPTION, {
      accountID
    })}/${product}/group-option-v1.1/${id}`,
    METHOD_PUT,
    data
  )

export const apiGetGroupOption = async (accountID, id, product) => {
  const res = await createApi(
    `${parseApi(API_CENTRAL_GROUP_OPTION, {
      accountID
    })}/${product}/group-option-v1.1/${id}`,
    METHOD_GET
  )
  if (_.get(res, 'keywords.enable')) {
    _.set(res, 'keywords.data', _.compact(res.keywords.data))
  }
  return res
}

export const apiGetGroupOptionSetting = (accountID, id, product) =>
  createApi(
    `${parseApi(API_CENTRAL_GROUP_OPTION, {
      accountID
    })}/${product}/group-option/${id}`,
    METHOD_GET
  )

export const apiGetGroupOptionV2 = (accountID, id, product, field, subFields = 'none', keywordField = 'none', raw = '0') =>
  createApi(
    `${parseApi(API_CENTRAL_GROUP_OPTION, {
      accountID
    })}/${product}/group-option-v2.0/${id}/fields/${field}/${subFields}/${keywordField}/${raw}`,
    METHOD_GET
  )

export const apiGetKeywordByTagId = (accountID, id, product, tagId) =>
  createApi(
    `${parseApi(API_CENTRAL_GROUP_OPTION, {
      accountID
    })}/${product}/group-option-v2.0/${id}/keywords-from-tag-id/${tagId}`,
    METHOD_GET
  )
