const SHORT_CURRENCY = {
  USD: '$'
}

export default {
  format (currency) {
    const CURRENCY = currency.toString().toUpperCase()
    return SHORT_CURRENCY[CURRENCY] || CURRENCY
  },
  amount (amount) {
    return (+amount).toFixed(2)
  }
}
