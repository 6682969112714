import { handleActions } from 'redux-actions'

import {
  getPantipAccountsDone,
  connectPantipDone,
  removePantipAccountDone,
  getFacebookPagesDone,
  connectFacebookPageDone,
  removeFacebookPageDone,
  connectTwitterAccountDone,
  getTwitterAccountsDone,
  removeTwitterAccountDone,
  getLineChatbotsDone,
  connectLineChatbotDone,
  removeLineChatbotDone
} from './actions'

const initialState = {
  pantip: [],
  facebook: [],
  twitter: [],
  line: []
}

export default handleActions(
  {
    [getPantipAccountsDone]: (state, action) => ({
      pantip: action.payload
    }),
    [getFacebookPagesDone]: (state, action) => ({
      facebook: action.payload
    }),
    [getTwitterAccountsDone]: (state, action) => ({
      twitter: action.payload
    }),
    [getLineChatbotsDone]: (state, action) => ({
      line: action.payload
    }),
    [connectPantipDone]: (state, action) => ({
      pantip: [...state.pantip, action.payload]
    }),
    [connectFacebookPageDone]: (state, action) => ({
      facebook: [...state.facebook, action.payload]
    }),
    [connectTwitterAccountDone]: (state, action) => ({
      twitter: [...state.twitter, action.payload]
    }),
    [connectLineChatbotDone]: (state, action) => ({
      line: [...state.line, action.payload]
    }),
    [removePantipAccountDone]: (state, action) => ({
      pantip: state.pantip.filter(
        item => Number(item.pantip_id) !== Number(action.payload)
      )
    }),
    [removeFacebookPageDone]: (state, action) => ({
      facebook: state.facebook.filter(
        item => Number(item.facebook_id) !== Number(action.payload)
      )
    }),
    [removeTwitterAccountDone]: (state, action) => ({
      twitter: state.twitter.filter(
        item => Number(item.twitter_id) !== Number(action.payload)
      )
    }),
    [removeLineChatbotDone]: (state, action) => ({
      line: state.line.filter(
        item => String(item.channel_id) !== String(action.payload)
      )
    })
  },
  initialState
)
