import queryString from 'query-string'
import { API_CANNED_RESPONSE } from 'constants/ApiUrls'
import {
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  METHOD_DELETE
} from 'constants/Methods'
import { createApi } from 'utils/index'

export const apiGetCannedResponseFolders = data =>
  createApi(
    `${API_CANNED_RESPONSE}/folders?${queryString.stringify(data)}`,
    METHOD_GET
  )

export const apiPostNewCannedResponseFolder = data =>
  createApi(`${API_CANNED_RESPONSE}/folders`, METHOD_POST, data)

export const apiPutChangeFolderName = ({ folderId = '', name = '' }) =>
  createApi(`${API_CANNED_RESPONSE}/folders/${folderId}`, METHOD_PUT, { name })

export const apiDeleteFolder = folderId =>
  createApi(`${API_CANNED_RESPONSE}/folders/${folderId}`, METHOD_DELETE)

export const apiPostNewCannedResponse = data =>
  createApi(`${API_CANNED_RESPONSE}/`, METHOD_POST, data)

export const apiDeleteCannedResponse = cannedId =>
  createApi(`${API_CANNED_RESPONSE}/${cannedId}`, METHOD_DELETE)

export const apiPutChangeCannedResponse = (cannedId, data) =>
  createApi(`${API_CANNED_RESPONSE}/${cannedId}`, METHOD_PUT, data)
