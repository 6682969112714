const api = {
  pending: false,
  success: true,
  error: ''
}
const defaultState = {
  data: {},
  default: {},
  api
}

export default {
  interest_tag: {
    settings: defaultState
  },
}
