import { createAction } from 'redux-actions'
import {
  createFullCycleActions
} from 'utils/actions'
import * as apis from 'apis/crm/groupManage'

const Actions = {
  settings: createFullCycleActions({
    prefix: 'actions.crm.groupManage.settings',
    apis: {
      get: apis.getSettings,
      update: apis.updateSettings
    }
  }),
  ui: {
    groupSelected: {
      set: createAction('actions.crm.groupManage.ui.groupSelected.set')
    }
  }
}

export default Actions
