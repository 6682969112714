/* import from node_modules */
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-theme.css'
import 'font-awesome/css/font-awesome.min.css'
import 'react-select/dist/react-select.css'
import '@zanroo/react-redux-toastr-arun/dist/toastr.css'

/* import from asset */
import './styles/style.css'
import './styles/style-responsive.css'
import './styles/customize/global-styles'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import configureStore from './store'
import Routes from './routes'
// import { registerServiceWorker, ErrorBoundary } from './utils'
import { ErrorBoundary } from './utils'

import { SocketProvider } from 'enhancer/socket'
import Realtime from 'enhancer/Realtime'

export const store = configureStore()

const RootDOMElement = document.getElementById('root')

if (RootDOMElement !== null) {
  const isDev = process.env.NODE_ENV === 'development'
  const App = (

    <ErrorBoundary>
      <Provider store={store}>
        <SocketProvider>
          <Realtime />
          <Routes />
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position='bottom-right'
            transitionIn='fadeIn'
            transitionOut='fadeOut'
            progressBar
          />
        </SocketProvider>
      </Provider>
    </ErrorBoundary>
  )
  if (isDev) {
    const RedBox = require('redbox-react').default
    try {
      render(App, RootDOMElement)
    } catch (e) {
      render(<RedBox error={e} />, RootDOMElement)
    }
  } else {
    render(App, RootDOMElement)
  }
  // registerServiceWorker()
}
