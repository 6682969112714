import PropTypes from 'prop-types'
import {
  compose,
  getContext,
  setDisplayName,
  setPropTypes,
  withContext,
  defaultProps
} from 'recompose'
import RocketClient from '@zanroo/rocket-client'
import { ROCKET_API } from 'constants/ApiUrls'
import Cookies from 'js-cookie'

const SocketHOC = compose(
  setDisplayName('SocketProvider'),
  setPropTypes({
    connection: PropTypes.object
  }),
  defaultProps({
    connection: new RocketClient(ROCKET_API, {
      query: {
        rocket: Cookies.get('rocket', '', {
          domain: process.env.REACT_APP_SSO_COOKIE_DOMAIN
        })
      }
    })
  }),
  withContext(
    {
      connection: PropTypes.object
    },
    props => ({
      connection: props.connection
    })
  )
)

const SocketProvider = SocketHOC(({ children }) => children)

const withSocket = getContext({ connection: PropTypes.object })

export { SocketHOC, SocketProvider, withSocket }
