import { SET_ACCOUNT, UPDATE_ACCOUNT } from 'constants/ActionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNT:
      return action.payload
    case UPDATE_ACCOUNT:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
