import {
  createFullCycleActions,
  createMiddlewares
} from 'utils/actions'
import _ from 'lodash'
import toastr from '@zanroo/react-redux-toastr-arun'
import update from 'immutability-helper'
import * as apis from 'apis/crm/verification'

const Actions = {
  settings: createFullCycleActions({
    prefix: 'actions.crm.verification.settings',
    apis: {
      list: apis.listSettings,
      create: apis.createSetting,
      update: apis.updateSetting,
      del: apis.deleteSetting
    },
    customMiddlewares: (actions) => ({
      create: createMiddlewares({
        caller: apis.createSetting,
        actionApi: actions.api,
        dispatcher: (data) => async (dispatch, getState) => {
          const state = getState()
          const defaultData = _.get(state, 'crm.verification.settings.default', {})

          const updated = update(defaultData, {
            items: {
              $push: [data]
            }
          })

          await dispatch(Actions.settings.default.set(updated))

          toastr.success('SUCCESS', 'Setting has been created.')
        }
      }),
      update: createMiddlewares({
        caller: apis.updateSetting,
        actionApi: actions.api,
        dispatcher: (data) => async (dispatch, getState) => {
          const state = getState()
          const defaultData = _.get(state, 'crm.verification.settings.default', {})

          const index = _.findIndex(defaultData.items, {_id: data._id})

          if (index < 0) {
            return
          }

          const updated = update(defaultData, {
            items: {
              [index]: {$set: data}
            }
          })

          await dispatch(Actions.settings.default.set(updated))
          toastr.success('SUCCESS', 'Setting has been updated.')
        }
      }),
      del: createMiddlewares({
        caller: apis.deleteSetting,
        actionApi: actions.api,
        dispatcher: (id) => async (dispatch, getState) => {
          const state = getState()
          const defaultData = _.get(state, 'crm.verification.settings.default', {})
          const index = _.findIndex(defaultData.items, {_id: id})

          if (index < 0) {
            return
          }

          const updated = update(defaultData, {
            items: { $splice: [[index, 1]] }
          })

          await dispatch(Actions.settings.default.set(updated))
          toastr.success('SUCCESS', 'Setting has been deleted.')
        }
      })
    })
  }),
  labels: createFullCycleActions({
    prefix: 'actions.crm.verification.labels',
    apis: {
      list: apis.listLabels,
      create: apis.createLabel,
      update: apis.updateLabel,
      del: apis.deleteLabel
    },
    customMiddlewares: (actions) => ({
      create: createMiddlewares({
        caller: apis.createLabel,
        actionApi: actions.api,
        dispatcher: (data) => async (dispatch, getState) => {
          const state = getState()
          const defaultLabels = _.get(state, 'crm.verification.labels.default', {})

          const updated = update(defaultLabels, {
            items: {
              $push: [data]
            }
          })
          await dispatch(Actions.labels.default.set(updated))
          toastr.success('SUCCESS', 'Setting has been created.')
        }
      }),
      update: createMiddlewares({
        caller: apis.updateLabel,
        actionApi: actions.api,
        dispatcher: (data) => async (dispatch, getState) => {
          const state = getState()
          const defaultLabel = _.get(state, 'crm.verification.labels.default', {})

          const index = _.findIndex(defaultLabel.items, {_id: data._id})

          if (index < 0) {
            return
          }

          const updated = update(defaultLabel, {
            items: {
              [index]: {$set: data}
            }
          })

          await dispatch(Actions.labels.default.set(updated))
          toastr.success('SUCCESS', 'Setting has been updated.')
        }
      }),
      del: createMiddlewares({
        caller: apis.deleteLabel,
        actionApi: actions.api,
        dispatcher: (id) => async (dispatch, getState) => {
          const state = getState()
          const defaultLabel = _.get(state, 'crm.verification.labels.default', {})
          const index = _.findIndex(defaultLabel.items, {_id: id})

          if (index < 0) {
            return
          }

          const updated = update(defaultLabel, {
            items: { $splice: [[index, 1]] }
          })

          await dispatch(Actions.labels.default.set(updated))
          toastr.success('SUCCESS', 'Setting has been deleted.')
        }
      })
    })
  })
}

export default Actions
