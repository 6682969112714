import { SET_USER, UPDATE_USER } from 'constants/ActionTypes'

const initialState = {
  role: {},
  group: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return action.payload
    case UPDATE_USER:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
