export const RELOAD_PANTIP_CAPTCHA = 'RELOAD_PANTIP_CAPTCHA'
export const LOGIN_WITH_PANTIP = 'LOGIN_WITH_PANTIP'
export const CONNECT_PANTIP = 'CONNECT_PANTIP'
export const GET_PANTIP_ACCOUNTS = 'GET_PANTIP_ACCOUNTS'
export const REMOVE_PANTIP_ACCOUNT = 'REMOVE_PANTIP_ACCOUNT'

export const LOGIN_WITH_FACEBOOK = 'LOGIN_WITH_FACEBOOK'
export const GET_FACEBOOK_PAGES = 'GET_FACEBOOK_PAGES'
export const CONNECT_FACEBOOK_PAGE = 'CONNECT_FACEBOOK_PAGE'
export const REMOVE_FACEBOOK_PAGE = 'REMOVE_FACEBOOK_PAGE'

export const LOGIN_WITH_TWITTER = 'LOGIN_WITH_TWITTER'
export const CONNECT_TWITTER_ACCOUNT = 'CONNECT_TWITTER_ACCOUNT'
export const REMOVE_TWITTER_ACCOUNT = 'REMOVE_TWITTER_ACCOUNT'
export const GET_TWITTER_ACCOUNTS = 'GET_TWITTER_ACCOUNTS'

export const VALIDATE_LINE_TOKEN = 'VALIDATE_LINE_TOKEN'
export const LOGIN_WITH_LINE = 'LOGIN_WITH_LINE'
export const CONNECT_LINE = 'CONNECT_LINE'
export const GET_LINE_CHAT_BOTS = 'GET_LINE_CHAT_BOTS'
export const REMOVE_LINE_CHAT_BOT = 'REMOVE_LINE_CHAT_BOT'
