import {
  SET_USERS,
  CREATE_USERS,
  UPDATE_USERS,
  DELETE_USERS
} from 'constants/ActionTypes'
import { createAction } from 'redux-actions'
import { bindMiddlewaresCreator, withThunkMiddleware } from 'utils/actions'
import * as apis from 'apis/user'

export const setUsers = createAction(SET_USERS, payload => payload)

export const createUsers = createAction(CREATE_USERS, payload => payload)

export const updateUsers = createAction(UPDATE_USERS, payload => payload)

export const deleteUsers = createAction(DELETE_USERS, payload => payload)

const actions = {
  set: setUsers,
  create: createUsers,
  update: updateUsers,
  delete: deleteUsers
}

const middlewares = {
  async getUsers (dispatch, state, payload) {
    const users = await apis.apiListUser()
    dispatch(actions.set(users))
  }
}

actions.middlewares = bindMiddlewaresCreator(middlewares, withThunkMiddleware())

export default actions
