import { SET_GROUP_OPTION, UPDATE_GROUP_OPTION } from 'constants/ActionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GROUP_OPTION:
      return action.payload
    case UPDATE_GROUP_OPTION:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
