import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { bindActionCreators } from 'redux'
import { compose } from 'recompose'
import * as paymentActions from 'actions/payment'
import { Container as AuthenticatedContainer } from './Authenticated'

export const mapStateToProps = createStructuredSelector({
  account: createSelector(state => state.account, account => account),
  user: createSelector(state => state.user, user => user),
  payment: createSelector(
    state => state.payment,
    payment => {
      if (!isEmpty(payment.cardInfo)) {
        return payment
      }
      const cardId = get(payment, 'customer.default_source')
      if (isEmpty(cardId)) {
        return payment
      }

      const cards = get(payment, 'customer.sources.data', [])
      const cardInfo = cards.find(e => e.id === cardId)
      if (isEmpty(cardInfo)) {
        return payment
      }
      return { ...payment, cardInfo }
    }
  ),
  paymented: createSelector(
    ({ payment }, { authenticated }) => ({ payment, authenticated }),
    ({ payment, authenticated }) => {
      /**
       * !!!!! JUST FOR BY-PASS PAYMENT STEP ONLY !!!!!!!
       */
      /*  if (!!authenticated === false) {
        return false
      }
      const subscriptions = get(payment, 'customer.subscriptions.data')
      if (subscriptions === undefined) {
        return null
      }
      if (!Array.isArray(subscriptions)) {
        return false
      }
      if (subscriptions.length === 0) {
        return false
      }

      for (let i = 0; i < subscriptions.length; i++) {
        const each = subscriptions[i]
        if (each.status === 'active') {
          return true
        }
      }
      return false */
      return true
    }
  ),
  addedCard: createSelector(
    state => state.payment,
    payment => {
      const defaultSource = get(payment, 'customer.default_source')
      return !isEmpty(defaultSource)
    }
  )
})

export function mapDispatchToProps (dispatch) {
  return bindActionCreators(paymentActions, dispatch)
}

export function PaymentContainer (Component) {
  return connect(mapStateToProps, mapDispatchToProps)(Component)
}

export const propTypes = {
  account: PropTypes.object,
  user: PropTypes.object,
  payment: PropTypes.object,
  setPaymentPlans: PropTypes.func.isRequired,
  setPaymentInvoices: PropTypes.func.isRequired,
  setPaymentPlanSelected: PropTypes.func.isRequired,
  setPaymentCardInfo: PropTypes.func.isRequired,
  setPaymentDisabled: PropTypes.func.isRequired,
  setPaymentEnabled: PropTypes.func.isRequired,
  setPaymentStatusMessage: PropTypes.func.isRequired,
  setPaymentCustomer: PropTypes.func.isRequired
}

export const defaultProps = {
  account: {},
  user: {},
  payment: {},
  setPaymentPlans () {},
  setPaymentInvoices () {},
  setPaymentPlanSelected () {},
  setPaymentCardInfo () {},
  setPaymentDisabled () {},
  setPaymentEnabled () {},
  setPaymentStatusMessage () {},
  setPaymentCustomer () {}
}

/**
 * Enhancer for wrap any component easy to reuse for receive nessessary props
 * @param {:ReactComponent} Component
 */
export function paymented (Component) {
  return props => {
    if (props.paymented === null) return null
    return <Component {...props} paymented={props.paymented} />
  }
}

export const Paymented = compose(AuthenticatedContainer, PaymentContainer)(
  props => {
    const { paymented = false, children = () => null, not = false } = props
    const isNotRender = not ? paymented : !paymented
    if (isNotRender || Array.isArray(children)) {
      return null
    }
    return children
  }
)

export const AddedCard = compose(AuthenticatedContainer, PaymentContainer)(
  props => {
    const { addedCard = false, children = () => null, not = false } = props
    const isNotRender = not ? addedCard : !addedCard
    if (isNotRender || Array.isArray(children)) {
      return null
    }
    return children
  }
)

// Export most actually used.
export default Component => compose(PaymentContainer, paymented)(Component)
