import { createAction } from 'redux-actions'
import _ from 'lodash'

const defaultResultMapping = result => _.get(result, 'body') || result

/**
 * Helper function for create thunk middleware with automatically map state by selector.
 * @param {Function} defaultSeletor
 * @return {Function, <dispatch, mappedState, payload>}
 */
export const withThunkMiddleware = (defaultSeletor = s => s) =>
  (handlers = () => { }) =>
    (params = {}) => {
      const {
        payload,
        selector = defaultSeletor
      } = params
      return (dispatch, getState) =>
        handlers(dispatch, selector(getState()), payload)
    }

/**
 * @desc bind middlewares with higher wrapper function
 * @param {Object} actions
 * @param {Function} wrapper
 * @returns {Object}
 */
export const bindMiddlewaresCreator = (actions, wrapper) => {
  for (const name in actions) {
    if (actions.hasOwnProperty(name)) {
      actions[name] = wrapper(actions[name])
    }
  }
  return actions
}

export const defaultAsyncFn = async () => { }

export const createApiActions = (prefix = '') => ({
  pending: createAction(`${prefix}.pending`),
  success: createAction(`${prefix}.success`),
  error: createAction(`${prefix}.error`)
})

export const createMiddlewares = ({
  caller = defaultAsyncFn,
  actionApi = createApiActions(),
  dispatcher = () => { },
  resultMapping = defaultResultMapping
}) => async (dispatch, state, payload) => {
  try {
    dispatch(actionApi.pending())
    const result = await caller(payload)
    dispatch(dispatcher(resultMapping(result)))
    dispatch(actionApi.success())
    return result
  } catch (error) {
    dispatch(actionApi.error(error))
    return Promise.reject(error)
  }
}

export const createFullCycleActions = ({
  prefix = '',
  apis = {},
  customMiddlewares = (/* actions */) => { }
}) => {
  const {
    get = defaultAsyncFn,
    list = defaultAsyncFn,
    create = defaultAsyncFn,
    update = defaultAsyncFn,
    del = defaultAsyncFn
  } = apis

  const actions = {
    api: createApiActions(`${prefix}.api`),
    data: {
      set: createAction(`${prefix}.data.set`),
      reset: createAction(`${prefix}.data.reset`)
    },
    default: { set: createAction(`${prefix}.default.set`) },
    middlewares: { get, list, create, update, del }
  }

  const middlewares = {
    get: createMiddlewares({ caller: get, actionApi: actions.api, dispatcher: actions.default.set }),
    list: createMiddlewares({ caller: list, actionApi: actions.api, dispatcher: actions.default.set }),
    create: createMiddlewares({ caller: create, actionApi: actions.api, dispatcher: actions.default.set }),
    update: createMiddlewares({ caller: update, actionApi: actions.api, dispatcher: actions.default.set }),
    del: createMiddlewares({ caller: del, actionApi: actions.api, dispatcher: actions.default.set }),
    ...customMiddlewares(actions)
  }
  actions.middlewares = bindMiddlewaresCreator(middlewares, withThunkMiddleware())
  return actions
}
