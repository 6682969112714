import { combineReducers } from 'redux'
import { createFullCycleHandlers } from 'utils/reducers'
import { handleActions } from 'redux-actions'
import update from 'immutability-helper'

import actions from 'actions/crm/uiSettings'
import crmState from './initial.state'

const handler = (actions, initialState) => handleActions({
  [actions.can_edit_after_done.set]: (state, action) => {
    return update(state, {
      canEditAfterDone: { $set: action.payload }
    })
  },
  [actions.can_reply_after_done.set]: (state, action) => {
    return update(state, {
      canReplyAfterDone: { $set: action.payload }
    })
  },
  [actions.no_dup_open_inbox.set]: (state, action) => {
    return update(state, {
      noDupOpenInbox: { $set: action.payload }
    })
  },
  [actions.action_on_assigned.set]: (state, action) => {
    return update(state, {
      actionOnAssigned: { $set: action.payload }
    })
  },
  [actions.timer_enabled.set]: (state, action) => {
    return update(state, {
      timerEnabled: { $set: action.payload }
    })
  },
  [actions.done_with_assignee.set]: (state, action) => {
    return update(state, {
      doneWithAssignee: { $set: action.payload }
    })
  },
  [actions.reply_with_assignee.set]: (state, action) => {
    return update(state, {
      replyWithAssignee: { $set: action.payload }
    })
  },
  [actions.done_with_survey.set]: (state, action) => {
    return update(state, {
      doneWithSurvey: { $set: action.payload }
    })
  },
  [actions.tag_search_option.set]: (state, action) => {
    return update(state, {
      tagSearchOption: { $set: action.payload }
    })
  },
  [actions.pause_survey_enabled.set]: (state, action) => {
    return update(state, {
      pauseSurveyEnabled: { $set: action.payload }
    })
  },
  [actions.pause_survey_duration.set]: (state, action) => {
    return update(state, {
      pauseSurveyDuration: { $set: action.payload }
    })
  },
  [actions.email_escalation.set]: (state, action) => {
    return update(state, {
      emailEscalation: { $set: action.payload }
    })
  },
  [actions.ticket_states.set]: (state, action) => {
    return update(state, {
      ticketStates: { $set: action.payload }
    })
  }
}, initialState)

export default combineReducers({
  settings: createFullCycleHandlers(actions.settings, crmState.ui.settings),
  pending: handler(actions.pending, crmState.ui.pending)
})
