const api = {
  pending: false,
  success: true,
  error: ''
}
const defaultState = {
  data: {},
  default: {},
  api
}

export default {
  engagements: {},
  sla: {},
  keywords: {},
  tags: {
    items: {},
    selected: {},
    groupSelected: {}
  },
  autoAssign: {
    settings: defaultState,
    agents: defaultState,
    conditions: defaultState,
    rules: defaultState
  },
  autoSentiment: {
    settings: defaultState
  },
  groupManage: {
    settings: defaultState,
    ui: {
      groupSelected: null
    }
  },
  ui: {
    settings: defaultState,
    pending: {
      canEditAfterDone: false,
      canReplyAfterDone: false,
      noDupOpenInbox: false,
      actionOnAssigned: false,
      timerEnabled: false,
      doneWithAssignee: false,
      replyWithAssignee: false,
      doneWithSurvey: false,
      tagSearchOption: false,
      emailEscalation: false,
      ticketStates: false
    }
  },
  autoCanned: {
    conditions: defaultState,
    keywords: defaultState
  },
  verification: {
    settings: defaultState,
    labels: defaultState
    // fields: [],
    // selectedFields: {},
    // items: {},
    // selected: {}
  },
  post_blocker: {
    settings: defaultState
  },
  working_status: {
    settings: defaultState
  }
}
