export const AUTHENTICATED = 'AUTHENTICATED'
export const PAYMENTED = 'PAYMENTED'
export const ADDEDCARD = 'ADDEDCARD'
export const NONPAYMENT = 'NONPAYMENT'
export const GUEST = 'GUEST'
export const ADMIN = 'ADMIN'
export const NOT_OEM = 'NOT_OEM'
export const PRODUCT_ACTIVATE = 'PRODUCT_ACTIVATE'
export const PRODUCT_SETTING_PAGE = 'PRODUCT_SETTING_PAGE'
export const PASSWORD_NOT_EXPIRED = 'PASSWORD_NOT_EXPIRED'
export const PERMISSIONS_REQUIRED = 'PERMISSIONS_REQUIRED'