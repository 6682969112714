import {
  SET_PAYMENT_PLAN,
  SET_PAYMENT_PLAN_SELECTED,
  SET_PAYMENT_CARD_INFO,
  SET_PAYMENT_DISABLED,
  SET_PAYMENT_ENABLED,
  SET_PAYMENT_STATUS_MESSAGE,
  SET_PAYMENT_CUSTOMER,
  SET_PAYMENT_INVOICE
} from 'constants/ActionTypes'
import { createAction } from 'redux-actions'

export const setPaymentPlans = createAction(
  SET_PAYMENT_PLAN,
  payload => payload
)

export const setPaymentPlanSelected = createAction(
  SET_PAYMENT_PLAN_SELECTED,
  payload => payload
)

export const setPaymentCardInfo = createAction(
  SET_PAYMENT_CARD_INFO,
  payload => payload
)

export const setPaymentDisabled = createAction(
  SET_PAYMENT_DISABLED,
  payload => payload
)

export const setPaymentEnabled = createAction(
  SET_PAYMENT_ENABLED,
  payload => payload
)

export const setPaymentStatusMessage = createAction(
  SET_PAYMENT_STATUS_MESSAGE,
  payload => payload
)

export const setPaymentCustomer = createAction(
  SET_PAYMENT_CUSTOMER,
  payload => payload
)

export const setPaymentInvoices = createAction(
  SET_PAYMENT_INVOICE,
  payload => payload
)
